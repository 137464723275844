import React from 'react';
import { Card } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  EditAddressHpStoreHyperlinkClicked,
  publishEvent
} from 'src/utils/analytics';
import { AddressLink, AddressSectionContent } from './styles';
import { URLS } from 'src/utils/urls';

interface AddressCardProps {
  children: React.ReactNode;
}

const OldAddressCard = ({ children }: AddressCardProps) => {
  const { translate } = useDependencyManagerContext();

  const htmlString = translate(
    'profile.findMoreAddresses',
    'Find more addresses in your {{tagReplaceStart}}HP Store address book.{{tagReplaceEnd}}'
  )
    .replace(
      '{{tagReplaceStart}}',
      `<a href="${URLS.EditAddressHpStore}" target="_blank" rel="noreferrer">`
    )
    .replace('{{tagReplaceEnd}}', '</a>');

  return (
    <Card
      title={{
        content: `${translate('profile.addresses', 'Addresses')}`
      }}
      data-testid="old-addresses"
      rowsColorChangeEnabled={false}
      rows={[
        {
          index: 0,
          content: <AddressSectionContent>{children}</AddressSectionContent>
        }
      ]}
    >
      <AddressLink>
        <div
          data-testid="edit-address-hp-store"
          dangerouslySetInnerHTML={{ __html: htmlString }}
          onClick={() => publishEvent(EditAddressHpStoreHyperlinkClicked)}
        />
      </AddressLink>
    </Card>
  );
};

export default OldAddressCard;
