import { PublishCdmEventsOptions } from '@clientos/commons-northbound-api/dist/interface/v1/analytics/types';
import { URLS } from './urls';
import { FormTypes } from 'src/utils/enums';

const activity = 'UnifiedAcctMgmt-v01';
export const screenPath = '/ReactMyAccountProfile/';
export const screenPathProfileDetails =
  '/ReactMyAccountProfile/ProfileDetails/';
export const screenName = 'Profile';
export const screenNameDetails = 'ProfileDetails';
const eventDetailVersion = '1.5.0';

export interface AnalyticsEventPropTypes {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlDetail?: string;
  version: string;
}

export interface AnalyticsEventWithControlName extends AnalyticsEventPropTypes {
  controlName: string;
}

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
): void => {
  const publishCdmEvents = (window as any).Shell?.v1.analytics.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

export const ProfileScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const ProfileDetailsScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath: screenPathProfileDetails,
  screenName: screenNameDetails,
  version: eventDetailVersion
};

export const ManagePersonalDetailsButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsButton',
    controlDetail: URLS.HpProfile,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsNameHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsNameLink',
    controlDetail: URLS.HpProfile,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsNameButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsNameButton',
    actionAuxParams: `type=${FormTypes.Name}`,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsEmailHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsEmailLink',
    controlDetail: URLS.HpProfile,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsEmailButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsEmailButton',
    actionAuxParams: `type=${FormTypes.Email}`,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsPhoneHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsPhoneLink',
    controlDetail: URLS.HpProfile,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsPhoneButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsPhoneButton',
    actionAuxParams: `type=${FormTypes.Phone}`,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsLanguageHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsPreferredLanguageLink',
    controlDetail: URLS.HpProfile,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsLanguageButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsPreferredLanguageButton',
    actionAuxParams: `type=${FormTypes.Language}`,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsCountryRegionHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsCountryRegionLink',
    controlDetail: URLS.HpProfile,
    version: eventDetailVersion
  };

export const ManagePersonalDetailsCountryRegionButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManagePersonalDetailsCountryRegionButton',
    actionAuxParams: `type=${FormTypes.Country}`,
    version: eventDetailVersion
  };

export const EditAddressHpInstantInkHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'EditAddressHpInstantInkLink',
    controlDetail: URLS.UnifiedShippingBillingURL,
    version: eventDetailVersion
  };

export const EditAddressHpAllInPlanHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'EditAddressHpAllInPlanLink',
    controlDetail: URLS.UnifiedShippingBillingURL,
    version: eventDetailVersion
  };

export const EditAddressHpStoreHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'EditAddressHpStoreLink',
    controlDetail: URLS.EditAddressHpStore,
    version: eventDetailVersion
  };

export const HpStoreNewsletterButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'HpStoreNewsletterButton',
  controlDetail: URLS.HpStoreNewsletter,
  version: eventDetailVersion
};

export const PersonalDetailsError = (
  errorInfo: string
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}Profile/`,
    screenName: 'PersonalDetailsError',
    version: eventDetailVersion
  };
};

export const AddressesError = (errorInfo: string): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}Profile/`,
    screenName: 'AddressesError',
    version: eventDetailVersion
  };
};
