export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      const value = cookie.substring(name.length + 1);
      return value === '' ? null : value;
    }
  }
  return null;
};
