import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const GroupName = styled.div`
  font-size: ${tokens.typography.size1};
  color: ${tokens.color.gray7};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size3};
`;

export const SubGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size5};
`;
