import { CDMObject, ValveFilterError, ValveFilterErrorType } from '@jarvis/jweb-core';
import { sanitize } from '../CDMFilter/sanitize';
import getTelemetryConsent from '../client/utils/consents';
import { DataValveFilterError } from './dataValveError';
import { logger } from './logger';

export const oneTrustFiltering = (cdmObjects: (ValveFilterError | CDMObject)[], consent: string) => {
  let isTelemetryAllowed = false;
  try {
    isTelemetryAllowed = getTelemetryConsent(consent);
  } catch (error: any) {
    if (error instanceof DataValveFilterError){
      throw error;
    } else {
      throw new DataValveFilterError(ValveFilterErrorType.treeNotAllowed,error.message,'Invalid webAppConsent', error);
    }
  }
  const filteredResults = cdmObjects.map(cdmObject => {
    if ((cdmObject as ValveFilterError).errorType) {
      return cdmObject;
    }
    if ((cdmObject as CDMObject).treeGun !== 'com.hp.cdm.service.eventing.version.1.resource.notification' || !isTelemetryAllowed) {
      return new DataValveFilterError(ValveFilterErrorType.treeNotAllowed,'ResourceId is invalid or Telemetry consent provided is not allowed','');
    }
    return cdmObject;
  });
  return filteredResults;
};

export const valveControllerFiltering = (cdmObjects: (ValveFilterError | CDMObject)[], bindings: object) => {
  const filteredResults = cdmObjects.map(cdmObject => {
    if (Object.keys(bindings).length !== 0) {
      try {
        if ((cdmObject as ValveFilterError).errorType) {
          return cdmObject;
        }
        const notification = JSON.parse((cdmObject as CDMObject).tree);
        const notificationObject = JSON.stringify(notification);
        const sanitizedObject = sanitize(notification, (cdmObject as CDMObject).treeGun, bindings, notificationObject, false);
        logger.log('FilteringMethods::ValveControllerFiltering::Sanitized object:', sanitizedObject.sanitizedNode);
        (cdmObject as CDMObject).tree = JSON.stringify(sanitizedObject.sanitizedNode);
        return cdmObject;
      } catch (error: any) {
        throw new DataValveFilterError(ValveFilterErrorType.treeNotAllowed,'Unexpected error in FilteringMethods', error);
      }
    } else {
      throw new DataValveFilterError(ValveFilterErrorType.valveControllerAPIError,'Bindings are undefined','Invalid bindings');
    }
  });
  return filteredResults;
};
