import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const DeviceDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const Title = styled.h4`
  font-size: ${tokens.typography.size4};
  line-height: ${tokens.typography.lineHeight4};
  color: ${tokens.color.gray12};
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  color: ${tokens.color.gray10};
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;
