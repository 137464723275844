import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { IconHeadset } from '@veneer/core';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { GetDeviceSupport } from './analytics';
import { URLS } from 'src/utils/urls';

interface MoreOptionsProps {
  deviceDetails: string;
}

const MoreOptions = ({ deviceDetails }: MoreOptionsProps) => {
  const { translate } = useDependencyManagerContext();
  const analyticEvent = GetDeviceSupport(deviceDetails);

  return (
    <Card
      title={{
        content: translate('common.moreOptions', 'More options') as string
      }}
      rows={[
        {
          index: 0,
          content: translate(
            'devices.getDeviceSupport',
            'Get device support'
          ) as string,
          type: ROW_TYPE.EXTERNAL,
          icon: <IconHeadset />,
          link: {
            url: URLS.DeviceSupport,
            dataTestId: 'device-support-link'
          },
          analyticsEvent: analyticEvent
        }
      ]}
    />
  );
};

export default MoreOptions;
