import { Stack } from '../types/shell';
import { getShellApiStack } from './getStack';

export const getHPSmartURL = (): string => {
  const stack = getShellApiStack();

  return stack === Stack.prod
    ? 'https://www.hpsmart.com'
    : 'https://www.hpsmartstage.com';
};

export const getHPSmartPrinterOverviewUrl = (): string => {
  return `${getHPSmartURL()}/ucde/my-printers`;
};

export const getHPSmartManageDeviceUsersAndSharingUrl = (): string => {
  return `${getHPSmartURL()}/ucde/account-details/manage-users`;
};

export const getHPSupportUrl = (): string => {
  return 'https://support.hp.com';
};

export const URLS = {
  DevicesPurchased:
    'https://www.hp.com/us-en/shop/LogonForm?catalogId=10051&storeId=10151&langId=-1&krypto=qQQ8hSaLbuyd%2Bx2xME%2BfiZ8oSdKDSF2qT9WAayfah%2F%2FFMudcn2s67mAs2RzgXa1N8Dkib%2FWw9VAQ5stD3wKr5vXKzPFzmrxlBC%2BNhAcC3Vpi0PPmmevFXRU9TAUc%2BXG0X5O%2FcBUFiNCRyqX%2FhqFeuqvvGIrSy26d7YoTh5zO70FVV5JnjGpjtSaTM%2Fb2FqRciaHRbEO1ddRBx6XKcWh%2BMRSoZDDlbUZE2ndE4dOI%2FegbOG%2FLd8tPTncZyZIOJBx8QpxZIvNq47m38PrFtEKiHBdD%2F%2Btq4OMFRWysPGaLH2WOtBDAyFa7hNqEtnJMqKd8cW8rfFf97IRLoZbRFRvEVABO6YaBRNqeZLoSFX8bsSDNMMs1jvEu7v4RdAc%2FD%2B91rSvGSXHRtlzc0bk2LR5E1g%3D%3D&ddkey=https%3AETRLogonFlow',
  DeviceSupportNotShown: `${getHPSupportUrl()}`,
  DeviceSupport: `${getHPSupportUrl()}/dashboard/device`,
  SupportDashboard: `${getHPSupportUrl()}/dashboard`,
  ManageInstantInk: '',
  HPCarePack: '',
  Register: 'https://www.register.hp.com/'
};
