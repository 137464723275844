import { useState } from 'react'
import { formatNumberAsYouType } from './phoneNumberFormatter'

function useForm(defaultState, formValidations, t: (id: string) => string) {
  const [formState, setFormState] = useState(defaultState)
  const verifyChanges = (initialValue, value) => {
    if (initialValue === value) {
      return false
    }
    return true
  }

  const handleChange = (user, fieldName, value, updateHasChanges = true) => {
    if (fieldName === 'phoneNumber') {
      value = formatNumberAsYouType(value)
    }

    const updatedFormState = {
      ...formState,
      [fieldName]: {
        value,
        error: formValidations[fieldName](formState[fieldName], value, t),
        applyChange: verifyChanges(formState[fieldName], value)
      }
    }

    const hasErrors = Object.values(updatedFormState).some(
      (obj) => obj['error']
    )

    const hasApplyChanges = Object.values(updatedFormState).some(
      (obj) => obj['applyChange']
    )

    const hasChanges = updateHasChanges
      ? defaultState[fieldName] !== value || formState.hasChanges
      : formState.hasChanges

    setFormState({
      ...updatedFormState,
      hasErrors,
      hasChanges,
      hasApplyChanges
    })
  }

  return {
    formState,
    setFormState,
    handleChange
  }
}

export default useForm
