import { Dispatch, SetStateAction } from 'react';
import {
  FIELDS,
  HpIdFieldConfigType
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { LoginDetailsType } from 'src/components/LoginDetails';

const getFieldsToFetch = (
  setLoginDetails: Dispatch<SetStateAction<LoginDetailsType>>
): HpIdFieldConfigType[] => {
  return [
    {
      field: FIELDS.EMAIL,
      setter: (value: string) =>
        setLoginDetails((prev) => ({ ...prev, email: value })),
      propertySelector: 'value'
    },
    {
      field: FIELDS.EMAIL,
      setter: (value: string) =>
        setLoginDetails((prev) => ({
          ...prev,
          emailVerified: String(value).toLowerCase() === 'true'
        })),
      propertySelector: 'verified'
    },
    {
      field: FIELDS.PHONE,
      setter: (value: string) =>
        setLoginDetails((prev) => ({ ...prev, phone: value }))
    },
    {
      field: FIELDS.PHONE,
      setter: (value: string) =>
        setLoginDetails((prev) => ({
          ...prev,
          phoneVerified: String(value).toLowerCase() === 'true'
        })),
      propertySelector: 'verified'
    }
  ];
};

export default getFieldsToFetch;
