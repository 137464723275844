import { path, propOr } from 'ramda';

export const OFFERING_OR_ENTITLEMENT = {
  OFFERING: 'Offering',
  ENTITLEMENT: 'Entitlement',
  BOTH: 'both',
};

export const getOfferingOrEntitlement = (
  programInfosPayload,
  serviceId,
  filter = {},
  listFilter = OFFERING_OR_ENTITLEMENT.BOTH,
) => {
  const programLevel = path(['planInfo', 'programLevel'], programInfosPayload);

  if (!programLevel) {
    return null;
  }
  const programInfoMapEntry = programLevel === 'HP+' ? 'YETI' : 'UCDE';

  const entries = path(['programInfoMap', programInfoMapEntry], programInfosPayload)
    || programInfosPayload;

  if (!entries) {
    return null;
  }

  const offerings = propOr([], 'subscriptionOfferingList', entries);
  const entitlements = propOr([], 'entitlementList', entries);

  const result = [
    ...((listFilter === OFFERING_OR_ENTITLEMENT.BOTH || listFilter === OFFERING_OR_ENTITLEMENT.OFFERING)
      ? offerings : []
    ),
    ...((listFilter === OFFERING_OR_ENTITLEMENT.BOTH || listFilter === OFFERING_OR_ENTITLEMENT.ENTITLEMENT)
      ? entitlements : []
    ),
  ].filter(item => item.serviceId === serviceId).pop();

  if (!result) {
    return null;
  }

  if (Array.isArray(filter.states)) {
    return filter.states.includes(result.state) ? result : null;
  }

  return result;
};
