import { Stack as StackShellApi } from '../types/shell';

export const getShellApiStack = (): StackShellApi => {
  return (window as any)?.Shell?.v1.app.getAuthStack();
};

export const getGraphQLStack = (): StackShellApi => {
  const stackShellClient = getShellApiStack();

  switch (stackShellClient) {
    case 0:
      return StackShellApi.dev;
    case 1:
      return StackShellApi.pie;
    case 2:
      return StackShellApi.stage;
    case 3:
      return StackShellApi.prod;
    default:
      throw new Error('Invalid stack');
  }
};
