import React from 'react';
import Loader from '../Loader';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_USER_DETAILS from 'src/graphql/getUserDetails';
import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import ErrorBoundary from 'src/App/ErrorBoundary';
import {
  LoginDetailsError,
  ManageLoginDetailsHyperLinkClicked
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { RowContent } from '../LoginDetails/styles';
import { Label, Row, Value, ValueContent } from 'src/styles/styles';
import Verified from '../Verified';
import { useStaticStrings } from 'src/utils/strings';

interface ValueColumnProps {
  value: string;
  verified?: boolean | null;
  dataComponentName?: string;
}

const ValueColumn = ({
  value,
  verified,
  dataComponentName
}: ValueColumnProps) => {
  return (
    <Value>
      <ValueContent
        {...(dataComponentName ? { 'data-component': dataComponentName } : {})}
      >
        {value}
      </ValueContent>
      {verified != null && <Verified verified={verified} />}
    </Value>
  );
};

interface RowDataProps {
  label: string;
  column: ValueColumnProps;
  dataComponentName?: string;
}

const RowData = ({ label, column, dataComponentName }: RowDataProps) => {
  return (
    <Row>
      <Label className="caption">{label}</Label>
      <ValueColumn
        {...(dataComponentName ? { dataComponentName: dataComponentName } : {})}
        value={column.value}
        verified={column.verified}
      />
    </Row>
  );
};

const OldLoginDetails = () => {
  const { northboundAPIs } = useDependencyManagerContext();
  const { useQuery } = northboundAPIs.v1.graphql.reactTools;
  const { loading, error, data } = useQuery(GET_USER_DETAILS);

  const {
    fsDataComponentName,
    loginDetailsTitle,
    emailLabel,
    emptyEmail,
    phoneLabel,
    emptyPhone,
    passwordMask,
    passwordLabel
  } = useStaticStrings();

  if (loading) {
    return <Loader />;
  }

  const { email, emailVerified, phone, phoneVerified } =
    data?.account?.user || {};

  if (error) {
    return (
      <Card
        title={{
          content: loginDetailsTitle
        }}
        rowsColorChangeEnabled={false}
        childrenStyle={{ align: 'center', marginTop: '16px' }}
        data-testid="error-section"
      >
        <ErrorBoundary analyticsEvent={LoginDetailsError(error.message)} />
      </Card>
    );
  }

  const title = {
    content: loginDetailsTitle,
    type: ROW_TYPE.EXTERNAL,
    link: {
      url: URLS.HpIdSecurity,
      dataTestId: 'ManageLoginDetailsLink'
    },
    analyticsEvent: ManageLoginDetailsHyperLinkClicked
  };

  const content = (
    <RowContent>
      <RowData
        dataComponentName={fsDataComponentName}
        label={emailLabel}
        column={{
          value: email || emptyEmail,
          verified: email ? emailVerified : null
        }}
      />
      <RowData
        dataComponentName={fsDataComponentName}
        label={phoneLabel}
        column={{
          value: phone || emptyPhone,
          verified: phone ? phoneVerified : null
        }}
      />
      <RowData
        label={passwordLabel}
        column={{
          value: passwordMask
        }}
        data-testid="default-password-row"
      />
    </RowContent>
  );

  return (
    <Card
      title={title}
      rowsColorChangeEnabled={false}
      childrenStyle={{ align: 'left', marginTop: '8px' }}
    >
      {data?.account?.user && content}
    </Card>
  );
};

export default OldLoginDetails;
