import React, { useEffect } from 'react';
import { Button } from '@veneer/core';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  ErrorContent,
  ErrorTextContainer,
  ErrorTopWrapper,
  StyledIconTools
} from './styles';
import { Section } from 'src/styles/styles';
import {
  AnalyticsEventPropTypes,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist';

interface ErrorBoundaryProps {
  analyticsEvent: AnalyticsEventPropTypes;
  showOutline?: boolean;
}

const ErrorBoundary = ({
  analyticsEvent,
  showOutline = true
}: ErrorBoundaryProps) => {
  const { translate } = useDependencyManagerContext();

  useEffect(() => {
    publishEvent(analyticsEvent);
  }, [analyticsEvent]);

  const errorContent = (
    <ErrorContent>
      <ErrorTopWrapper>
        <StyledIconTools />
        <ErrorTextContainer data-testid="error-container">
          <div className="subtitle-large">
            {`${translate('common.itsNotYou', 'It’s not you, it’s us…')}`}
          </div>
          <div className="body">
            {`${translate(
              'common.thereWasAnInterruption',
              'There was an interruption while loading this information.'
            )}`}
          </div>
        </ErrorTextContainer>
      </ErrorTopWrapper>
      <Button
        appearance="secondary"
        data-testid="try-again-button"
        onClick={() => {
          window.location.reload();
        }}
      >
        {`${translate('common.tryAgain', 'Please try again')}`}
      </Button>
    </ErrorContent>
  );

  return (
    <>
      {showOutline ? (
        <Section
          appearance="outlined"
          data-testid="error-boundary-section"
          content={errorContent}
        />
      ) : (
        errorContent
      )}
    </>
  );
};

export default ErrorBoundary;
