import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { ManageDeviceUsersAndSharing } from './analytics';
import { getHPSmartManageDeviceUsersAndSharingUrl } from 'src/utils/urls';
import { Content } from './styles';

interface UsersAndSharingProps {
  deviceDetails: string;
}

const UsersAndSharing = ({ deviceDetails }: UsersAndSharingProps) => {
  const { translate } = useDependencyManagerContext();
  const analyticEvent = ManageDeviceUsersAndSharing(deviceDetails);

  return (
    <Card
      data-testid="users-and-sharing"
      title={{
        content: translate(
          'devices.usersAndSharing',
          'Users & sharing'
        ) as string,
        type: ROW_TYPE.EXTERNAL,
        link: {
          url: getHPSmartManageDeviceUsersAndSharingUrl(),
          dataTestId: 'users-sharing'
        },
        analyticsEvent: analyticEvent
      }}
    >
      <Content>
        {`${translate(
          'devices.usersAndSharingDescription',
          'Invited users can enjoy the benefits of your HP account and access your printer. Add or remove users anytime here. Use the Solutions menu to manage available features of your HP account.'
        )}`}
      </Content>
    </Card>
  );
};

export default UsersAndSharing;
