import {
  CustomImage,
  IconDocumentCopy,
  IconInkDoubleDrops,
  LogoHpInstantInk
} from '@veneer/core';
import { SubscriptionType } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useCallback, useMemo } from 'react';
import {
  ImageContainer,
  Label,
  Link,
  Row,
  RowIcon,
  Section
} from 'src/styles/styles';
import {
  OverviewAllInHyperLinkClicked,
  OverviewInstantInkHyperLinkClicked,
  publishEvent
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { Logo } from '../Logo';
import { SubscriptionsCardProps } from '../SubscriptionsCard/types';
import { TypeNameEntities } from 'src/utils/enums';
import { getResourceId } from 'src/utils/utils';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

export const PlanDetails = ({ subscription }: SubscriptionsCardProps) => {
  const { translate } = useDependencyManagerContext();

  const getDeviceName = (device: any) => {
    return device.identity?.friendlyName || device.identity?.makeAndModel?.name;
  };

  const instantPaperPlan = subscription?.entities?.find(
    (item) => item.__typename == TypeNameEntities.INSTANTPAPERENTITY
  );

  const getPagesMonth = useCallback(
    (entity: any) => {
      const plusPaper = instantPaperPlan
        ? ` ${translate('common.plusPaper', ' + Paper')}`
        : '';

      const loweredPages = `${translate(
        'common.pages',
        'Pages'
      )}${plusPaper}`.toLowerCase();

      return entity?.product?.description
        ? `${entity.product.description} ${loweredPages}`
        : '';
    },
    [instantPaperPlan, translate]
  );

  const getMonthlyPlanDescription = useCallback(
    (entity: any) => {
      const detail =
        entity.__typename === TypeNameEntities.INSTANTPAPERENTITY
          ? `${translate('common.sheets', 'sheets')}`
          : `${translate('common.pages', 'Pages')}`.toLowerCase();

      return entity?.product?.description
        ? `${entity.product.description} ${detail}${translate(
            'common.month',
            '/month'
          )}`
        : '';
    },
    [translate]
  );

  const resourceId = getResourceId(subscription);

  const InstantInkPlanDetails = useMemo(() => {
    const hasDevices = subscription?.devices?.items?.length > 0;

    const deviceName = hasDevices
      ? getDeviceName(subscription?.devices?.items[0])
      : '';

    return (
      <>
        <RowIcon>
          <span className="subtitle-regular">
            <Label>{`${translate('common.plan', 'Plan')}`}</Label>
          </span>

          <div className="logo-instant-ink">
            <LogoHpInstantInk appearance="multicolor" />
          </div>
        </RowIcon>

        <span className="body-large">
          {getPagesMonth(subscription?.entities[0])}
        </span>
        <span
          data-component="SubscriptionsPlanInstantInkIdValue"
          className="caption"
        >
          {`${translate('common.id', 'ID:')} ${resourceId}`}
        </span>
        <div>
          <Link
            data-testid="HPInstantInkOverviewLink"
            href={URLS.InstantInkOverview}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              publishEvent(
                OverviewInstantInkHyperLinkClicked(
                  resourceId,
                  subscription.startDate
                )
              )
            }
          >
            {`${translate('subscriptions.hpInstantInkOverview', 'HP Instant Ink Overview')}`}
          </Link>
        </div>
        {hasDevices && (
          <RowIcon>
            <div className="custom-image">
              <CustomImage
                quietArea={0}
                src={
                  subscription?.devices?.items[0]?.images?.[
                    subscription?.devices?.items[0]?.images?.length - 1
                  ]?.url
                }
              />
            </div>
            <span className="caption">{deviceName}</span>
          </RowIcon>
        )}
      </>
    );
  }, [
    subscription?.devices?.items,
    subscription?.entities,
    resourceId,
    getPagesMonth,
    subscription.startDate,
    translate
  ]);

  const AllInPlanDetails = useMemo(() => {
    const devicesEntities = subscription?.entities?.filter(
      (item) => item.__typename === TypeNameEntities.DEVICEENTITY
    );

    const instantInkPlan = subscription?.entities?.find(
      (item) => item.__typename == TypeNameEntities.INSTANTINKENTITY
    );

    return (
      <>
        <RowIcon>
          <span className="subtitle-regular">
            <Label>{`${translate('common.plan', 'Plan')}`}</Label>
          </span>

          <div className="logo-all-in">
            <Logo type="ALL_IN_HORIZONTAL" />
          </div>
        </RowIcon>

        <span className="body-large">{getPagesMonth(instantInkPlan)}</span>
        <span
          data-component="SubscriptionsPlanHpAllInPlanIdValue"
          className="caption"
        >{`${translate('common.id', 'ID:')} ${resourceId}`}</span>
        <div>
          <Link
            data-testid="HPAllInPlanOverviewLink"
            href={URLS.HpAllInOverview}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              publishEvent(
                OverviewAllInHyperLinkClicked(
                  resourceId,
                  subscription.startDate
                )
              )
            }
          >
            {`${translate('subscriptions.hpAllInPlanOverview', 'HP All-In Plan Overview')}`}
          </Link>
        </div>

        {devicesEntities.length > 0 &&
          devicesEntities.map((device) => {
            return (
              <RowIcon key={device?.product?.name}>
                <ImageContainer className="custom-image">
                  <CustomImage
                    quietArea={0}
                    src={device?.product?.image}
                  />
                </ImageContainer>
                <span className="caption">{device?.product?.name}</span>
              </RowIcon>
            );
          })}

        {instantInkPlan && (
          <RowIcon>
            <ImageContainer className="custom-image">
              <IconInkDoubleDrops />
            </ImageContainer>
            <Row $flexDirection="column">
              <span className="caption">
                {`${translate('common.printPlan', 'Print Plan')}`}
              </span>
              <span className="caption-small">
                {getMonthlyPlanDescription(instantInkPlan)}
              </span>
            </Row>
          </RowIcon>
        )}

        {instantPaperPlan && (
          <RowIcon>
            <ImageContainer className="custom-image">
              <IconDocumentCopy />
            </ImageContainer>
            <Row $flexDirection="column">
              <span className="caption">
                {`${translate('common.papperPlan', 'Paper Plan')}`}
              </span>
              <span className="caption-small">
                {getMonthlyPlanDescription(instantPaperPlan)}
              </span>
            </Row>
          </RowIcon>
        )}
      </>
    );
  }, [
    subscription,
    resourceId,
    getPagesMonth,
    instantPaperPlan,
    getMonthlyPlanDescription,
    translate
  ]);

  const getSubscriptionCard = () => {
    if (subscription?.type === SubscriptionType.INSTANT_INK) {
      return InstantInkPlanDetails;
    }

    if (subscription?.type === SubscriptionType.HP_ALL_IN) {
      return AllInPlanDetails;
    }

    return null;
  };

  return (
    <Section
      appearance="outlined"
      data-testid="PlanDetails-Section-Container"
      content={getSubscriptionCard()}
    />
  );
};

export default PlanDetails;
