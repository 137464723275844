import { IconPin } from '@veneer/core';
import React, { useMemo } from 'react';
import { transformDate } from 'src/helpers/transformDate';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  HpAllInManageAddressHyperLinkClicked,
  InstantInkManageAddressHyperLinkClicked,
  ShipmentHistoryAllInHyperLinkClicked,
  ShipmentHistoryInstantInkHyperLinkClicked,
  publishEvent
} from 'src/utils/analytics';
import { EntityType, FulfillmentStatus } from 'src/utils/enums';
import { URLS } from 'src/utils/urls';
import { SubscriptionsCardProps } from '../SubscriptionsCard/types';
import {
  CardContainer,
  Link,
  Gap8,
  History,
  EmptyContent
} from 'src/styles/styles';
import { getResourceId } from 'src/utils/utils';
import {
  Card,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';

export const Shipping = ({ subscription }: SubscriptionsCardProps) => {
  const { translate } = useDependencyManagerContext();

  const resourceId = getResourceId(subscription);

  const ShipmentHistoryLink = () => (
    <div className="caption">
      <Link
        data-testid="ShipmentHistoryLink"
        href={
          subscription?.type === SubscriptionType.INSTANT_INK
            ? URLS.InkShipment
            : URLS.AllInShipment
        }
        target="_blank"
        rel="noreferrer"
        onClick={() =>
          publishEvent(
            subscription?.type === SubscriptionType.INSTANT_INK
              ? ShipmentHistoryInstantInkHyperLinkClicked(
                  resourceId,
                  subscription?.startDate
                )
              : ShipmentHistoryAllInHyperLinkClicked(
                  resourceId,
                  subscription?.startDate
                )
          )
        }
      >
        {`${translate('subscriptions.shipmentHistory', 'Shipment history')}`}
      </Link>
    </div>
  );

  const ShipmentHistoryContent = useMemo(() => {
    // TODO: Implement fulfillment for InstantInk subscriptions once we have the data
    if (subscription?.type === SubscriptionType.INSTANT_INK) {
      return null;
    }

    const isDeliveredOrShipped = (status: FulfillmentStatus) =>
      status === FulfillmentStatus.DELIVERED ||
      status === FulfillmentStatus.SHIPPED;

    const sortFulfillments = (a: any, b: any) => {
      if (a.status === b.status) {
        // If the status is the same, sort by date
        return (
          new Date(b.shipmentDate).getTime() -
          new Date(a.shipmentDate).getTime()
        );
      }
      // Prioritize 'DELIVERED' status
      return a.status === FulfillmentStatus.DELIVERED ? -1 : 1;
    };

    const findLastShipment = (type: string) => {
      const entity = subscription?.entities?.find(
        (entity) => entity?.type?.toUpperCase() === type
      );

      const partBundle = entity?.fulfillments
        ?.flatMap((fulfillment) => fulfillment?.parts || [])
        .filter((part) =>
          isDeliveredOrShipped(part?.status as FulfillmentStatus)
        )
        .sort(sortFulfillments)[0];

      const singlePart = entity?.fulfillments
        ?.filter((fulfillment) =>
          isDeliveredOrShipped(fulfillment?.status as FulfillmentStatus)
        )
        .sort(sortFulfillments)[0];

      return partBundle || singlePart;
    };

    const lastShipmentInk = findLastShipment(EntityType.INSTANT_INK);
    const lastShipmentPaper = findLastShipment(EntityType.INSTANTPAPER);

    if (!lastShipmentInk?.shipmentDate && !lastShipmentPaper?.shipmentDate) {
      return null;
    }

    return (
      <Gap8>
        {lastShipmentInk?.shipmentDate && (
          <span className="caption">
            {`${translate(
              'subscriptions.lastShipmentInkOn',
              'Last shipment: Ink on'
            )} ${transformDate(lastShipmentInk?.shipmentDate)}`}
          </span>
        )}
        {lastShipmentPaper?.shipmentDate && (
          <span className="caption">
            {`${translate(
              'subscriptions.lastShipmentPaperOn',
              'Last shipment: Paper on'
            )} ${transformDate(lastShipmentPaper?.shipmentDate)}`}
          </span>
        )}
      </Gap8>
    );
  }, [subscription?.entities, subscription?.type, translate]);

  const shipments = (
    <CardContainer>
      <Gap8>
        <div
          data-component="SubscriptionsShippingNameValue"
          className="caption"
        >
          {subscription?.shippingAddress?.firstName}{' '}
          {subscription?.shippingAddress?.lastName}
        </div>
        <div
          data-component="SubscriptionsShippingStreetValue"
          className="caption"
        >
          {subscription?.shippingAddress?.address}
        </div>
        <div
          data-component="SubscriptionsShippingCityStateCodeValue"
          className="caption"
        >
          {subscription?.shippingAddress?.city},{' '}
          {subscription?.shippingAddress?.state}{' '}
          {subscription?.shippingAddress?.postalCode}
        </div>
        <div
          data-component="SubscriptionsShippingPhoneValue"
          className="caption"
        >
          {subscription?.shippingAddress?.phoneNumber}
        </div>
        <div className="caption">
          <Link
            data-testid="ManageAddressLink"
            href={URLS.UnifiedShippingBillingURL}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              publishEvent(
                subscription?.type === SubscriptionType.INSTANT_INK
                  ? InstantInkManageAddressHyperLinkClicked(
                      resourceId,
                      subscription.startDate
                    )
                  : HpAllInManageAddressHyperLinkClicked(
                      resourceId,
                      subscription.startDate
                    )
              )
            }
          >
            {`${translate('subscriptions.manageAddress', 'Manage address')}`}
          </Link>
        </div>
      </Gap8>
      <History>
        {ShipmentHistoryContent}
        <ShipmentHistoryLink />
      </History>
    </CardContainer>
  );

  const noShipments = (
    <EmptyContent>
      <IconPin />
      <div className="caption">
        <a
          href={URLS.UnifiedShippingBillingURL}
          target="_blank"
          rel="noreferrer"
        >
          {`${translate(
            'subscriptions.addShippingAddress',
            'Add shipping address'
          )}`}
        </a>
      </div>
    </EmptyContent>
  );

  return (
    <Card
      title={{
        content: translate('subscriptions.shipping', 'Shipping') as string
      }}
      data-testid="payment-section"
    >
      {subscription?.shippingAddress ? shipments : noShipments}
    </Card>
  );
};
