import { Stack } from '@jarvis/web-stratus-client';

const HP_ID_PROD_URL = 'https://account.id.hp.com';
const HP_ID__STG_URL = 'https://account.stg.cd.id.hp.com';

export const getHPIdUrl = () => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod ? HP_ID_PROD_URL : HP_ID__STG_URL;
};

export const URLS = {
  HpIdSecurity: `${getHPIdUrl()}/security`,
  MobileTwoFactorAuth: `${getHPIdUrl()}/webcomponents/static/mfa.html?csrf=c309cf99-c771-4dee-8e99-ff26210fb8df_w2`
};
