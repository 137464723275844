import React, { useEffect, useState } from 'react'
import { TranslatorFunctionType } from './types/localization'
import { MfePropsType } from './types/mfeProps'
import { BrowserRouter } from 'react-router-dom'
import { MainRoutes } from './routes/MainRoutes'
import TopBanner from './components/TopBanner'
import Loader from './components/Loader'
import useUsersApiCall from './hooks/useUserApiCall'
import useRefreshScopesApiCall from './hooks/useRefreshScopesApiCall'
import useAccountApiCall from './hooks/useAccountApiCall'
import useGrantsApiCall from './hooks/useGrantsApiCall'
import useFeatureFlags, { FeatureFlagsEnum } from './hooks/useFeatureFlags'
import { MfeManagerProvider, MyAccountProvider } from './contexts'
import { ErrorWidget } from './components/ErrorWidget'
import { ErrorBoundary } from './components/ErrorBoundary'
import { collectErrors, logErrors } from './utils/errorHandler'
import './styles/global.scss'

const App = ({
  t,
  stack,
  authProvider,
  store,
  accessControl,
  removeLocalStorageScopesItem
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const [isFetching, setIsFetching] = useState(true)

  const checkScopesForProfileAndUserMenus = useFeatureFlags(
    FeatureFlagsEnum.checkScopesForProfileAndUserMenus
  )

  /*
    The 'scopes' item is always deleted when My Account starts up,
    to prevent a user from accessing My Account with another user's scopes.
  */
  useEffect(() => {
    if (removeLocalStorageScopesItem) {
      removeLocalStorageScopesItem()
    }
  }, [removeLocalStorageScopesItem])

  const refreshScopes = useRefreshScopesApiCall({ accessControl })

  const userInfos = useUsersApiCall({
    authProvider,
    stack,
    store
  })

  const tenantId: string = userInfos.data?.userTenantDetail?.tenantResourceId

  const account = useAccountApiCall({
    authProvider,
    stack
  })

  const countrySet: Array<string> = account.data?.countrySet || []

  const grants = useGrantsApiCall({
    authProvider,
    stack,
    store,
    init: false
  })

  const { makeApiCall: grantsMakeApiCall } = grants

  useEffect(() => {
    if (tenantId) grantsMakeApiCall(tenantId)
  }, [grantsMakeApiCall, tenantId])

  useEffect(() => {
    setIsFetching(
      userInfos.pending ||
        refreshScopes.pending ||
        account.pending ||
        grants.pending
    )
  }, [
    userInfos.pending,
    refreshScopes.pending,
    account.pending,
    grants.pending
  ])

  const { errors, hasErrors } = collectErrors(
    userInfos.error,
    tenantId,
    account.error,
    grants.error,
    refreshScopes.error,
    checkScopesForProfileAndUserMenus
  )

  const requestsFinished =
    userInfos.success !== null &&
    refreshScopes.success !== null &&
    account.success !== null &&
    grants.success !== null

  useEffect(() => {
    if (requestsFinished && hasErrors) {
      logErrors(errors)
    }
  }, [requestsFinished, hasErrors, errors])

  const showDashboard = !isFetching && !hasErrors && requestsFinished

  return (
    <ErrorBoundary>
      <MyAccountProvider t={t}>
        {isFetching && <Loader />}

        {!isFetching && hasErrors && <ErrorWidget />}

        {showDashboard && (
          <MfeManagerProvider
            countrySet={countrySet}
            accessControl={accessControl}
            store={store}
          >
            <BrowserRouter>
              <TopBanner
                authProvider={authProvider}
                stack={stack}
                store={store}
              />
              <MainRoutes stack={stack} />
            </BrowserRouter>
          </MfeManagerProvider>
        )}
      </MyAccountProvider>
    </ErrorBoundary>
  )
}

export default App
