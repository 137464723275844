import styled from 'styled-components'
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import Modal from '@veneer/core/dist/scripts/modal'
import tokens from '@veneer/tokens'

export const ModalBox = styled.div`
  padding-bottom: ${tokens.layout.size5};
`

export const ModalTitle = styled.label`
  line-height: ${tokens.typography.lineHeight6};
  vertical-align: middle !important;
`

export const WarningAlert = styled(IconWarningAlt)`
  padding-right: ${tokens.layout.size2};
  transform: scale(1.5);
  vertical-align: middle;
`

export const MainModal = styled(Modal)`
  .vn-modal--content {
    max-width: 612px;
  }
  .vn-button-group--responsive {
    float: right;
  }
  .vn-modal--footer {
    justify-content: right;
  }
`
