import { Stack } from '@jarvis/web-stratus-client';

export const HP_SMART_PROD_URL = 'https://www.hpsmart.com/ucde';
export const HP_SMART_STAGE_URL =
  'https://consumer.stage.portalshell.int.hp.com';

export const HP_ID_PROD_URL = 'https://account.id.hp.com';
export const HP_ID_STAGE_URL = 'https://account.stg.cd.id.hp.com';

export const getHpSmartUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod ? HP_SMART_PROD_URL : HP_SMART_STAGE_URL;
};

export const getHPIdUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod ? HP_ID_PROD_URL : HP_ID_STAGE_URL;
};

export const URLS = {
  HpProfile: `${getHPIdUrl()}/profile`,
  UnifiedShippingBillingURL: `${getHpSmartUrl()}/account-details/shipping-billing`,
  EditAddressHpStore:
    'https://www.hp.com/us-en/shop/AddressBookDashboard?catalogId=10051&addressBookStyle=strong&storeId=10151&langId=-1',
  HpStoreNewsletter:
    'https://www.hp.com/us-en/shop/ManageSubscription?catalogId=10051&orderStatusStyle=strong&storeId=10151&langId=-1'
};
