import {
  ApiSubscriptionStates,
  Card,
  CommercialSubscriptionStates,
  getSubscriptionCommercialState,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useCallback, useMemo } from 'react';
import ErrorBoundary from 'src/App/ErrorBoundary';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_SUBSCRIPTIONS_ADDRESSES from 'src/graphql/getSubscriptionsAddresses';
import {
  AddressesError,
  EditAddressHpAllInPlanHyperlinkClicked,
  EditAddressHpInstantInkHyperlinkClicked,
  publishEvent
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { Label, Row, Value } from '../../pages/Profile/styles';
import OldAddressCard from './OldAddressCard';
import { Divider, IconGhost, NoAddresses, NoAddressesText } from './styles';
import Loader from '../Loader';

interface Address {
  address: string | null;
  city: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  postalCode: string | null;
  resourceId: string | null;
  state: string | null;
}

interface Subscription {
  resourceId: string | null;
  friendlyId: string | null;
  shippingAddress: Address | null;
  type: SubscriptionType;
  state: ApiSubscriptionStates;
}

const OldAddresses = () => {
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const { useQuery } = northboundAPIs.v1.graphql.reactTools;
  const { loading, error, data } = useQuery(GET_SUBSCRIPTIONS_ADDRESSES);

  const subs = data?.account?.subscriptions || [];

  const instantInkSubs = subs.filter(
    (sub: Subscription) =>
      sub?.type.toUpperCase() === SubscriptionType.INSTANT_INK &&
      getSubscriptionCommercialState(sub.state, sub.type) !==
        CommercialSubscriptionStates.INACTIVE
  );
  const hpAllInSubs = subs.filter(
    (sub: Subscription) =>
      sub?.type.toUpperCase() === SubscriptionType.HP_ALL_IN &&
      getSubscriptionCommercialState(sub.state, sub.type) !==
        CommercialSubscriptionStates.INACTIVE
  );

  const AddressLine = useCallback(
    (address: Address, isInstantInk: boolean) => {
      if (!address) return null;

      return (
        <Value>
          <div
            data-component="UserDataField"
            className="caption"
          >
            {address.firstName} {address.lastName}
          </div>
          <div
            data-component="UserDataField"
            className="caption"
          >
            {address.address}
          </div>
          <div
            data-component="UserDataField"
            className="caption"
          >
            {address.city}, {address.state} {address.postalCode}
          </div>
          <div
            data-component="UserDataField"
            className="caption"
          >
            {address.phoneNumber}
          </div>
          <div>
            <a
              className="caption"
              data-testid={`edit-address-${
                isInstantInk ? 'instant-ink' : 'hp-all-in'
              }`}
              href={URLS.UnifiedShippingBillingURL}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                isInstantInk
                  ? publishEvent(EditAddressHpInstantInkHyperlinkClicked)
                  : publishEvent(EditAddressHpAllInPlanHyperlinkClicked)
              }
            >
              {`${translate('common.edit', 'Edit')}`}
            </a>
          </div>
        </Value>
      );
    },
    [translate]
  );

  const subscriptionAddressRows = useMemo(() => {
    const showHpAllInSubs = hpAllInSubs.length > 0;
    const showInstantInkSubs = instantInkSubs.length > 0;

    if (!showHpAllInSubs && !showInstantInkSubs) {
      return (
        <NoAddresses
          content={
            <>
              <IconGhost size={40} />
              <NoAddressesText>
                {`${translate('profile.noAddresses', 'No addresses yet!')}`}
              </NoAddressesText>
            </>
          }
        />
      );
    }

    return (
      <>
        {showInstantInkSubs &&
          instantInkSubs.map((subscription: Subscription, index: number) => (
            <React.Fragment
              key={subscription.resourceId || subscription.friendlyId}
            >
              <Row>
                <Label>
                  <div
                    className="caption"
                    data-testid={`HP_II_${index}`}
                  >
                    {`${translate('common.instantInk', 'HP Instant Ink')}`}
                  </div>
                  <div
                    data-component="UserDataField"
                    className="caption"
                  >
                    {`${translate('common.id', 'ID:')}`}{' '}
                    {subscription.friendlyId || subscription.resourceId}
                  </div>
                </Label>
                {AddressLine(subscription.shippingAddress, true)}
              </Row>
              {index < instantInkSubs.length - 1 && <Divider />}
            </React.Fragment>
          ))}

        {showInstantInkSubs && showHpAllInSubs && (
          <Divider data-testid="divider" />
        )}

        {showHpAllInSubs &&
          hpAllInSubs.map((subscription: Subscription, index: number) => (
            <React.Fragment
              key={subscription.resourceId || subscription.friendlyId}
            >
              <Row>
                <Label>
                  <div
                    className="caption"
                    data-testid={`HP_ALL_${index}`}
                  >
                    {`${translate('common.allIn', 'HP All-In Plan')}`}
                  </div>
                  <div
                    data-component="UserDataField"
                    className="caption"
                  >
                    {`${translate('common.id', 'ID:')}`}{' '}
                    {subscription.friendlyId || subscription.resourceId}
                  </div>
                </Label>
                {AddressLine(subscription.shippingAddress, false)}
              </Row>
              {index < hpAllInSubs.length - 1 && <Divider />}
            </React.Fragment>
          ))}
      </>
    );
  }, [AddressLine, hpAllInSubs, instantInkSubs, translate]);

  if (error) {
    return (
      <Card
        title={{
          content: `${translate('profile.addresses', 'Addresses')}`
        }}
        rowsColorChangeEnabled={false}
        data-testid="addresses-error"
      >
        <ErrorBoundary analyticsEvent={AddressesError(error?.message)} />
      </Card>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return <OldAddressCard>{subscriptionAddressRows}</OldAddressCard>;
};

export default React.memo(OldAddresses);
