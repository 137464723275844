import {
  ACTIONS,
  activity,
  AnalyticsEventWithControlName,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { screenPath } from 'src/utils/constants';
import { ScreenName } from 'src/utils/enums';
import { getHPSmartPrinterOverviewUrl, URLS } from 'src/utils/urls';

export const DeviceRegisterButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'DeviceRegisterButton',
  controlDetail: URLS.Register,
  version: eventDetailVersion
};

export const GetSupportForDeviceNotShownButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    controlName: 'GetSupportForDeviceNotShownButton',
    controlDetail: URLS.DeviceSupportNotShown,
    version: eventDetailVersion
  };
export const HPSmartDevicesMyPrintersButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    controlName: 'HPSmartDevicesMyPrintersButton',
    controlDetail: getHPSmartPrinterOverviewUrl(),
    version: eventDetailVersion
  };

export const SeeDevicesPurchasedButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'SeeDevicesPurchasedButton',
  controlDetail: URLS.DevicesPurchased.slice(0, 256),
  version: eventDetailVersion
};
