import React, { useMemo } from 'react';
import PersonalDetails from 'src/components/PersonalDetails';
import OldPersonalDetails from 'src/components/OldPersonalDetails';
import Addresses from 'src/components/Addresses';
import OldAddresses from 'src/components/OldAddresses';
import { FeatureFlag, FeatureFlagLoadingState } from 'src/types/FeatureFlag';
import Loader from 'src/components/Loader';

interface WrapperProps {
  flags: FeatureFlag;
  flagsLoadingState: FeatureFlagLoadingState;
}

const ContentWrapper: React.FC<WrapperProps> = ({
  flags,
  flagsLoadingState
}) => {
  const { isProfileListPersonalDetailsEnabled: personalDetailsList } = flags;
  const { isProfileListPersonalDetailsLoading: personalDetailsListLoading } =
    flagsLoadingState;

  const PersonalDetailsWrapper = useMemo(() => {
    if (personalDetailsListLoading) {
      return <Loader />;
    }

    return personalDetailsList ? (
      <PersonalDetails flags={flags} />
    ) : (
      <OldPersonalDetails />
    );
  }, [flags, personalDetailsList, personalDetailsListLoading]);

  const AddressesWrapper = useMemo(() => {
    if (personalDetailsListLoading) {
      return <Loader />;
    }

    return personalDetailsList ? <Addresses /> : <OldAddresses />;
  }, [personalDetailsList, personalDetailsListLoading]);

  return (
    <>
      {PersonalDetailsWrapper}
      {AddressesWrapper}
    </>
  );
};

export default ContentWrapper;
