import React from 'react';
import PropTypes from 'prop-types';
import Button from '@veneer/core/dist/scripts/button';
import {
  ButtonGroup,
  Content,
  Header,
  HeaderIcon,
  StyledGenericModal,
} from './styles';

const GenericModal = ({
  children,
  dataTestId,
  footer,
  header,
  id,
  onClose,
  show,
  width,
  responsiveness,
  closeButton,
}) => {
  const {
    dataTestId: cancelButtonDataTestId,
    label: cancelButtonLabel,
    ...restCancelButtonProps
  } = footer?.buttons?.cancel || {};

  const {
    dataTestId: proceedButtonDataTestId,
    label: proceedButtonLabel,
    ...restProceedButtonProps
  } = footer?.buttons?.proceed || {};

  return (
    <StyledGenericModal
      closeOnBlur={false}
      data-testid={dataTestId}
      footer={footer ? (
        <ButtonGroup>
          <Button
            appearance="secondary"
            data-testid={cancelButtonDataTestId}
            {...(restCancelButtonProps || {})}
          >
            {cancelButtonLabel}
          </Button>
          <Button
            data-testid={proceedButtonDataTestId}
            {...(restProceedButtonProps || {})}
          >
            {proceedButtonLabel}
          </Button>
        </ButtonGroup>
      ) : undefined}
      id={id}
      onClose={onClose}
      show={show}
      width={width}
      closeButton={closeButton}
      responsiveness={responsiveness}
    >
      <Content>
        <Header
          {...header.dataTestId && { 'data-testid': header.dataTestId }}
          {...header.lineHeight && { lineHeight: header.lineHeight }}
        >
          {header.leadingIcon && (<HeaderIcon>{header.leadingIcon}</HeaderIcon>)}
          <div className="title-small">{header.title}</div>
        </Header>
        <div>{children}</div>
      </Content>
    </StyledGenericModal>
  );
};

GenericModal.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  footer: PropTypes.shape({
    buttons: PropTypes.shape({
      cancel: PropTypes.shape({
        dataTestId: PropTypes.string,
        disabled: PropTypes.bool,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
      proceed: PropTypes.shape({
        appearance: PropTypes.string,
        dataTestId: PropTypes.string,
        disabled: PropTypes.bool,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        leadingIcon: PropTypes.node,
        loading: PropTypes.bool,
      }),
    }),
  }),
  header: PropTypes.shape({
    bottomMargin: PropTypes.number,
    dataTestId: PropTypes.string,
    leadingIcon: PropTypes.node,
    lineHeight: PropTypes.number,
    title: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  width: PropTypes.number,
  closeButton: PropTypes.bool,
  responsiveness: PropTypes.oneOf(['default', 'full']),
};

GenericModal.defaultProps = {
  children: null,
  dataTestId: 'generic-modal',
  footer: null,
  id: 'generic-modal-id',
  onClose: null,
  width: null,
  closeButton: false,
  responsiveness: 'default',
};

export default GenericModal;
