import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container, Dot } from './styles';

const SupplyDot = ({ colorCode }) => {
  const colorName = useMemo(() => {
    switch (colorCode) {
      case 'C':
        return 'cyan';
      case 'M':
        return 'magenta';
      case 'Y':
        return 'yellow';
      case 'K':
        return 'black';
      default:
        return '';
    }
  }, [colorCode]);

  return (
    <Container>
      <Dot className={`caption-small dot ${colorName}`}>
        <div>{colorCode}</div>
      </Dot>
    </Container>
  );
};

SupplyDot.propTypes = {
  colorCode: PropTypes.string.isRequired,
};

export default SupplyDot;
