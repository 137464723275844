import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import VeneerStepper from '@veneer/core/dist/scripts/stepper';
import Tokens from '@veneer/tokens';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { Container } from './styles';

const Stepper = ({
  title,
  steps,
  onStepperNext,
  onStepperBack,
  stepNumber,
}) => {
  const [stepperData, setStepperData] = useState(steps.map(({ label }, i) => ({
    label,
    step: i + 1,
    status: i === 0 ? 'current' : 'incomplete',
  })));

  const [currentStepNumber, setCurrentStepNumber] = useState(stepNumber);
  const tablet = useMediaQuery(`(max-width: ${Tokens.lgMin})`);
  const mobile = useMediaQuery(`(max-width: ${Tokens.smMin})`);

  const onStepChange = useCallback((_, index) => {
    if (index === currentStepNumber + 1) {
      onStepperNext();
      return;
    }

    if (index === currentStepNumber - 1) {
      onStepperBack();
    }
  }, [currentStepNumber, onStepperBack, onStepperNext]);

  useEffect(() => {
    setCurrentStepNumber(stepNumber);

    if (stepNumber > currentStepNumber) {
      const newStepperData = stepperData;
      newStepperData[currentStepNumber].status = 'complete';
      newStepperData[currentStepNumber + 1].status = 'current';

      setStepperData(newStepperData);
      return;
    }

    if (stepNumber < currentStepNumber) {
      const newStepperData = stepperData;
      newStepperData[currentStepNumber].status = 'incomplete';
      newStepperData[currentStepNumber - 1].status = 'current';
      setStepperData(newStepperData);
    }
  }, [currentStepNumber, stepNumber, stepperData]);

  return (
    <Container>
      <p className="subtitle-large">{title}</p>
      <VeneerStepper
        appearance={mobile ? 'compact' : 'standard'}
        interactive
        steps={stepperData}
        onChange={onStepChange}
        direction={tablet ? 'horizontal' : 'vertical'}
        expanded
      />
    </Container>
  );
};

Stepper.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
  })).isRequired,
  onStepperNext: PropTypes.func.isRequired,
  onStepperBack: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

export default Stepper;
