import { Card as VeneerCard } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

interface ExpiredProps {
  isExpired: boolean;
}

export const Expired = styled.p<ExpiredProps>`
  color: ${(props) =>
    props.isExpired ? tokens.color.red7 : tokens.color.gray12};
`;

export const PayPalFlagImage = styled.img`
  width: 68px;
  height: 16.52px;
  margin-bottom: ${tokens.layout.size2};
`;

export const PaymentFlagImage = styled.img`
  width: 40px;
  height: ${tokens.layout.size7};
  margin-bottom: ${tokens.layout.size2};
`;

export const InnerCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  & > .div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${tokens.layout.size1};
    align-self: stretch;
  }

  & > #manage {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    & > button {
      color: ${tokens.color.hpBlue7};
      padding: 0;
      justify-content: flex-end;
      pointer-events: none;
    }
  }
`;

export const GhostContent = styled.div`
  display: flex;
  width: 100%;
  height: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${tokens.layout.size2};
`;

interface PaymentContainerCardProps {
  isEmpty?: boolean;
}

export const Card = styled(VeneerCard).attrs<PaymentContainerCardProps>(
  (props) => ({
    isEmpty: props.isEmpty ?? false
  })
)<PaymentContainerCardProps>`
  display: flex;
  padding: ${tokens.layout.size4} ${tokens.layout.size4} ${tokens.layout.size2};
  width: 290px;
  height: 146px;

  cursor: ${(props) => (props.isEmpty ? 'auto' : 'pointer')};
  box-sizing: border-box;
  display: flex;
  ${(props) =>
    !props.isEmpty &&
    `
    &:hover {
      background-color: ${tokens.color.gray1};
      border-radius: ${tokens.layout.cornerRadius3};
    }
    &:active {
      background-color: #21212133;
    }
  `}
`;

export const SubscriptionLinkedText = styled.div`
  font-size: ${tokens.typography.size0};
  color: ${tokens.color.gray6};
`;

export const TopWrapper = styled.div`
  width: inherit;
  height: stretch;
`;

export const EmailValue = styled.p`
  font-size: ${tokens.typography.size1};
  color: ${tokens.color.gray12};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit;
`;

export const ContainerManager = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;
