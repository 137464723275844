export enum DeviceType {
  PRINTER_DEVICE = 'PrinterDevice',
  PC_DEVICE = 'PCDevice'
}

export enum DeviceTypeParam {
  PRINTER = 'PRINTER',
  PC = 'PC',
  KEYBOARD = 'KEYBOARD',
  MOUSE = 'MOUSE',
  PEN = 'PEN'
}

export enum FeatureFlagsEnum {
  originalFeedbackExperience = 'original-feedback-experience'
}

export const enum ScreenName {
  DEVICES = 'Devices',
  DEVICE_DETAILS = 'DeviceDetails'
}
