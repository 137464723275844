const GET_USER_DETAILS = `
  query Query {
    account {
      user {
        email
        emailVerified
        phone
        phoneVerified
      }
    }
  }
`;

export default GET_USER_DETAILS;
