import {
  ACTIONS,
  activity,
  AnalyticsEventPropTypes,
  AnalyticsEventWithControlName,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { screenPath } from 'src/utils/constants';
import { ScreenName } from 'src/utils/enums';

export const DevicesError = (errorInfo: string): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}Devices/`,
    screenName: 'DevicesError',
    version: eventDetailVersion
  };
};
export const DevicesScreenDisplayed = (
  totalDeviceCT: string
): AnalyticsEventPropTypes => {
  return {
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    actionAuxParams: totalDeviceCT,
    version: eventDetailVersion
  };
};

export const RefreshDevicesButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'RefreshDevicesButton',
  version: eventDetailVersion
};
