import React from 'react';
import tokens from '@veneer/tokens';
import { Loading } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import {
  AnalyticsEventPropTypes,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import {
  CardBody,
  CardHeader,
  CardTitle,
  Center,
  Link,
  StyledCard
} from './styles';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

interface Url {
  link: string;
  text: string;
  analyticsEvent: AnalyticsEventPropTypes;
  isInternalLink?: boolean;
}

export interface IFrameCardBaseProps {
  icon?: React.ReactNode;
  title?: string;
  urls?: Url[];
}

export interface IFrameCardLoadingProps extends IFrameCardBaseProps {
  loading: true;
}

export interface IFrameCardContentProps extends IFrameCardBaseProps {
  loading?: false;
  icon: React.ReactNode;
  title: string;
  urls: Url[];
}

export type IFrameCardProps = IFrameCardLoadingProps | IFrameCardContentProps;

interface IFrameCardTemplateProps {
  card: IFrameCardProps;
}

const FrameCard = ({ card }: IFrameCardTemplateProps) => {
  const { northboundAPIs } = useDependencyManagerContext();

  const navigation = northboundAPIs.v1.navigation.push;

  if (card.loading) {
    return (
      <Center data-testid="frame-loading">
        <Loading color={tokens.color.gray5} />
      </Center>
    );
  }

  return (
    <StyledCard
      hoverable={false}
      appearance="outlined"
      data-testid="frame-card"
      content={
        <>
          <CardHeader className="header">
            <CardTitle className="body-large">{card.title}</CardTitle>
            {card.icon}
          </CardHeader>
          <CardBody className="body">
            {card.urls.map((url) => (
              <Link
                data-testid="frame-card-link"
                className="caption"
                rel="noreferrer"
                href={url.link}
                target="_blank"
                key={url.link}
                onClick={(event) => {
                  if (url.isInternalLink) {
                    event.preventDefault();
                  }
                  publishEvent(url.analyticsEvent);
                  if (url.isInternalLink) {
                    navigation(url.link);
                  }
                }}
              >
                {url.text}
              </Link>
            ))}
          </CardBody>
        </>
      }
    />
  );
};

export default FrameCard;
