import {
  activity,
  eventDetailVersion,
  AnalyticsEventPropTypes,
  AnalyticsEventWithControlName,
  ACTIONS
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { URLS } from './urls';
import { FormTypesEnum } from './enums';

export const screenPath = '/ReactMyAccountSecurity/';
export const screenDetailsPath = '/ReactMyAccountSecurity/Security/';
export const screenName = 'Security';
export const screenDetailsName = 'SecurityDetails';

export const SecurityScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const SecurityDetailsScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath: screenDetailsPath,
  screenName: screenDetailsName,
  version: eventDetailVersion
};

export const ManageLoginDetailsHyperLinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManageLoginDetailsLink',
    controlDetail: URLS.HpIdSecurity,
    version: eventDetailVersion
  };

export const ManageEmailHyperLinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManageEmailLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ManageEmailControlButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManageEmailButton',
  actionAuxParams: `type=${FormTypesEnum.Email}`,
  version: eventDetailVersion
};

export const ManagePhoneHyperLinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManagePhoneLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ManagePhoneControlButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManagePhoneButton',
  actionAuxParams: `type=${FormTypesEnum.Phone}`,
  version: eventDetailVersion
};

export const ChangePasswordHyperLinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ChangePasswordLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ChangePasswordControlButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ChangePasswordButton',
    actionAuxParams: `type=${FormTypesEnum.Password}`,
    version: eventDetailVersion
  };

export const ManageAdvancedSecurityOptionsHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManageAdvancedSecurityOptionsLink',
    controlDetail: URLS.HpIdSecurity,
    version: eventDetailVersion
  };

export const MobileTwoFactorAuthHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'MobileTwoFactorAuthLink',
    controlDetail: URLS.MobileTwoFactorAuth,
    version: eventDetailVersion
  };

export const ManageActiveSessionsHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManageActiveSessionsLink',
    controlDetail: URLS.HpIdSecurity,
    version: eventDetailVersion
  };

export const LoginDetailsError = (
  errorInfo: string
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}Security/`,
    screenName: 'LoginDetailsError',
    version: eventDetailVersion
  };
};
