import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const SubSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${tokens.layout.size2};
  padding-left: ${tokens.layout.size4};
`;
