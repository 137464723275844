import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const CardSection = styled(Card)`
  display: flex;
  padding: ${tokens.layout.size4};
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size2};

  @media screen and (max-width: ${tokens.layout.smMin}) {
    width: stretch;
  }
`;

export const DeviceImage = styled.div`
  display: flex;
  align-self: center;
  height: 220px;
  padding: 0 20px 0 20px;

  & > svg,
  img {
    cursor: pointer;
    width: 292.67px;
    height: 220px;

    @media screen and (max-width: ${tokens.layout.smMin}) {
      width: 100%;
      height: 160px;
    }
  }

  @media screen and (max-width: ${tokens.layout.smMin}) {
    height: 160px;
  }
`;

export const ContentType = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${tokens.layout.size2};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size1};
`;

const BaseText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 320px;

  @media screen and (max-width: ${tokens.layout.smMin}) {
    width: 200px;
  }
`;

export const DeviceName = styled(BaseText)`
  color: ${tokens.color.gray9};
`;

export const DeviceInfo = styled(BaseText)`
  color: ${tokens.color.gray6};
`;
