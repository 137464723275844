import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React from 'react';
import { HpStoreNewsletterButtonClicked } from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

const HPStoreNewsletter = () => {
  const { translate } = useDependencyManagerContext();

  return (
    <Card
      title={{
        content: translate(
          'profile.storeNewsletter',
          'HP Store Newsletter'
        ) as string,
        type: ROW_TYPE.EXTERNAL,
        link: {
          url: URLS.HpStoreNewsletter,
          dataTestId: 'HpStoreNewsletterButton'
        },
        analyticsEvent: HpStoreNewsletterButtonClicked
      }}
      rowsColorChangeEnabled={false}
      data-testid="hp-store-newsletter"
    >
      {`${translate(
        'profile.storeNewsletterDescription',
        'Stay informed on the latest products, the hottest deals, and our special sales through our award-winning HP Store Newsletter.'
      )}`}
    </Card>
  );
};

export default HPStoreNewsletter;
