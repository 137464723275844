import {
  ACTIONS,
  activity,
  AnalyticsEventWithControlName,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { screenPath, screenPathDevice } from 'src/utils/constants';
import { ScreenName } from 'src/utils/enums';
import { getHPSmartPrinterOverviewUrl, URLS } from 'src/utils/urls';

export const ControlTileClicked = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_TILE_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    controlName: 'DeviceDetailsTileCard',
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const DeviceOverview = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath: screenPathDevice,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'PC',
    controlName: 'DeviceOverviewLink',
    controlDetail: URLS.SupportDashboard,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const PrinterOverview = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath: screenPathDevice,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'PrinterOverviewLink',
    controlDetail: getHPSmartPrinterOverviewUrl(),
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const ViewWarrantyStatus = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath: screenPathDevice,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ViewWarrantyStatusLink',
    controlDetail: URLS.SupportDashboard,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};
