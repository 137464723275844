import React, { useMemo } from 'react';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import Router from 'src/router';
import * as T from './types';
import { Container } from './styles';
import '../styles/global.scss';

const AppComponent: React.FC<T.AppPropsType> = () => {
  const { northboundAPIs } = useDependencyManagerContext();

  const client = useMemo(
    () => northboundAPIs.v1.graphql.getClient(),
    [northboundAPIs.v1.graphql]
  );
  const GraphQLProvider = useMemo(
    () => northboundAPIs.v1.graphql.reactTools.createGraphQLProvider(React),
    [northboundAPIs.v1.graphql.reactTools]
  );

  return (
    <GraphQLProvider client={client}>
      <Container data-testid="profile-page">
        <Router />
      </Container>
    </GraphQLProvider>
  );
};

const App: React.FC<T.AppPropsType> = () => {
  return (
    <DependencyManagerProvider>
      <AppComponent />
    </DependencyManagerProvider>
  );
};

export default App;
