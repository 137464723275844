import React from 'react';
import PropTypes from 'prop-types';
import NewLogoHpPlusSvg from '../../assets/images/hp-plus-logo.svg';
import { NewLogoHpPlus } from './styles';

const BlackHPPlus = ({
  height, width,
}) => (
  <NewLogoHpPlus
    src={NewLogoHpPlusSvg}
    height={height}
    width={width}
    alt="HP Plus"
  />
);

BlackHPPlus.defaultProps = {
  height: '36px',
  width: '36px',
};

BlackHPPlus.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default BlackHPPlus;
