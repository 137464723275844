import { Stack } from '@jarvis/web-stratus-client';
export const PORTAL_PROD_URL = 'https://account.hp.com';
export const PORTAL_STAGE_URL = 'https://myaccount.stg.portalshell.int.hp.com';

export const getPortalUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  const devStacks = [Stack.dev, Stack.pie, Stack.stage];

  return devStacks.includes(stack) ? PORTAL_STAGE_URL : PORTAL_PROD_URL;
};

export const URLS = {
  MyOrders: 'https://www.hp.com/us-en/shop',
  OrderHistory:
    'https://www.hp.com/us-en/shop/MyOrdersView?catalogId=10051&storeId=10151&langId=-1',
  GuestOrders:
    'https://www.hp.com/us-en/shop/MyAccountOrderStatusView?catalogId=10051&langId=-1&storeId=10151'
};
