import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const HyperlinkText = styled.a`
  color: ${tokens.colorHpBlue6};

  &:hover {
    color: ${tokens.colorHpBlue7};
  }

  &:active {
    color: ${tokens.colorHpBlue8};
  }
`;

export default HyperlinkText;
