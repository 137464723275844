
import { EventServiceErrorType, EventServicePluginError, Publisher as EventServicePublisher } from '@jarvis/jweb-core';
import { ErrorMessages } from '../utils/ErrorMessages';
import { eventServicePluginErrorMethod } from '../utils/exceptions';
import { validateIdentifier } from '../utils/ValidateIdentifier';
import { eventHub } from './EventHub';

let publisherIds: string[] = [];

export const Publisher = (publisherId: string): EventServicePublisher | EventServicePluginError => {
  if (publisherIds.includes(publisherId)) {
    return eventServicePluginErrorMethod(EventServiceErrorType.publisherIdAlreadyInUse, ErrorMessages.publisherIdAlreadyInUse);
  }
  publisherIds.push(publisherId);
  const publish = async (
    eventName: string,
    eventData: object,
  ): Promise<void | EventServicePluginError> => {
    if (!eventName || !validateIdentifier(eventName)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    eventHub.publish(eventName, publisherId, eventData);
  };
  const destroy = async (): Promise<void | EventServicePluginError> => {
    console.info ('destroyed the publisher');
    publisherIds = publisherIds.filter((id: string) => id !== publisherId);
  };
  return {
    publish,
    destroy
  };
};
