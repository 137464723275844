import {
  ACTIONS,
  eventDetailVersion,
  activity
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { ScreenName, ScreenPath } from 'src/utils/constants';

export const OrdersScreenDisplayed = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath: ScreenPath,
  screenName: ScreenName,
  version: eventDetailVersion
};
