import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => (props.largeSize ? '346px' : '250px')};
  align-items: center;
  justify-content: center;
  gap: ${tokens.space2};
`;

export const Icon = styled.div`
  align-items: center;
  background-color: rgb(88, 88, 88, 0.1);
  border-radius: 50%;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
`;

export const Title = styled.div`
  color: ${tokens.colorGray7};
`;

export const SubTitle = styled.div`
  color: ${tokens.colorGray5};
`;
