import styled from 'styled-components';
import Modal from '@veneer/core/dist/scripts/modal';
import VeneerGroupButton from '@veneer/core/dist/scripts/button_group';
import Tokens from '@veneer/tokens';
import { lg, md, sm } from '../../helpers/mixins/mediaQueries';

export const ButtonGroup = styled(VeneerGroupButton)`
  justify-content: flex-end;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${Tokens.space5};
`;

export const Header = styled.div`
  align-items: flex-start;
  column-gap: ${Tokens.space2};
  display: flex;
`;

export const HeaderIcon = styled.div`
  flex: 0 0 auto;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;
  }

  ${sm(`
    height: 32px;
    width: 32px;
  `)};

  ${md(`
    height: 36px;
    width: 36px;
  `)};
`;

export const StyledGenericModal = styled(Modal)`
  min-width: 320px;

  .vn-modal--content {
    ${props => props.width
      && md(`width: ${props.width}px;`)}

    ${props => props.responsiveness === 'full'
      && lg(`
        width: auto;
        max-width: calc(100% - 24px - 24px);
      `)}
  }
`;

export const SubHeader = styled.div`
  margin: ${Tokens.space5} 0 ${Tokens.space2};
`;
