import tokens from '@veneer/tokens';

const breakpoints = {
  mobile: 375, // 375px
  xsMax: tokens.layout.xsMax, // 575px
  smMin: tokens.layout.smMin, // 576px
  mdMin: tokens.layout.mdMin, // 768px
  mdMax: tokens.layout.mdMax, // 991px
  lgMin: tokens.layout.lgMax, // 1199px
  xlMin: tokens.layout.xlMin // 1200px
};

export const MainTheme = {
  breakpoints,
  mediaQueries: {
    mobile: `(max-width: 375px)`,
    maxWidthMobile: `(max-width: ${breakpoints.xsMax})`,
    small: `(min-width: ${breakpoints.xsMax}) and (max-width: ${breakpoints.mdMin})`,
    medium: `(min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.lgMin})`,
    large: `min-width: ${breakpoints.lgMin}`
  }
};
