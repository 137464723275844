const GET_USER_DETAILS = `
  query GetUserDetails {
    account {
      user {
        email
        fullName
        locale
        phone
        resourceId
      }
    }
  }
`;

export default GET_USER_DETAILS;
