import React, { memo } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import DeviceDetailing from 'src/pages/DeviceDetailing';
import Devices from 'src/pages/Devices';

const Router = () => {
  const { northboundAPIs } = useDependencyManagerContext();
  const { pathname } = northboundAPIs.v1.navigation.location || {};

  const pageName = pathname?.split('/')?.pop()?.toLowerCase();
  const devices = 'devices';

  let Page = Devices;

  const paths = pathname?.split('/').filter((item) => item != '') || [];

  if (paths.length > 1 && paths.includes(devices) && pageName !== devices) {
    Page = DeviceDetailing;
  }

  return <Page />;
};

export default memo(Router);
