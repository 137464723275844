import styled from 'styled-components';
import Tokens from '@veneer/tokens';
import VeneerCard from '@veneer/core/dist/scripts/card';
import { lg } from '../../helpers/mixins/mediaQueries';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  background-color: ${props => props.theme.colorBackground};
  overflow: hidden;
  flex-direction: column;
`;

export const PageArea = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
`;

export const Content = styled.div`
  height: auto;
  width: 100%;
  ${props => (props.multiColumn
    ? `
      max-width: unset;
      display: flex;
      flex-wrap: wrap;
      ${lg('flex-wrap: nowrap;')}
    ` : `
      max-width: 944px;
      display: grid;
      grid-template-rows: auto;
    `)}

  box-sizing: border-box;
  padding: ${props => props.theme.orbitalSix};
  gap: ${props => props.theme.orbitalSix};

  background-color: ${props => props.theme.colorBackground};

  @media (max-width: ${Tokens.mdMax}) {
    max-width: unset;
    height: auto;
    overflow-y: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  position: sticky;
  width: 100%;
  top: 0;
  flex-direction: row;
  align-items: center;
  padding: ${props => props.theme.orbitalFour};
  gap: ${Tokens.space3};
  background-color: ${props => props.theme.colorForegroundInverse};
  ${props => (props.showHeaderBottomBorder ? `border-bottom: 1px solid ${Tokens.colorGray3};` : '')}
`;

export const Card = styled(VeneerCard)`
  ${props => (props.multiColumn ? `
    width: 100%;
    ${lg('width: 50%;')}
    ` : '')}
`;
