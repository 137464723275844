const GET_SUBSCRIPTIONS = `
  query Query {
    account {
      subscriptions {
        type
        state
      }
    }
  }
`;

export default GET_SUBSCRIPTIONS;
