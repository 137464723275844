import { Task } from './types';

async function registerTask(id: string, task: Task) {
  await (window as any).Shell.v1?.serviceRouting.registerBackgroundTask(
    id,
    task
  );
}

export default registerTask;
