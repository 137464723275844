import { useEffect, useMemo, useState } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

const launchDarklyClientKey = 'myaccount';

export enum FeatureFlagsEnum {
  welcomeNonUsUsers = 'welcome-non-us-users',
  originalFeedbackExperience = 'original-feedback-experience',
  securityHPIDPassword = 'security-hp-id-password',
  profileListPersonalDetails = 'profile-list-personal-details'
}

const featureFlagsDefaultValues = {
  welcomeNonUsUsers: false,
  originalFeedbackExperience: false,
  securityHPIDPassword: false,
  profileListPersonalDetails: false
} as any;

const useFeatureFlags = (key: string) => {
  const { northboundAPIs } = useDependencyManagerContext();
  const getClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );
  const defaultValue = featureFlagsDefaultValues[key] || false;
  const [featureFlagValue, setFeatureFlagValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getFeatureFlagValue = async () => {
      const client = await getClient(launchDarklyClientKey);
      const featureFlagValue = await client?.getFeatureFlag({
        key,
        defaultValue
      });

      setFeatureFlagValue(featureFlagValue);
      setIsLoading(false);
    };

    getFeatureFlagValue();
  }, [key, getClient, defaultValue, setFeatureFlagValue]);

  return { featureFlagValue, isLoading };
};

export default useFeatureFlags;
