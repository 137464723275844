import React, { useCallback, useEffect, useMemo } from 'react';
import {
  GenericFormContainer,
  Loading,
  WIDGETS_TYPE,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { FormTypesEnum } from 'src/utils/enums';
import { SecurityDetailsScreenDisplayed } from 'src/utils/analytics';
import { FeatureFlagProps } from 'src/types/FeatureFlag';
import { Center } from 'src/styles/styles';

const SecurityDetails = ({ flags, flagsLoadingState }: FeatureFlagProps) => {
  const { translate, northboundAPIs } = useDependencyManagerContext();
  const navigation = northboundAPIs?.v1?.navigation;
  const country = northboundAPIs?.v1?.localization.country;
  const language = northboundAPIs?.v1?.localization.language;

  const path =
    (navigation.location.pathname.split('/').pop() as FormTypesEnum) || '';

  const pathToRedirect = useMemo(
    () => `/${country}/${language}/security`,
    [country, language]
  );

  const handleRedirect = useCallback(() => {
    navigation?.push(pathToRedirect);
  }, [navigation, pathToRedirect]);

  const isLoadingFlags =
    flagsLoadingState.isSecurityHpIdEmailLoading ||
    flagsLoadingState.isSecurityHpIdPhoneLoading ||
    flagsLoadingState.isSecurityHpIdPasswordLoading;

  useEffect(() => {
    if (!isLoadingFlags) {
      const formTypes = [] as FormTypesEnum[];

      flags.isSecurityHpIdEmailEnabled && formTypes.push(FormTypesEnum.Email);
      flags.isSecurityHpIdPhoneEnabled && formTypes.push(FormTypesEnum.Phone);
      flags.isSecurityHpIdPasswordEnabled &&
        formTypes.push(FormTypesEnum.Password);

      if (path !== null && formTypes.includes(path as FormTypesEnum)) {
        publishEvent({
          ...SecurityDetailsScreenDisplayed,
          actionAuxParams: `type=${path}`
        });
        return;
      }

      handleRedirect();
    }
  }, [
    flags.isSecurityHpIdEmailEnabled,
    flags.isSecurityHpIdPasswordEnabled,
    flags.isSecurityHpIdPhoneEnabled,
    handleRedirect,
    isLoadingFlags,
    path
  ]);

  const widgets: WIDGETS_TYPE[] = [];

  switch (path) {
    case FormTypesEnum.Email:
      widgets.push(WIDGETS_TYPE.CHANGE_EMAIL);
      break;
    case FormTypesEnum.Phone:
      widgets.push(WIDGETS_TYPE.PHONE);
      break;
    case FormTypesEnum.Password:
      widgets.push(WIDGETS_TYPE.CHANGE_PASSWORD);
      break;
    default:
      break;
  }

  if (isLoadingFlags) {
    return (
      <Center>
        <Loading testid="loading-security-details" />
      </Center>
    );
  }

  return (
    <div
      data-testid="security-details-page"
      aria-label="SecurityDetailsPage"
    >
      <GenericFormContainer
        data-testid="generic-form-container"
        title={translate('security.loginDetails', 'Login details')}
        stack={northboundAPIs.v1.app.getAuthStack()}
        language={`${language}_${country.toUpperCase()}`}
        showButtons={false}
        widgets={widgets}
        labels={{
          cancel: translate('common.cancel', 'Cancel'),
          save: translate('common.save', 'Save')
        }}
        onClickArrowLeft={handleRedirect}
      />
    </div>
  );
};

export default SecurityDetails;
