import { useCallback } from 'react';
import useApiCall from './useApiCall';
import getTenancyNode from '../services/getTenancyNode';

const useTenancyRegistryCall = ({
  authProvider,
  stack,
  id,
}) => {
  const apiCall = useCallback(async () => {
    const response = await getTenancyNode({ authProvider, stack, id });

    return response;
  }, [authProvider, id, stack]);

  return useApiCall({
    apiCall,
  });
};

export default useTenancyRegistryCall;
