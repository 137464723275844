import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';
import { ROW_TYPE } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import {
  IconPolicies,
  IconPrinter,
  IconShoppingCart,
  IconSupport
} from '@veneer/core';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { URLS, getHPSmartPrinterOverviewUrl } from 'src/utils/urls';
import {
  DeviceRegisterButtonClicked,
  GetSupportForDeviceNotShownButtonClicked,
  HPSmartDevicesMyPrintersButtonClicked,
  SeeDevicesPurchasedButtonClicked
} from './analytics';
import { DoNotSeeDeviceContainer } from './styles';

export const DoNotSeeDevice = () => {
  const { translate } = useDependencyManagerContext();

  const rows = [
    {
      index: 0,
      content: translate(
        'devices.seeDevice',
        `See devices you've purchased on HP.com`
      ) as string,
      icon: <IconShoppingCart filled={false} />,
      link: {
        dataTestId: 'DevicesPurchased',
        url: URLS.DevicesPurchased
      },
      type: ROW_TYPE.EXTERNAL,
      analyticsEvent: SeeDevicesPurchasedButtonClicked
    },
    {
      index: 1,
      content: translate(
        'devices.getSupportForDeviceNotShown',
        `Get support for a device that's not shown above`
      ) as string,
      icon: <IconSupport filled={false} />,
      link: {
        dataTestId: 'DeviceSupportNotShown',
        url: URLS.DeviceSupportNotShown
      },
      type: ROW_TYPE.EXTERNAL,
      analyticsEvent: GetSupportForDeviceNotShownButtonClicked
    },
    {
      index: 2,
      content: translate(
        'devices.viewOrAddPrinters',
        `View or add a printer on HP Smart`
      ) as string,
      icon: <IconPrinter filled={false} />,
      link: {
        dataTestId: 'HPSmartDevicesMyPrinters',
        url: getHPSmartPrinterOverviewUrl()
      },
      type: ROW_TYPE.EXTERNAL,
      analyticsEvent: HPSmartDevicesMyPrintersButtonClicked
    },
    {
      index: 3,
      content: translate(
        'devices.registerDevice',
        `Register a device`
      ) as string,
      icon: <IconPolicies filled={false} />,
      link: {
        dataTestId: 'Register',
        url: URLS.Register
      },
      type: ROW_TYPE.EXTERNAL,
      analyticsEvent: DeviceRegisterButtonClicked
    }
  ] as CardRowProps[];

  return (
    <DoNotSeeDeviceContainer
      title={{
        content: translate(
          'devices.dontSeeDevice',
          `Don't see the device you're looking for?`
        ) as string
      }}
      rows={rows}
    />
  );
};
