import {
  CreateSubscriberOptions,
  EventInfo,
  EventServiceErrorType,
  EventServicePluginError,
  SubscribeOptions,
  SubscriptionHandle,
} from '@jarvis/jweb-core';
import { validateIdentifier } from '../../utils/ValidateIdentifier';
import { eventServicePluginErrorMethod } from '../../utils/exceptions';
import { ErrorMessages } from '../../utils/ErrorMessages';
import { eventHub } from '../EventHub';
import { EventSubscriber } from '../../web';
import { Subscription } from './Subscription';

// Represents subscriber for the events
export class Subscriber implements EventSubscriber {
  private subscriptions: Subscription[];
  private isPersistent?: boolean;
  events: any[];
  isPaused?: boolean;
  subscriberId?: string;

  constructor(options?: CreateSubscriberOptions) {
    this.subscriptions = [];
    this.events = [];
    this.isPaused = false;
    this.isPersistent = options?.isPersistent;
    this.subscriberId = options?.subscriberId;
  }

  // The method used to subscribe to an event.
  async subscribe(options: SubscribeOptions, eventHandler: (eventInfo: EventInfo) => void): Promise<SubscriptionHandle | EventServicePluginError> {
    if (options.eventName && !validateIdentifier(options.eventName)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    if (options.publisherId && !validateIdentifier(options.publisherId)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    try {
      const subscription = new Subscription(
        this,
        eventHandler,
        options.publisherId,
        options.eventName,
      );
      const subscriptionHandle: SubscriptionHandle = {
        publisherId: options.publisherId,
        eventName: options.eventName,
        isActive: true,
        unsubscribe: async (): Promise<void | EventServicePluginError> => {
          eventHub.unsubscribe(subscription);
        }
      };
      eventHub.subscribe(subscription, options.eventName, options.publisherId);

      this.subscriptions.push(subscription);
      console.log('Subscriber: subscribe()');
      console.log(`Subscriptions: ${this.subscriptions}`);
      return subscriptionHandle;
    } catch (e) {
      console.error(e);
      return eventServicePluginErrorMethod(EventServiceErrorType.unexpectedError, ErrorMessages.unexpectedError);
    }
  }

  // The method used to unsubscribe from an event.
  async unsubscribe(
    eventName?: string,
    publisherId?: string
  ): Promise<void | EventServicePluginError> {
    if (eventName && !validateIdentifier(eventName)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    if (publisherId && !validateIdentifier(publisherId)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    try {
      const subsToRemove: Subscription[] = [];
      this.subscriptions.forEach(sub => {
        if ((!eventName && !publisherId) ||
          (!publisherId && eventName && sub.eventName === eventName) ||
          (!eventName && publisherId && sub.publisherId === publisherId) ||
          (eventName && publisherId && sub.eventName === eventName && sub.publisherId === publisherId)) {
          eventHub.unsubscribe(sub);
          subsToRemove.push(sub);
        }
      });

      subsToRemove.forEach(sub => {
        this.subscriptions = this.subscriptions.filter(item => item !== sub);
      });
      console.log('Subscriber: unsubscribe()');
      console.log(`Subscriptions: ${this.subscriptions}`);
    } catch (e) {
      console.error(e);
      return eventServicePluginErrorMethod(EventServiceErrorType.unexpectedError, ErrorMessages.unexpectedError);
    };
  }

  async pauseEvents(): Promise<void | EventServicePluginError> {
    if (!this?.isPersistent) {
      return eventServicePluginErrorMethod(EventServiceErrorType.subscriberNotPersistent, ErrorMessages.subscriberNotPersistent);
    }
    if (!this.isPaused) {
      this.isPaused = true;
      console.log('subscriptions', this.subscriptions);
    } else {
      console.log('subscriber is already in paused state');
    }
  }

  async resumeEvents(): Promise<void | EventServicePluginError> {
    if (!this?.isPersistent) {
      return eventServicePluginErrorMethod(EventServiceErrorType.subscriberNotPersistent, ErrorMessages.subscriberNotPersistent);
    }
    if (this.isPaused) {
      console.log('resuming the event');
      this.isPaused = false;
      this.subscriptions.forEach((sub) => {
        eventHub.resumeEvent(sub);
      });
    } else {
      console.log('subscriber is not in paused state');
    }
  }

  async getSubscriptionHandles(): Promise<EventServicePluginError | SubscriptionHandle[]> {
    return this.subscriptions.map(sub => this.createSubscriptionHandle(sub));
  }

  private createSubscriptionHandle(subscription: Subscription): SubscriptionHandle {
    return {
      publisherId: subscription.publisherId,
      eventName: subscription.eventName,
      isActive: true,
      unsubscribe: async (): Promise<void | EventServicePluginError> => {
        eventHub.unsubscribe(subscription);
      }
    };
  };
}


