import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

const breakpoints = {
  xsMin: 375, // 375px
  xsMax: tokens.layout.xsMax, // 575px
  smMin: tokens.layout.smMin, // 576px
  mdMin: tokens.layout.mdMin, // 768px
  mdMax: tokens.layout.mdMax, // 991px
  lgMax: tokens.layout.lgMax, // 1199px
  xlMin: tokens.layout.xlMin, // 1200px
  xxlMin: 1399, // 1399px
  xxlMax: 1400 // 1400px
};

export const MainTheme = {
  breakpoints,
  mediaQueries: {
    maxWidthMobile: `(max-width: ${breakpoints.xsMax})`,
    xsmall: `(max-width: ${breakpoints.xsMax})`,
    small: `(min-width: ${breakpoints.smMin}) and (max-width: ${breakpoints.mdMin})`,
    medium: `(min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.mdMax})`,
    large: `(min-width: ${tokens.layout.lgMin}) and (max-width: ${breakpoints.lgMax})`,
    xLarge: `(min-width: ${breakpoints.xlMin}) and (max-width: ${breakpoints.xxlMin})`,
    xxLarge: `(min-width: ${breakpoints.xxlMax})`
  }
};

export const Section = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${tokens.layout.size4};
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  align-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size4};
  align-self: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size6};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.medium}) {
    gap: ${tokens.layout.size8};
  }
`;

export const Link = styled.a`
  color: ${tokens.color.hpBlue6};
  text-decoration: none;

  @media (hover: none) and (pointer: coarse) {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
`;
