import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import { MainTheme } from 'src/styles/styles';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};
  margin-bottom: ${tokens.layout.size5};

  && {
    h4 {
      font-size: ${tokens.typography.size7};
    }
  }
`;

export const Title = styled.h4`
  font-size: ${tokens.typography.size7};
  line-height: ${tokens.typography.lineHeight7};
  color: ${tokens.color.gray12};

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    font-size: ${tokens.typography.size6};
    line-height: ${tokens.typography.lineHeight6};
  }
`;

export const Subtitle = styled.p`
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  color: ${tokens.color.gray12};
`;

export const NoDevicesSection = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${tokens.layout.size6};
  max-width: 1140px;
  gap: ${tokens.layout.size1};
`;

export const NoDevicesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const DevicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1140px;
  gap: ${tokens.layout.size3};
`;

export const DevicesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${tokens.layout.size5};

  // TODO: Temporary solution (833px) until the layout for device cards is fixed
  @media screen and (max-width: 833px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const RefreshSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
