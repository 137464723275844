import readOnlyDataStreamer from '../utils/readOnlyDataStreamer';
import { handleSessionValidation } from '../utils/sessionValidation';

/**
 * Background Task: bgtInjectHpIdModule
 *
 * Purpose:
 * The bgtInjectHpIdModule background task aims to validate the user's session based on the hpid_id and access token.
 * If the session is not valid, the user is redirected to the login page. If the redirection has already occurred, the user is logged out.
 *
 * Outputs:
 * - No explicit output. The function returns a Promise<void>.
 *
 * Process Details:
 * 1. Retrieve hpid_id and access token
 * 2. Validate the session by comparing One Cloud (access token) and HP ID (hpid_id) sessions
 * 3. Redirect to login if the session is invalid and has not been redirected before
 * 4. Log out the user if the session is invalid and has already been redirected
 * 5. Log errors and throw exceptions on failure.
 */
export const bgtInjectHpIdModule = async (): Promise<void> => {
  const shell = window?.Shell?.v1;
  if (!shell || !shell.authProvider) {
    console.error('bgtInjectHpIdModule: Shell is not defined');
    return;
  }

  try {
    const accessToken =
      await shell.authProvider.getDecodedUserStratusJWTPayload();

    if (accessToken) {
      await readOnlyDataStreamer('hpid_id', async (hpidId: string) => {
        await handleSessionValidation(hpidId, accessToken);
      });
    } else {
      console.warn(
        'bgtInjectHpIdModule: Skipping session validation. Access token is not defined'
      );
    }
  } catch (error) {
    console.error('bgtInjectHpIdModule: Error', error);
  }
};
