import { Stack } from '@jarvis/web-stratus-client';

const SERVICES_URLS = {
  HpId: {
    prod: 'https://account.id.hp.com',
    stage: 'https://account.stg.cd.id.hp.com'
  },
  HpSmart: {
    prod: 'https://www.hpsmart.com/ucde',
    stage: 'https://consumer.stage.portalshell.int.hp.com'
  }
};
const BASE_VIRTUAL_AGENT_URL = 'https://virtualagent.hpcloud.hp.com/?';

export const getStack = (): Stack | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).Shell?.v1.app.getAuthStack();
};

type UrlKey = keyof typeof SERVICES_URLS;

export const getUrl = (key: UrlKey): string => {
  const stack = getStack();
  const devStacks = [Stack.dev, Stack.pie, Stack.stage];
  const env = devStacks.includes(stack) ? 'stage' : 'prod';

  return SERVICES_URLS[key][env];
};

export const getHpIdUrl = (): string => {
  return getUrl('HpId');
};

export const getHpSmartUrl = (): string => {
  return getUrl('HpSmart');
};

export const getCardsInstantInkRequestMoreInk = (
  language: string = 'en',
  country: string = 'us'
): string => {
  return `${BASE_VIRTUAL_AGENT_URL}botClient=Web&botSubClient=UMA&lc=${language}&cc=${country}`;
};

export const URLS = {
  Cards: {
    LoginAndSecurity: {
      ChangePassword: `${getHpIdUrl()}/security`,
      UpdatePersonalDetails: `${getHpIdUrl()}/profile`,
      ChangePasswordOnPortal: `/security/password`,
      UpdatePersonalDetailsOnPortal: `/profile`
    },
    YourPurchases: {
      ViewOrderHistory:
        'https://www.hp.com/us-en/shop/MyOrdersView?catalogId=10051&langId=-1&storeId=10151',
      StartReturn:
        'https://www.hp.com/us-en/shop/cv/returnsandexchanges?pStoreID=hpepp'
    },
    InstantInk: {
      UpdatePaymentMethod: `${getHpSmartUrl()}/account-details/shipping-billing`,
      ChangePauseOrCancelPlan: `${getHpSmartUrl()}/print_plans/my_account`,
      ViewPrintUsage: `${getHpSmartUrl()}/print_plans`
    },
    HpAllIn: {
      UpdatePaymentMethod: `${getHpSmartUrl()}/account-details/shipping-billing`,
      ChangeOrCancelPlan: `${getHpSmartUrl()}/hp-all-in-print-plan/update-plan`,
      ViewPrintUsage: `${getHpSmartUrl()}/hp-all-in-print-plan`,
      ContactProLiveSupport: `https://support.hp.com/us-en/contact/all-in-plan-serial-number`
    },
    OtherLinks: {
      PrinterShowsAccountIssue: `${getHpSmartUrl()}/account-details/view-notifications`,
      AddPrinter: `${getHpSmartUrl()}/my-printers`,
      GetProductSupport: `https://support.hp.com/us-en`,
      RegisterDevice: `https://www.register.hp.com/`
    }
  }
};
