export enum FeatureFlagsEnum {
  originalFeedbackExperience = 'original-feedback-experience'
}

export enum Stack {
  dev,
  pie,
  stage,
  prod
}
