import { useEffect, useState } from 'react';

const useCallSuccess = ({
  init = true,
  call,
  onSuccess,
}) => {
  const [hasRun, setHasRun] = useState(false);

  const {
    pending,
    success,
    data,
  } = call;

  useEffect(() => {
    if (pending && hasRun) {
      setHasRun(false);
    }
  }, [pending, hasRun]);

  useEffect(() => {
    if (hasRun || !init) {
      return;
    }

    if (!pending && success) {
      onSuccess(data.data);
      setHasRun(true);
    }
  }, [
    onSuccess,
    data,
    hasRun,
    init,
    pending,
    success,
  ]);
};

export default useCallSuccess;
