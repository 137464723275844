import {
  EnumAllowedLogType,
  LogFuncType
} from '@jarvis/shell-commons/dist/interface/v1/logger/types';

/**
 * Adds a script to the document head and executes a callback function when the script is loaded.
 * If the script fails to load, it does not attempt to reload it.
 *
 * @param {string} src - The source URL of the script to be added.
 * @param {Function} onLoad - The callback function to be executed once the script is loaded.
 */
const addScript = (
  src: string,
  logger: Record<EnumAllowedLogType, LogFuncType>,
  onLoad: () => void,
  id?: string,
  type?: string
) => {
  const existingScript = document.querySelector(`script[src*="${src}"]`);

  if (existingScript) {
    logger.log('script already loaded');
    if (existingScript.getAttribute('data-loaded') === 'true') {
      onLoad();
    } else {
      existingScript.addEventListener('load', onLoad);
    }
  } else {
    const script = document.createElement('script');
    if (id) {
      script.id = id;
    }
    if (type) {
      script.type = type;
    }
    script.src = src;
    script.async = true;
    script.onload = () => {
      script.setAttribute('data-loaded', 'true');
      logger.log('script loaded successfully');
      onLoad();
    };
    script.onerror = () => {
      logger.error(`error loading script: ${src}`);
    };
    document.head.appendChild(script);
  }
};

export default addScript;
