import { useEffect, useMemo, useState } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  launchDarklyClientKey,
  featureFlagsDefaultValues
} from 'src/utils/constants';

const useFeatureFlags = (key: string) => {
  const { northboundAPIs } = useDependencyManagerContext();
  const getClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );
  const defaultValue =
    featureFlagsDefaultValues[key as keyof typeof featureFlagsDefaultValues] ||
    false;
  const [featureFlagValue, setFeatureFlagValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getFeatureFlagValue = async () => {
      const client = await getClient(launchDarklyClientKey);
      const featureFlagValue = await client?.getFeatureFlag({
        key,
        defaultValue
      });

      setFeatureFlagValue(featureFlagValue as boolean);
      setIsLoading(false);
    };

    getFeatureFlagValue();
  }, [key, getClient, defaultValue, setFeatureFlagValue]);

  return { featureFlagValue, isLoading };
};

export default useFeatureFlags;
