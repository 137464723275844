import {
  EnumAllowedLogType,
  LogFuncType
} from '@jarvis/shell-commons/dist/interface/v1/logger/types';
import { Stack } from '@jarvis/web-stratus-client';
import addScript from 'src/utils/addScript';
import getFeatureFlagValue from 'src/utils/getFeatureFlagValue';
import getStack from 'src/utils/getStack';

const QUALTRICS_PROD =
  'https://znaid9q7g0kclquju-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_aid9q7g0kClquJU';
const QUALTRICS_STAGE =
  'https://zncuabwr7p241aiia-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_cuaBwr7P241aIia';

export const loadQualtricsScript = async (
  logger: Record<EnumAllowedLogType, LogFuncType>
) => {
  const ff = await getFeatureFlagValue(logger, 'qualtrics-survey');
  if (!ff) {
    logger.log('feature flag is false, script not loaded');
    return;
  }

  const stack = getStack();
  const QUALTRICS_SCRIPT_SRC =
    stack === Stack.stage ? QUALTRICS_STAGE : QUALTRICS_PROD;
  addScript(
    QUALTRICS_SCRIPT_SRC,
    logger,
    () => {
      return;
    },
    'qualtrics-script',
    'text/javascript'
  );
};

/**
 * Background Task: bgtInjectQualtricsSnippet
 *
 * This function asynchronously injects the Qualtrics snippet into the My Account Portal using ClientOS background tasks.
 *
 *  * Process Details:
 * 1. Check Qualtrics feature flag value.
 * 2. Load the Qualtrics script if the feature flag is true.
 * 3. Log errors and throw exceptions on failure.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
export const bgtInjectQualtricsSnippet = async (): Promise<void> => {
  const shell = window?.Shell?.v1;
  if (!shell) {
    console.error('bgtInjectQualtricsSnippet: Shell is not defined');
    return;
  }

  const logger = shell.logger.createLoggerInstance({
    preffixLog: 'Qualtrics:'
  });

  await loadQualtricsScript(logger);
};
