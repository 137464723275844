import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

export const useStaticStrings = () => {
  const { translate } = useDependencyManagerContext();

  return {
    fsDataComponentName: 'UserDataField',
    loginDetailsTitle: translate('security.loginDetails', 'Login details'),
    emailLabel: translate('profile.email', 'Email'),
    emptyEmail: translate('profile.noEmail', 'No email'),
    phoneLabel: translate('profile.phone', 'Phone'),
    emptyPhone: translate('profile.noPhone', 'No phone'),
    passwordMask: '*************',
    passwordLabel: translate('security.currentPassword', 'Current password')
  };
};
