import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';
import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { IconPlusOpenAll, IconShoppingCart } from '@veneer/core/';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { URLS } from 'src/utils/urls';
import {
  HpStoreOrdersButtonClicked,
  ManageMySubscriptionsButtonClicked
} from './analytics';

const MoreOptionsOrder = () => {
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const { country, language } = northboundAPIs.v1.localization || {};

  const rows = [
    {
      index: 0,
      icon: <IconPlusOpenAll />,
      type: ROW_TYPE.INTERNAL,
      content: translate(
        'orders.viewOrManageSubscriptions',
        'View or manage your subscriptions'
      ),
      link: {
        pathname: '/subscriptions',
        dataTestId: 'subscriptions-link'
      },
      analyticsEvent: ManageMySubscriptionsButtonClicked(country, language)
    },
    {
      index: 1,
      icon: <IconShoppingCart />,
      type: ROW_TYPE.EXTERNAL,
      content: translate(
        'orders.explore',
        'Explore the latest laptops, desktops, printers and accessories'
      ),
      link: {
        url: URLS.MyOrders,
        dataTestId: 'hp-store-orders'
      },
      analyticsEvent: HpStoreOrdersButtonClicked
    }
  ] as CardRowProps[];

  return (
    <Card
      title={{
        content: translate('orders.moreOptions', 'More options') as string
      }}
      rows={rows}
      data-testid="more-options-order"
    />
  );
};

export default MoreOptionsOrder;
