import { configure as configureSplunk } from '@jarvis/shell-splunk-rum';
import { ManifestType } from '../../types/manifest';

// FIXME: Update Shell Commons ManifestType
export default function configureSplunkUsingManifest(
  manifest: ManifestType
): void {
  try {
    if (
      manifest?.services?.splunk?.enabled &&
      manifest?.services?.splunk?.rumAuth
    ) {
      configureSplunk({
        beaconUrl: 'https://rum-ingest.us1.signalfx.com/v1/rum',
        rumAuth: manifest?.services?.splunk?.rumAuth,
        app: manifest?.services?.splunk?.appName || manifest?.portal?.appName,
        environment: manifest?.portal?.stack
      });
    }

    logSplunkSessionIdWhenAvailable();
  } catch (error) {
    console.error(error);
  }
}

function logSplunkSessionIdWhenAvailable() {
  let loggedToBrowser = false;
  let loggedToJWeb = false;
  const startTime = new Date().getMilliseconds();
  const intervalCheckToLogSplunkId = setInterval(() => {
    try {
      const splunkRumSessionId = (
        window as any
      )?.SplunkRum?.getSessionId?.() as string;
      const jWeb = (window as any)?.JWeb;

      if (splunkRumSessionId) {
        const message = `SplunkRum sessionId: ${splunkRumSessionId}`;

        if (!loggedToBrowser) {
          loggedToBrowser = true;
          console.debug(message);
        }
        if (jWeb && !loggedToJWeb) {
          loggedToJWeb = true;
          if (jWeb?.isNative) {
            jWeb?.Plugins?.Console?.log?.({
              logLevel: 'LOG',
              message: `SplunkRum sessionId: ${splunkRumSessionId}`
            });
          }
        }

        const duration = new Date().getMilliseconds() - startTime;
        const timeout = 10 * 1000;
        if ((loggedToBrowser && loggedToJWeb) || duration > timeout) {
          clearInterval(intervalCheckToLogSplunkId);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, 500);
}
