import { Stack } from '@jarvis/web-stratus-client';

const HP_ID_PROD_URL = 'https://account.id.hp.com';
const HP_ID_STAGE_URL = 'https://account.stg.cd.id.hp.com';

export const getHpIdMainUrl = (stack: Stack) => {
  const stageCase = [Stack.dev, Stack.pie, Stack.stage];
  const stackUrl = stageCase.includes(stack) ? HP_ID_STAGE_URL : HP_ID_PROD_URL;

  return `${stackUrl}/components/libs/releases/main.241114.1.js`;
};

export default getHpIdMainUrl;
