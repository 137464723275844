import {
  LookingForSomethingElse,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useEffect } from 'react';
import ManageOrdersSection from 'src/components/ManageOrdersSection';
import MoreOptions from 'src/components/MoreOptions';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import useFeatureFlags from 'src/hooks/useFeatureFlags';

import {
  OriginalFeedbackExperience,
  ScreenName,
  ScreenPath
} from 'src/utils/constants';
import { OrdersScreenDisplayed } from './analytics';
import { Container, Header, Subtitle, Title } from './styles';

export const OrdersPage = (): JSX.Element => {
  const { translate } = useDependencyManagerContext();

  const {
    featureFlagValue: originalFeedbackExperience,
    isLoading: isOriginalFeedbackExperienceLoading
  } = useFeatureFlags(OriginalFeedbackExperience);

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isOriginalFeedbackExperienceLoading;

  useEffect(() => {
    publishEvent(OrdersScreenDisplayed);
  }, []);

  return (
    <Container data-testid="orders-page">
      <Header>
        <Title>{`${translate('orders.ordersLabel', 'Orders')}`}</Title>
        <Subtitle>
          {`${translate('orders.viewAndManagePurchase', 'View and manage your purchases')}`}
        </Subtitle>
      </Header>
      <ManageOrdersSection />
      <MoreOptions />
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: ScreenName,
            screenPath: ScreenPath
          }}
          translate={translate}
        />
      )}
    </Container>
  );
};
