import tokens from '@veneer/tokens';
import { Card, IconGhostWaveWall } from '@veneer/core';
import styled from 'styled-components';

export const IconGhost = styled(IconGhostWaveWall)`
  && {
    color: ${tokens.color.gray9};
  }
`;

export const NoAddresses = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${tokens.layout.size8};
`;

export const NoAddressesText = styled.body`
  && {
    color: ${tokens.color.gray9};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${tokens.color.gray3};
`;
