export const collectErrors = (
  userInfosError: unknown,
  tenantId: string | undefined,
  accountError: unknown,
  grantsError: unknown,
  refreshScopesError: unknown,
  checkScopesForProfileAndUserMenus: unknown
): { errors: [string, unknown][]; hasErrors: boolean } => {
  const errors: [string, unknown][] = []

  const addError = (errorCondition: unknown, message: string) => {
    if (errorCondition) {
      errors.push([message, errorCondition])
    }
  }

  addError(userInfosError, 'Failed to fetch user information')
  addError(accountError, 'Failed to fetch account information')
  addError(grantsError, 'Failed to fetch grants information')
  if (!tenantId) errors.push(['Tenant ID is missing.', tenantId])
  if (checkScopesForProfileAndUserMenus && refreshScopesError) {
    addError(refreshScopesError, 'Failed to refresh scopes')
  }

  const hasErrors = errors.length > 0

  return { errors, hasErrors }
}

export const logErrors = (errors: [string, unknown][]): void => {
  errors.forEach(([message, error]) =>
    console.error('UMA Error during initialization:', message, error)
  )
}
