import { Stack } from '@jarvis/web-stratus-client';

const getStack = (): Stack => {
  const stack = window.Shell.v1.app.getAuthStack() ?? Stack.prod;
  const DEV_STACKS = [Stack.dev, Stack.pie, Stack.stage];

  return DEV_STACKS.includes(stack) ? Stack.stage : Stack.prod;
};

export default getStack;
