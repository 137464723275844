import { publishEvent } from '@hpx-core-experiences/react-my-account-commons/dist';
import React, { useEffect, useMemo } from 'react';
import { HomeScreenDisplayed } from 'src/components/FrameCards/analytics';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import Home from 'src/pages/Home';
import * as S from './styles';
import * as T from './types';

const AppComponent: React.FC<T.AppPropsType> = () => {
  return (
    <S.Container data-testid="home-page">
      <Home />
    </S.Container>
  );
};

const MainComponent: React.FC<T.AppPropsType> = () => {
  const { northboundAPIs } = useDependencyManagerContext();

  const client = useMemo(
    () => northboundAPIs.v1.graphql.getClient(),
    [northboundAPIs.v1.graphql]
  );

  const GraphQLProvider = useMemo(
    () => northboundAPIs.v1.graphql.reactTools.createGraphQLProvider(React),
    [northboundAPIs.v1.graphql.reactTools]
  );

  return (
    <GraphQLProvider client={client}>
      <AppComponent />
    </GraphQLProvider>
  );
};

const App: React.FC<T.AppPropsType> = () => {
  useEffect(() => {
    publishEvent(HomeScreenDisplayed);
  }, []);

  return (
    <DependencyManagerProvider>
      <MainComponent />
    </DependencyManagerProvider>
  );
};

export default App;
