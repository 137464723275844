import { IconArrowLeft, IconArrowRight, useDirection } from '@veneer/core';
import React, { useCallback, useEffect } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

import {
  Loading,
  LookingForSomethingElse,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import DeviceDetails from 'src/components/DeviceDetails';
import MoreOptions from 'src/components/MoreOptions';
import Subscriptions from 'src/components/Subscriptions';
import { DeviceDetailsError } from 'src/components/Subscriptions/analytics';
import UsersAndSharing from 'src/components/UsersAndSharing';
import GET_DEVICE from 'src/graphql/getDevice';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { usePathname } from 'src/hooks/usePathname';
import { ContainerContent, Section } from 'src/styles/styles';
import { screenPathDeviceDetailing } from 'src/utils/constants';
import { FeatureFlagsEnum, ScreenName } from 'src/utils/enums';
import getDeviceType from 'src/utils/getDeviceType';
import { DeviceDetailsHeader, Title } from './styles';

const DeviceDetailing = () => {
  const { translate, northboundAPIs } = useDependencyManagerContext();
  const deviceId = usePathname(true);

  const { useQuery } = northboundAPIs.v1.graphql.reactTools;

  const { data, loading, error } = useQuery(GET_DEVICE, {
    variables: {
      deviceId
    }
  });

  const navigation = northboundAPIs.v1.navigation;
  const country = northboundAPIs.v1.localization.country;
  const language = northboundAPIs.v1.localization.language;

  const { isRTL } = useDirection();
  const IconArrow = isRTL ? IconArrowRight : IconArrowLeft;

  const {
    featureFlagValue: originalFeedbackExperience,
    isLoading: isOriginalFeedbackExperienceLoading
  } = useFeatureFlags(FeatureFlagsEnum.originalFeedbackExperience);

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isOriginalFeedbackExperienceLoading;

  const goBack = useCallback(
    () => navigation.push(`/${country}/${language}/devices`),
    [country, language, navigation]
  );

  useEffect(() => {
    if (error) {
      publishEvent(DeviceDetailsError(error?.message));
      goBack();
    }

    if (!loading && !error) {
      const deviceExists = data?.devices?.items[0]?.deviceId === deviceId;

      if (!deviceExists || !data?.devices?.totalCount) {
        goBack();
      }
    }
  }, [
    data?.devices?.items,
    data?.devices?.totalCount,
    deviceId,
    error,
    goBack,
    loading
  ]);

  const device = data?.devices?.items[0];

  const deviceDetailsAnalytics = `deviceType=${getDeviceType(
    device
  )}&productNumber=${device?.identity?.makeAndModel?.number}`;

  const showUsersAndSharing = getDeviceType(device) === 'printer';

  return (
    <>
      <DeviceDetailsHeader data-testid="DeviceDetailsHeader">
        <IconArrow
          data-testid={isRTL ? 'IconArrowRight' : 'IconArrowLeft'}
          onClick={goBack}
        />
        <Title>
          {`${translate('devices.deviceDetails', 'Device details')}`}
        </Title>
      </DeviceDetailsHeader>
      {loading ? (
        <Section
          appearance="outlined"
          content={<Loading data-testid="loading" />}
        />
      ) : (
        <ContainerContent>
          <DeviceDetails device={device} />
          <Subscriptions
            device={device}
            deviceDetails={deviceDetailsAnalytics}
          />
          {showUsersAndSharing && (
            <UsersAndSharing deviceDetails={deviceDetailsAnalytics} />
          )}
          <MoreOptions deviceDetails={deviceDetailsAnalytics} />
        </ContainerContent>
      )}
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: ScreenName.DEVICE_DETAILS,
            screenPath: screenPathDeviceDetailing
          }}
          translate={translate}
        />
      )}
    </>
  );
};

export default DeviceDetailing;
