export const launchDarklyClientKey = 'myaccount';

export const featureFlagsDefaultValues = {
  originalFeedbackExperience: false
} as any;

export const screenPath = '/ReactMyAccountDevices/';
export const screenPathDevice = '/ReactMyAccountDevices/Devices/';
export const screenPathDeviceDetailing =
  '/ReactMyAccountDevices/DeviceDetails/';
