import {
  activity,
  eventDetailVersion,
  AnalyticsEventPropTypes,
  AnalyticsEventWithControlName,
  ACTIONS
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { URLS } from './urls';

export const screenPath = '/ReactMyAccountSupport/';
export const screenName = 'HelpAndSupport';

export const HelpAndSupportScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const HPStoreSupportClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_TILE_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'HPStoreSupportTileCard',
  controlDetail: URLS.HPStoreSupport,
  version: eventDetailVersion
};

export const DeviceSupportClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_TILE_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'DeviceSupportDashboardTileCard',
  controlDetail: URLS.DeviceSupport,
  version: eventDetailVersion
};

export const TroubleshootAndFixDeviceClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_TILE_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'TroubleshootAndFixDeviceTileCard',
  controlDetail: URLS.TroubleshootAndFixDevice,
  version: eventDetailVersion
};

export const SubscriptionsAndServicesClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_TILE_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'SubscriptionAndServicesSupportTileCard',
  controlDetail: URLS.SubscriptionAndServicesSupport,
  version: eventDetailVersion
};
