import React from 'react';
import { Platform, Stack, createGraphQLClient } from '@clientos/graphql-client';
import '../styles/global.scss';
import Payments from '../pages/Payments';
import { createGraphQLProvider } from '@clientos/graphql-client/dist/react';
import * as T from './types';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();

  const client = createGraphQLClient({
    authTokenCallback: async () => {
      return await (props.authProvider as any).getAccessToken();
    },
    platform: Platform.WEB,
    stack: stack,
    isShellPortal: true
  });

  const GraphQLProvider = createGraphQLProvider(React);

  return (
    <DependencyManagerProvider>
      <GraphQLProvider
        client={client}
        // eslint-disable-next-line react/no-children-prop
        children={<Payments />}
      />
    </DependencyManagerProvider>
  );
};

export default App;
