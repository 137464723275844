import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};
`;

export const Title = styled.h4`
  font-size: ${tokens.typography.size7};
  line-height: ${tokens.typography.lineHeight7};
  color: ${tokens.color.gray12};
`;

export const Subtitle = styled.p`
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  color: ${tokens.color.gray12};
`;
