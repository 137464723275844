import tokens from '@veneer/tokens';

const breakpoints = {
  xsMin: 375, // 375px
  xsMax: tokens.layout.xsMax, // 575px
  smMin: tokens.layout.smMin, // 576px
  mdMin: tokens.layout.mdMin, // 768px
  mdMax: tokens.layout.mdMax, // 991px
  lgMax: tokens.layout.lgMax, // 1199px
  xlMin: tokens.layout.xlMin, // 1200px
  xxlMin: 1399, // 1399px
  xxlMax: 1400 // 1400px
};

export const MainTheme = {
  breakpoints,
  mediaQueries: {
    maxWidthMobile: `(max-width: ${breakpoints.xsMax})`,
    xsmall: `(max-width: ${breakpoints.xsMax})`,
    small: `(min-width: ${breakpoints.smMin}) and (max-width: ${breakpoints.mdMin})`,
    medium: `(min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.mdMax})`,
    large: `(min-width: ${tokens.layout.lgMin}) and (max-width: ${breakpoints.lgMax})`,
    xLarge: `(min-width: ${breakpoints.xlMin}) and (max-width: ${breakpoints.xxlMin})`,
    xxLarge: `(min-width: ${breakpoints.xxlMax})`
  }
};
