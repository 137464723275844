import {
  ACTIONS,
  activity,
  AnalyticsEventWithControlName,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { screenPathDeviceDetailing } from 'src/utils/constants';
import { ScreenName } from 'src/utils/enums';
import { getHPSmartManageDeviceUsersAndSharingUrl } from 'src/utils/urls';

export const ManageDeviceUsersAndSharing = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: screenPathDeviceDetailing,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ManageDeviceUsersAndSharingButton',
    controlDetail: getHPSmartManageDeviceUsersAndSharingUrl(),
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};
