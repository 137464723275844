import { useEffect, useMemo, useState } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

export const featureFlagsDefaultValues = {
  'security-hp-id-email': false,
  'security-hp-id-phone': false,
  'security-list-login-details': false,
  'original-feedback-experience': false
} as any;

const useFeatureFlags = (key: string) => {
  const { northboundAPIs } = useDependencyManagerContext();
  const getClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );
  const defaultValue = featureFlagsDefaultValues[key] || false;
  const [featureFlagValue, setFeatureFlagValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getFeatureFlagValue = async () => {
      const client = await getClient('myaccount');
      const featureFlagValue = await client?.getFeatureFlag({
        key,
        defaultValue
      });

      setFeatureFlagValue(featureFlagValue);
      setIsLoading(false);
    };

    getFeatureFlagValue();
  }, [key, getClient, defaultValue, setFeatureFlagValue]);

  return { featureFlagValue, isLoading };
};

export default useFeatureFlags;
