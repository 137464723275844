import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@veneer/core/dist/scripts/button';
import { ButtonContainer, Container } from './styles';
import { buttonNames } from '../buttons';
import { useWizardContext } from '../context';

const Footer = ({
  i18n,
  onApply,
  onBack,
  onCancel,
  onNext,
  setButtonsCallback,
  shadow,
  stepId,
}) => {
  const [buttons, setButtons] = useState({
    leftArea: [],
    rightArea: [],
  });

  const {
    gotoPreviousStep,
    setShowCancelModal,
  } = useWizardContext();

  const setNewButtons = useCallback(newButtons => {
    const getButton = button => {
      if (button.name === buttonNames.BACK_BUTTON) {
        const {
          onClick: onClickButtonProps,
          ...restButtonProps
        } = button.props;

        return (
          <Button
            key={buttonNames.BACK_BUTTON}
            appearance="secondary"
            text={i18n.back}
            onClick={() => {
              onClickButtonProps?.();
              onBack?.(stepId);
              gotoPreviousStep();
            }}
            {...restButtonProps}
          >
            {i18n.back}
          </Button>
        );
      }

      if (button.name === buttonNames.CANCEL_BUTTON) {
        const {
          onClick: onClickButtonProps,
          ...restButtonProps
        } = button.props;

        return (
          <Button
            key={buttonNames.CANCEL_BUTTON}
            appearance="secondary"
            text={i18n.cancel}
            onClick={() => {
              onClickButtonProps?.();
              onCancel?.(stepId);
              setShowCancelModal(true);
            }}
            {...restButtonProps}
          >
            {i18n.cancel}
          </Button>
        );
      }

      if (button.name === buttonNames.NEXT_BUTTON) {
        const {
          onClick: onClickButtonProps,
          ...restButtonPros
        } = button.props;

        return (
          <Button
            key={buttonNames.NEXT_BUTTON}
            text={`${i18n.next}`}
            onClick={() => {
              onClickButtonProps?.();
              onNext?.(stepId);
            }}
            {...restButtonPros}
          >
            {i18n.next}
          </Button>
        );
      }

      if (button.name === buttonNames.APPLY_BUTTON) {
        const {
          onClick: onClickButtonProps,
          ...restButtonPros
        } = button.props;

        return (
          <Button
            key={buttonNames.APPLY_BUTTON}
            text={i18n.apply}
            onClick={() => {
              onClickButtonProps?.();
              onApply?.(stepId);
            }}
            {...restButtonPros}
          >
            {i18n.apply}
          </Button>
        );
      }

      return null;
    };

    setButtons(newButtons.reduce((prev, button) => ({
      ...prev,
      leftArea: [
        ...prev.leftArea,
        ...(button.area === 'left' ? [
          getButton(button),
        ] : []),
      ],
      rightArea: [
        ...prev.rightArea,
        ...(button.area === 'right' ? [
          getButton(button),
        ] : []),
      ],
    }), { rightArea: [], leftArea: [] }));
  }, [
    gotoPreviousStep,
    i18n.apply,
    i18n.back,
    i18n.cancel,
    i18n.next,
    onApply,
    onBack,
    onCancel,
    onNext,
    setShowCancelModal,
    stepId,
  ]);

  useEffect(() => {
    setButtonsCallback(setNewButtons);
  }, [setButtonsCallback, setNewButtons]);

  return (
    <Container shadow={shadow}>
      <ButtonContainer>
        {buttons.leftArea}
      </ButtonContainer>
      <ButtonContainer>
        {buttons.rightArea}
      </ButtonContainer>
    </Container>
  );
};

Footer.defaultProps = {
  onApply: null,
  onBack: null,
  onCancel: null,
  onNext: null,
  shadow: false,
};

Footer.propTypes = {
  i18n: PropTypes.shape({
    next: PropTypes.string,
    back: PropTypes.string,
    apply: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
  onApply: PropTypes.func,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  onNext: PropTypes.func,
  setButtonsCallback: PropTypes.func.isRequired,
  shadow: PropTypes.bool,
  stepId: PropTypes.string.isRequired,
};

export default Footer;
