import { useEffect, useState } from 'react';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FeatureFlag, FeatureFlagLoadingState } from 'src/types/FeatureFlag';
import { FeatureFlagEnum } from 'src/utils/enums';

const useFeatureFlagsState = (): {
  flags: FeatureFlag;
  flagsLoadingState: FeatureFlagLoadingState;
} => {
  const {
    featureFlagValue: profileListPersonalDetails,
    isLoading: isProfileListPersonalDetailsLoading
  } = useFeatureFlags(FeatureFlagEnum.profileListPersonalDetails);

  const {
    featureFlagValue: profileHpIdName,
    isLoading: isProfileHpIdNameLoading
  } = useFeatureFlags(FeatureFlagEnum.profileHpIdName);

  const {
    featureFlagValue: profileHpIdPreferredLanguage,
    isLoading: isProfileHpIdPreferredLanguageLoading
  } = useFeatureFlags(FeatureFlagEnum.profileHpIdPreferredLanguage);

  const {
    featureFlagValue: profileHpIdCountryRegion,
    isLoading: isProfileHpIdCountryRegionLoading
  } = useFeatureFlags(FeatureFlagEnum.profileHpIdCountryRegion);

  const {
    featureFlagValue: profileHpIdEmail,
    isLoading: isProfileHpIdEmailLoading
  } = useFeatureFlags(FeatureFlagEnum.profileHpIdEmail);

  const {
    featureFlagValue: profileHpIdPhone,
    isLoading: isProfileHpIdPhoneLoading
  } = useFeatureFlags(FeatureFlagEnum.profileHpIdPhone);

  const {
    featureFlagValue: originalFeedbackExperience,
    isLoading: isOriginalFeedbackExperienceLoading
  } = useFeatureFlags(FeatureFlagEnum.originalFeedbackExperience);

  const {
    featureFlagValue: localeFiltering,
    isLoading: isLocaleFilteringLoading
  } = useFeatureFlags(FeatureFlagEnum.localeFiltering);

  // State to hold the feature flags
  const [flags, setFlags] = useState<FeatureFlag>({
    isProfileListPersonalDetailsEnabled: false,
    isHPIDNameEnabled: false,
    isHPIDPreferredLanguageEnabled: false,
    isHPIDCountryRegionEnabled: false,
    isHPIDEmailEnabled: false,
    isHPIDPhoneEnabled: false,
    isOriginalFeedbackExperienceEnabled: false,
    isLocaleFilteringEnabled: false
  });

  // State to hold the loading status of each flag
  const [flagsLoadingState, setFlagsLoadingState] =
    useState<FeatureFlagLoadingState>({
      isProfileListPersonalDetailsLoading: true,
      isHPIDNameLoading: true,
      isHPIDPreferredLanguageLoading: true,
      isHPIDCountryRegionLoading: true,
      isHPIDEmailLoading: true,
      isHPIDPhoneLoading: true,
      isOriginalFeedbackExperienceLoading: true,
      isLocaleFilteringLoading: true
    });

  // Effect to update the flags and loading state when feature flags change
  useEffect(() => {
    setFlags({
      isProfileListPersonalDetailsEnabled: profileListPersonalDetails,
      isHPIDNameEnabled: profileHpIdName,
      isHPIDPreferredLanguageEnabled: profileHpIdPreferredLanguage,
      isHPIDCountryRegionEnabled: profileHpIdCountryRegion,
      isHPIDEmailEnabled: profileHpIdEmail,
      isHPIDPhoneEnabled: profileHpIdPhone,
      isOriginalFeedbackExperienceEnabled: originalFeedbackExperience,
      isLocaleFilteringEnabled: localeFiltering
    });

    setFlagsLoadingState({
      isProfileListPersonalDetailsLoading: isProfileListPersonalDetailsLoading,
      isHPIDNameLoading: isProfileHpIdNameLoading,
      isHPIDPreferredLanguageLoading: isProfileHpIdPreferredLanguageLoading,
      isHPIDCountryRegionLoading: isProfileHpIdCountryRegionLoading,
      isHPIDEmailLoading: isProfileHpIdEmailLoading,
      isHPIDPhoneLoading: isProfileHpIdPhoneLoading,
      isOriginalFeedbackExperienceLoading: isOriginalFeedbackExperienceLoading,
      isLocaleFilteringLoading: isLocaleFilteringLoading
    });
  }, [
    profileListPersonalDetails,
    isProfileListPersonalDetailsLoading,
    profileHpIdName,
    isProfileHpIdNameLoading,
    profileHpIdPreferredLanguage,
    isProfileHpIdPreferredLanguageLoading,
    profileHpIdCountryRegion,
    isProfileHpIdCountryRegionLoading,
    profileHpIdEmail,
    isProfileHpIdEmailLoading,
    profileHpIdPhone,
    isProfileHpIdPhoneLoading,
    originalFeedbackExperience,
    isOriginalFeedbackExperienceLoading,
    localeFiltering,
    isLocaleFilteringLoading
  ]);

  return { flags, flagsLoadingState };
};

export default useFeatureFlagsState;
