import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@veneer/core/dist/scripts/button';
import { ButtonContainer, Container } from './styles';
import { buttonNames } from '../buttons';

const Footer = ({
  buttons,
  setButtonsCallback,
  setShowCancelModal,
  shadow,
  i18n,
}) => {
  const [buttonsByArea, setButtonsByArea] = useState({
    leftArea: [],
    rightArea: [],
  });

  const setNewButtons = useCallback(newButtons => {
    const getButton = button => {
      if (button.name === buttonNames.CANCEL_BUTTON) {
        return (
          <Button
            key={buttonNames.CANCEL_BUTTON}
            appearance="secondary"
            text={i18n.cancel}
            onClick={() => setShowCancelModal(true)}
            {...button.props}
          >
            {i18n.cancel}
          </Button>
        );
      }

      if (button.name === buttonNames.APPLY_BUTTON) {
        return (
          <Button
            key={buttonNames.APPLY_BUTTON}
            text={i18n.apply}
            {...button.props}
          >
            {i18n.apply}
          </Button>
        );
      }

      return null;
    };

    setButtonsByArea(newButtons.reduce((prev, button) => ({
      ...prev,
      leftArea: [
        ...prev.leftArea,
        ...(button.area === 'left' ? [
          getButton(button),
        ] : []),
      ],
      rightArea: [
        ...prev.rightArea,
        ...(button.area === 'right' ? [
          getButton(button),
        ] : []),
      ],
    }), { rightArea: [], leftArea: [] }));
  }, [i18n.apply, i18n.cancel, setShowCancelModal]);

  useEffect(() => {
    setButtonsCallback(setNewButtons);
  }, [setButtonsCallback, setNewButtons]);

  useEffect(() => {
    setNewButtons(buttons);
  }, [buttons, setNewButtons]);

  return (
    <Container shadow={shadow}>
      <ButtonContainer>
        {buttonsByArea.leftArea}
      </ButtonContainer>
      <ButtonContainer>
        {buttonsByArea.rightArea}
      </ButtonContainer>
    </Container>
  );
};

Footer.defaultProps = {
  buttons: [],
  shadow: false,
};

Footer.propTypes = {
  setButtonsCallback: PropTypes.func.isRequired,
  setShowCancelModal: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    area: PropTypes.string,
    props: PropTypes.objectOf(PropTypes.any),
  })),
  shadow: PropTypes.bool,
  i18n: PropTypes.shape({
    next: PropTypes.string,
    back: PropTypes.string,
    apply: PropTypes.string,
    cancel: PropTypes.string,
  }).isRequired,
};

export default Footer;
