import React, {
  cloneElement,
  useEffect,
  useState,
} from 'react';
import Tooltip from '@veneer/core/dist/scripts/tooltip';
import useDimensions from 'react-use-dimensions';
import PropTypes from 'prop-types';

const SmartTooltip = ({
  arrow,
  children,
  content,
  contentHideDelayOnHover,
  contentShowDelayOnHover,
  id,
  show,
  placement,
  portal,
  tabIndex,
  useJsPositioning,
  userSelect,
}) => {
  const [ref, { width, height }, node] = useDimensions();
  const [isTruncate, setIsTruncate] = useState(false);

  useEffect(() => {
    if (node) {
      setIsTruncate(node.scrollWidth > node.clientWidth || node.scrollHeight > node.clientHeight);
    }
  }, [node, width, height]);

  return (show || isTruncate) ? (
    <Tooltip
      arrow={arrow}
      content={content}
      contentHideDelayOnHover={contentHideDelayOnHover}
      contentShowDelayOnHover={contentShowDelayOnHover}
      id={id}
      placement={placement}
      portal={portal}
      tabIndex={tabIndex}
      useJsPositioning={useJsPositioning}
      userSelect={userSelect}
      ref={ref}
    >
      {children}
    </Tooltip>
  ) : (
    cloneElement(children, { ref })
  );
};

SmartTooltip.propTypes = {
  arrow: PropTypes.bool,
  children: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  contentHideDelayOnHover: PropTypes.number,
  contentShowDelayOnHover: PropTypes.number,
  id: PropTypes.string,
  show: PropTypes.bool,
  placement: PropTypes.string,
  portal: PropTypes.bool,
  tabIndex: PropTypes.number,
  useJsPositioning: PropTypes.bool,
  userSelect: PropTypes.string,
};

// Setting default prop values to undefined, to use
// the default Veneer values instead.
SmartTooltip.defaultProps = {
  arrow: undefined,
  id: undefined,
  show: false,
  placement: undefined,
  portal: undefined,
  tabIndex: undefined,
  useJsPositioning: undefined,
  userSelect: undefined,
  contentHideDelayOnHover: undefined,
  contentShowDelayOnHover: undefined,
};

export default SmartTooltip;
