import tokens from '@veneer/tokens';
import { MainTheme } from 'src/styles/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: ${tokens.layout.size5};
  padding: ${tokens.layout.size5} 40px;
  max-width: 1140px;
  margin: 0 auto;

  @media (${MainTheme.mediaQueries.medium}) {
    padding: ${tokens.layout.size5} ${tokens.layout.size8};
  }

  @media (${MainTheme.mediaQueries.small}) {
    padding: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    padding: ${tokens.layout.size4};
  }
`;

export const Title = styled.h4`
  font-size: ${tokens.typography.size7};

  @media (max-width: ${tokens.layout.xsMax}) {
    font-size: ${tokens.typography.size6};
  }
`;

export const Subtitle = styled.p`
  font-size: ${tokens.typography.size2};
  color: ${tokens.color.gray12};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > div {
    width: 100%;
    max-width: 1140px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};
`;

export const FindMoreText = styled.div`
  font-size: ${tokens.typography.size2};
  color: ${tokens.color.gray12};
`;
