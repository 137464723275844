import React from 'react'
import ToastProvider from '@veneer/core/dist/scripts/toast_container/toast_provider'
import { Stack } from '@jarvis/web-stratus-client'
import { ProfileForm } from '../src/components/Profile/index'
import RootProvider from '../src/contexts/Root/RootProvider'
import resources from '../src/assets/locale/index'
import ShellV1Type from '../src/types/shell/v1'
import ShellV2Type from '../src/types/shell/v2'

type ShellProps = {
  v1: ShellV1Type
  v2: ShellV2Type
}
declare global {
  interface Window {
    Shell: ShellProps
  }
}

type CustomStrings = {
  [key: string]: string
}

type PreferencesProps = {
  customStrings?: CustomStrings
  disableSelectCountry?: boolean
  formMaxWidth?: string
  hideLanguageSelector?: boolean
  displaySeparateLabel?: boolean
  themeDensity?: string
  displayAdditionalTabInfoText?: boolean
  showOrganizationForm?: boolean
  showToastRetryButton?: boolean
  useBottomSaveBar?: boolean
  useGlobalHeader?: boolean
  showContextualToolbarWithShadow?: boolean
}

type AccountProfileProps = {
  country: string
  customStrings?: CustomStrings
  enableLocalization: boolean
  formMaxWidth?: string
  hideLanguageSelector: boolean
  language: string
  properties?: PreferencesProps
  showOrganizationForm: boolean
  showToastRetryButton?: boolean
  stack: Stack
  useBottomSaveBar: boolean
  useGlobalHeader: boolean
  showContextualToolbarWithShadow?: boolean
}

const defaultProps = {
  enableLocalization: true
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props: AccountProfileProps) {
  const { v1, v2 } = window.Shell as ShellProps
  const shell = {
    ...v1,
    ...v2
  }
  const displaySeparateLabel = props?.properties?.displaySeparateLabel
  const displayAdditionalTabInfoText =
    props?.properties?.displayAdditionalTabInfoText
  const themeDensity = props?.properties?.themeDensity || 'standard'
  const name = '@jarvis/react-account-profile'
  const propsManifest = props.properties || {}

  return (
    <section id={name}>
      <RootProvider
        localization={v1.localization}
        resources={resources}
        shell={shell}
        {...defaultProps}
        {...props}
      >
        <ToastProvider>
          <div>
            <ProfileForm
              {...v1}
              {...v2}
              country={props.country}
              customStrings={props.customStrings || propsManifest.customStrings}
              disableSelectCountry={propsManifest.disableSelectCountry}
              formMaxWidth={props.formMaxWidth || propsManifest.formMaxWidth}
              hideLanguageSelector={
                props.hideLanguageSelector || propsManifest.hideLanguageSelector
              }
              displaySeparateLabel={displaySeparateLabel}
              displayAdditionalTabInfoText={displayAdditionalTabInfoText}
              themeDensity={themeDensity}
              language={props.language}
              showOrganizationForm={
                props.showOrganizationForm || propsManifest.showOrganizationForm
              }
              showToastRetryButton={
                props.showToastRetryButton || propsManifest.showToastRetryButton
              }
              stack={props.stack}
              useBottomSaveBar={
                props.useBottomSaveBar || propsManifest.useBottomSaveBar
              }
              useGlobalHeader={
                props.useGlobalHeader || propsManifest.useGlobalHeader
              }
              showContextualToolbarWithShadow={
                props.showContextualToolbarWithShadow ||
                propsManifest.showContextualToolbarWithShadow
              }
            />
          </div>
        </ToastProvider>
      </RootProvider>
    </section>
  )
}
