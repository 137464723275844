import { Card, IconGhostWaveWall } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const Payments = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: ${tokens.layout.size5};
  margin: 0 0 ${tokens.layout.size5} 0;

  @media (max-width: ${tokens.layout.smMax}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: ${tokens.layout.size6};
  }
`;

export const Error = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${tokens.layout.size5};
`;

export const IconGhost = styled(IconGhostWaveWall)`
  && {
    color: ${tokens.color.gray9};
  }
`;

export const NoDataText = styled.body`
  display: flex;
  justify-content: center;
  && {
    color: ${tokens.color.gray9};
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const Section = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${tokens.layout.size4};
  margin-bottom: 20px;
  max-width: 1140px;

  && {
    border-radius: ${tokens.layout.size4};
  }
`;
