import styled from 'styled-components';
import LoaderWidget from '../../LoaderWidget';
import { xs } from '../../../helpers/mixins/mediaQueries';

export const CustomChart = styled.div`
  > div {
    // !important is needed because Highcharts sets overflow hidden inline
    ${xs(`
      overflow-x: scroll !important;
    `)}
  }
`;

export const LoaderContainer = styled(LoaderWidget)`
  flex: 1 1 auto;
  height: 250px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  height: 250px;
  align-items: center;
`;
