import { Stack } from '@jarvis/web-stratus-client';

export const HP_SMART_PROD_URL = 'https://www.hpsmart.com/ucde';
export const HP_SMART_STAGE_URL =
  'https://consumer.stage.portalshell.int.hp.com';

export const getHpSmartUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  const devStacks = [Stack.dev, Stack.pie, Stack.stage];

  return devStacks.includes(stack) ? HP_SMART_STAGE_URL : HP_SMART_PROD_URL;
};

export const URLS = {
  UnifiedShippingBillingURL: `${getHpSmartUrl()}/account-details/shipping-billing`,
  HpStoreWallet: 'https://www.hp.com/us-en/shop/MySavedCards'
};
