import { Loading } from '@hpx-core-experiences/react-my-account-commons/dist';
import React from 'react';
import { Center } from 'src/App/styles';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import useFeatureFlagsState from 'src/hooks/useFeatureFlagsState';
import PersonalDetailsEditor from 'src/pages/PersonalDetailsEditor';
import Profile from 'src/pages/Profile';
import { FormTypes } from 'src/utils/enums';

const SUB_ROUTES = Object.values(FormTypes);

const Router = () => {
  const { flags, flagsLoadingState } = useFeatureFlagsState();
  const { northboundAPIs } = useDependencyManagerContext();

  const pathName = northboundAPIs.v1.navigation.location.pathname || '';
  const subRouteExists = SUB_ROUTES.includes(
    pathName.replace('/profile/', '').replace(/\//g, '') as FormTypes
  );

  // Redirect to profile page if the subroute does not exist
  if (pathName.includes('/profile/') && !subRouteExists) {
    northboundAPIs.v1.navigation.redirect('/profile');
    return (
      <Center>
        <Loading />
      </Center>
    );
  }

  if (subRouteExists) {
    return (
      <PersonalDetailsEditor
        flags={flags}
        flagsLoadingState={flagsLoadingState}
      />
    );
  }

  return (
    <Profile
      flags={flags}
      flagsLoadingState={flagsLoadingState}
    />
  );
};

export default Router;
