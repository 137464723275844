export enum EntityType {
  IOT_PC = 'IOT_PC',
  IOT_PRINTER = 'IOT_PRINTER',
  INSTANT_INK = 'INSTANT_INK',
  INSTANTPAPER = 'INSTANTPAPER',
  THIRD_PARTY_SOFTWARE = 'THIRD_PARTY_SOFTWARE',
  MOUSE = 'MOUSE',
  KEYBOARD = 'KEYBOARD',
  PEN = 'PEN',
  CAREPACK = 'CAREPACK',
  PAYMENT = 'PAYMENT'
}

export enum FulfillmentStatus {
  NEW = 'NEW',
  RECEIVED = 'RECEIVED',
  PROCESSING = 'PROCESSING',
  SHIPPED = 'SHIPPED',
  INVOICED = 'INVOICED',
  DELIVERED = 'DELIVERED',
  ONHOLD = 'ONHOLD',
  RELEASEHOLD = 'RELEASEHOLD',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
  UNDELIVERED = 'UNDELIVERED',
  LOSTINTRANSIT = 'LOSTINTRANSIT',
  CLOSED = 'CLOSED'
}

export enum SubscriptionsWarningState {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  EXPIRING = 'EXPIRING',
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE'
}

export enum SubscriptionsState {
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  OBSOLETE = 'OBSOLETE',
  SUBSCRIBED_NO_PRINTER = 'SUBSCRIBED_NO_PRINTER',
  SUBSCRIBED_NO_PENS = 'SUBSCRIBED_NO_PENS',
  SUSPENDED = 'SUSPENDED',
  SUBSCRIBED = 'SUBSCRIBED',
  INITIATED_UNSUBSCRIBE = 'INITIATED_UNSUBSCRIBE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING'
}

export enum TypeNameEntities {
  CAREPACKENTITY = 'CarePackEntity',
  INSTANTINKENTITY = 'InstantInkEntity',
  DEVICEENTITY = 'DeviceEntity',
  INSTANTPAPERENTITY = 'InstantPaperEntity'
}

export enum FeatureFlagsEnum {
  originalFeedbackExperience = 'original-feedback-experience'
}
