import React from 'react';
import {
  Card,
  Loading
} from '@hpx-core-experiences/react-my-account-commons/dist';

const Loader = () => {
  return (
    <Card
      rowsColorChangeEnabled={false}
      childrenStyle={{ align: 'center', marginTop: '16px' }}
    >
      <Loading data-testid="loading" />
    </Card>
  );
};

export default Loader;
