import {
  ACTIONS,
  activity,
  AnalyticsEventPropTypes,
  AnalyticsEventWithControlName,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { screenPathDeviceDetailing } from 'src/utils/constants';
import { ScreenName } from 'src/utils/enums';
import { URLS } from 'src/utils/urls';

export const DeviceDetailsError = (
  errorInfo: string
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: screenPathDeviceDetailing,
    screenName: 'DeviceDetailsError',
    version: eventDetailVersion
  };
};
export const ManageDeviceSubscription = (
  deviceDetails: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath: screenPathDeviceDetailing,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ManageDeviceSubscriptionLink',
    controlDetail: URLS.ManageInstantInk,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};
