import React from 'react';
import PropTypes from 'prop-types';
import StyledHyperlinkText from './styles';

const HyperlinkText = ({ onClick, text, url }) => (
  <StyledHyperlinkText
    data-testid="hyperlink-text"
    href={url}
    {...onClick && { onClick }}
  >
    {text}
  </StyledHyperlinkText>
);

HyperlinkText.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

HyperlinkText.defaultProps = {
  onClick: null,
};

export default HyperlinkText;
