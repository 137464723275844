import waitForOObject from 'src/utils/waitForOObject';
import waitForConsent from 'src/utils/waitForConsent';
import { ConsentStatus } from 'src/enums/consentStatus';
import loadFullStoryScript from 'src/utils/loadFullStoryScript';

declare global {
  interface Window {
    _O: {
      ifOptInGroups: (group: string) => boolean;
    };
  }
}

/**
 * Background Task: bgtInjectFullstorySnippet
 *
 * This function asynchronously injects the Fullstory snippet into the My Account Portal using ClientOS background tasks.
 *
 *  * Process Details:
 * 1. Wait for the OObject to be ready. If the OObject is not available after 5 seconds, log an error.
 * 2. Check if the user has accepted the analytics agreement. Opt-in group ('2:1')
 * 3. Check FullStory feature flag value.
 * 4. Load the FullStory script if the user is in the specified opt-in group and the feature flag is true.
 * 5. Log errors and throw exceptions on failure.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
export const bgtInjectFullstorySnippet = async (): Promise<void> => {
  const shell = window?.Shell?.v1;
  if (!shell) {
    console.error('bgtInjectFullstorySnippet: Shell is not defined');
    return;
  }

  const logger = shell.logger?.createLoggerInstance({
    preffixLog: 'Fullstory:'
  });

  waitForOObject(async () => {
    const consentStatus = await waitForConsent();
    if (consentStatus === ConsentStatus.Accepted) {
      logger?.log('user has accepted consents, load Fullstory script');
      await loadFullStoryScript(logger);
    } else if (consentStatus === ConsentStatus.Rejected) {
      logger?.log('user has rejected consents, script not loaded');
    } else {
      logger?.log('user has not made a consent decision, script not loaded');
    }
  });
};
