import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@veneer/core/dist/scripts/button'
import { ContextualToolbar as ContextualToolbarWithShadow } from '@jarvis/react-portal-addons'
import { getProps } from '../Profile/helpers/commonMethods'
import { Container } from './styles'
import './index.scss'
import tokens from '@veneer/tokens'

const ContextualToolbar = ({ bounce, fixed, gapSize, show, buttons }) => {
  const [triggerBounce, setTriggerBounce] = useState<boolean>(false)

  const { showContextualToolbarWithShadow } = getProps()
  const { apply: applyProps, cancel: cancelProps } = buttons

  useEffect(() => {
    if (bounce) {
      setTriggerBounce(true)
      const timer = setTimeout(() => setTriggerBounce(false), 1000)

      return () => clearTimeout(timer)
    }
  }, [bounce])

  const contextuaToolbar = useMemo(
    () => (
      <Container
        className={triggerBounce ? 'bounce' : ''}
        bounce={triggerBounce}
        fixed={fixed || bounce}
        gapSize={gapSize}
        show={show}
      >
        <Button {...cancelProps}>{cancelProps?.label}</Button>
        <Button {...applyProps}>{applyProps?.label}</Button>
      </Container>
    ),
    [applyProps, bounce, cancelProps, fixed, gapSize, show, triggerBounce]
  )

  const contextualToolbarWithShadow = useMemo(
    () => (
      <ContextualToolbarWithShadow
        bounce={triggerBounce}
        gapSize={gapSize}
        show={show}
        variant="confirm"
        data-testid="contextual-toolbar"
        buttons={buttons}
      />
    ),
    [buttons, gapSize, show, triggerBounce]
  )

  return (
    <>
      {showContextualToolbarWithShadow
        ? contextualToolbarWithShadow
        : contextuaToolbar}
    </>
  )
}

ContextualToolbar.defaultProps = {
  bounce: 0,
  fixed: false,
  gapSize: tokens.layout.size6,
  show: false
}

ContextualToolbar.propTypes = {
  bounce: PropTypes.number,
  children: PropTypes.node,
  fixed: PropTypes.bool,
  gapSize: PropTypes.string,
  show: PropTypes.bool,
  buttons: PropTypes.shape({
    apply: PropTypes.objectOf(PropTypes.any),
    cancel: PropTypes.objectOf(PropTypes.any)
  }).isRequired
}

export default ContextualToolbar
