import {
  ACTIONS,
  activity,
  AnalyticsEventPropTypes,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { screenName, screenPath } from 'src/utils/constants';

export const UserNameError = (errorInfo: string): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: screenPath,
    screenName: `${screenName}/UserNameError`,
    version: eventDetailVersion
  };
};

export const SubscriptionsError = (
  errorInfo: string
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: screenPath,
    screenName: `${screenName}/SubscriptionsError`,
    version: eventDetailVersion
  };
};
