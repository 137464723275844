import { UAParser } from 'ua-parser-js';
import { ValveFilterErrorType } from '@jarvis/jweb-core';
import { Stack } from '../../dataCollectionService/dataCollectionServiceTypes';
import { DataValveFilterError } from '../../helpers/dataValveError';
import { window } from './window';

export enum Url {
  /**
   * Dev environment base URL
   */
  devBaseURL = 'https://dev-us1.api.ws-hp.com',

  /**
   * Local environment base URL
   */
  localURL = 'http://localhost:8090',

  /**
   * PIE environment base URL
   */
  pieBaseURL = 'https://pie-us1.api.ws-hp.com',

  /**
   * Stage environment base URL
   */
  stageBaseURL = 'https://stage-us1.api.ws-hp.com',
  /**
   * Prod environment base URL
   */
  prodBaseURL = 'https://us1.api.ws-hp.com'
}

const getOsLangugeFromLocale = (locale: string) => {
  if (locale.startsWith('zh') || (locale === 'en-EU') || (locale === 'fr-CA')) {
    // fallback for zh
    if (locale === 'zh'){
      return 'zh-TW';
    }
    return locale;
  } else {
    return locale.split('-')[0];
  }
};

export const getWindowValues = () => {
  const { innerWidth: width, innerHeight: height, navigator: navigator, localStorage: localStorage, sessionStorage: sessionStorage, DOMParser: DOMParser } = window;
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const osCountryRegion = locale.split('-')[1] ? locale.split('-')[1]?.toUpperCase() : 'US';
  const osLanguage = getOsLangugeFromLocale(locale);
  const browserUserAgent = navigator.userAgent;
  const browserScreenResolution = regexValidation('^([0-9]{3,4}x[0-9]{3,4})$',`${height}x${width}`) ? `${height}x${width}`: 'unknown';

  return {
    osCountryRegion,
    osLanguage,
    browserUserAgent,
    browserScreenResolution,
    localStorage,
    sessionStorage,
    navigator,
    DOMParser
  };
};

export const stackUrl = (stack: Stack, customEndpoint?: string) => {
  switch (stack) {
  case Stack.local:
    return Url.localURL;
  case Stack.dev:
    return Url.devBaseURL;
  case Stack.pie:
    return Url.pieBaseURL;
  case Stack.staging:
    return Url.stageBaseURL;
  case Stack.production:
    return Url.prodBaseURL;
  case Stack.custom:
    return customEndpoint ?? Url.devBaseURL;
  default:
    return Url.devBaseURL;
  }
};

export const getPlatform = () => {
  const result = getBrowserInfoResult();
  const osName = result.os.name;
  let os = '';
  if (osName !== undefined) {
    os = osName.toLowerCase();
  }
  switch (true) {
  case os.includes('mac'):
    return 'MacOS';
  case os.includes('windows'):
    return 'Windows';
  case os.includes('android'):
    return 'Android';
  case os.includes('chrom'):
    return 'ChromeOS';
  case os.includes('ios'):
    return 'iOS';
  case os.includes('ipad'):
    return 'iPadOS';
  default:
    return 'Linux';
  }
};

export const getDeviceType = () => {
  const result = getBrowserInfoResult();
  const deviceType = window? 'browser': result.device.type;
  switch (deviceType) {
  case 'mobile':
    return 'phone';
  case 'tablet':
    return deviceType;
  case 'smarttv':
    return 'tv';
  case 'console':
    return 'game/console';
  case 'browser':{
    const platform = getPlatform();
    if (platform === 'MacOS' || platform === 'Windows' || platform === 'Linux') {
      return 'desktop';
    } else if (platform === 'iOS' || platform === 'Android') {
      return 'phone';
    } else if (platform === 'iPadOS') {
      return 'tablet';
    }
    return 'other_'+ deviceType;
  }
  default:
    return 'other_'+ deviceType;
  }
};

export const validateBrowserInformation = (browserInfo: string | undefined, len: number) => {
  if (browserInfo === 'none' || !browserInfo) return 'unknown';
  if (browserInfo.length > len && browserInfo.charAt(len-1) === '.') {
    browserInfo = browserInfo.substring(0, len-1);
  } else if (browserInfo.length > len) {
    browserInfo = browserInfo.substring(0, len);
  }
  return browserInfo;
};

export const isValidOsVersion = (browserInfo: string | undefined) => {
  if (browserInfo){
    return regexValidation('^(unknown|[0-9]+([.][0-9]+)?([.][0-9]+)?([.][0-9]+)?)$',browserInfo);
  } else {
    return false;
  }
};

export const regexValidation = (pattern: string, testValue: string) => {
  const regexMatch = new RegExp(pattern);
  return regexMatch.test(testValue);
};

export const getIntrinsicPlatform = () => {
  const platform = getPlatform();
  if (platform === 'iOS' || platform === 'Android' || platform === 'iPadOS') {
    return 'mobile';
  }
  return 'desktop';
};

export const getBrowserInfoResult = () => {
  const uaParser = new UAParser();
  return uaParser.getResult();
};

export const invalidateValveControllerMetaDataKey = 'invalidateValveControllerMetaData';
export const bindingsMetadataKey = 'BindingsMetadata';

export const getConfigurationError = () => new DataValveFilterError(ValveFilterErrorType.configurationError,'Minimum Configuration is required', 'configuration is missing');
