import { Loading } from '@hpx-core-experiences/react-my-account-commons/dist';
import React from 'react';
import { Center } from 'src/App/styles';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import useFeatureFlagsState from 'src/hooks/useFeatureFlagsState';
import SecurityDetails from 'src/pages/SecurityDetails';
import Security from 'src/pages/Security';
import { FormTypesEnum } from 'src/utils/enums';

const SUB_ROUTES = Object.values(FormTypesEnum);

const Router = () => {
  const { flags, flagsLoadingState } = useFeatureFlagsState();
  const { northboundAPIs } = useDependencyManagerContext();

  const pathName = northboundAPIs.v1.navigation.location.pathname || '';
  const subRouteExists = SUB_ROUTES.includes(
    pathName.split('/').pop() as FormTypesEnum
  );

  // Redirect to security page if the subroute does not exist
  if (pathName.includes('/security/') && !subRouteExists) {
    northboundAPIs.v1.navigation.redirect('/security');
    return (
      <Center>
        <Loading />
      </Center>
    );
  }

  if (subRouteExists) {
    return (
      <SecurityDetails
        flags={flags}
        flagsLoadingState={flagsLoadingState}
      />
    );
  }

  return (
    <Security
      flags={flags}
      flagsLoadingState={flagsLoadingState}
    />
  );
};

export default Router;
