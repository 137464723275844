import { LookingForSomethingElse } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useEffect } from 'react';
import HPStoreNewsletter from 'src/components/HPStoreNewsletter';
import ContentWrapper from './ContentWrapper';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { FeatureFlagProps } from 'src/types/FeatureFlag';
import {
  ProfileScreenDisplayed,
  publishEvent,
  screenName,
  screenPath
} from 'src/utils/analytics';
import { Content, Header, Title, Subtitle } from './styles';

const Profile = ({ flags, flagsLoadingState }: FeatureFlagProps) => {
  const { translate } = useDependencyManagerContext();

  useEffect(() => {
    publishEvent(ProfileScreenDisplayed);
  }, []);

  return (
    <>
      <Header data-testid="header">
        <Title>{`${translate('menu.profile', 'Profile')}`}</Title>
        <Subtitle>
          {`${translate(
            'profile.managePersonalData',
            'Manage your personal details and addresses'
          )}`}
        </Subtitle>
      </Header>
      <Content>
        <ContentWrapper
          flags={flags}
          flagsLoadingState={flagsLoadingState}
        />
        <HPStoreNewsletter />
      </Content>
      {flags.isOriginalFeedbackExperienceEnabled && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </>
  );
};

export default Profile;
