import { Button } from '@veneer/core';
import React, { useCallback, useMemo } from 'react';
import {
  SubscriptionType,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import Images from 'src/assets/images/index';
import getExpirationStatus from 'src/helpers/getExpirationStatus';
import getPaymentFlag from 'src/helpers/getPaymentFlag';
import {
  ManageHpAllInPaymentButtonClicked,
  ManageHpInstantInkPaymentButtonClicked
} from './analytics';
import getCreditCardMask from 'src/helpers/getCreditCardMask';
import { URLS } from 'src/utils/urls';
import {
  InnerCard,
  Expired,
  PayPalFlagImage,
  Card,
  PaymentFlagImage,
  SubscriptionLinkedText,
  EmailValue,
  TopWrapper,
  ContainerManager
} from './styles';

export enum PaymentMethodStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED'
}

export enum PaymentMethodType {
  CREDIT_CARD = 'CREDIT_CARD',
  PAYPAL = 'PAY_PAL'
}

export interface IPayment {
  status?: PaymentMethodStatus;
  type: PaymentMethodType;
  cardType?: string;
  lastFour?: string;
  expirationDate?: string;
  accountName?: string;
  email?: string;
}

export interface IBilling {
  nextBillingCycle: {
    chargeDate: string;
    commonBillingDate: string;
  };
  paymentMethod: IPayment;
}

export interface ISubscription {
  type: string;
  subscriptionId: string;
  billing: IBilling;
}

const Payment = ({
  type,
  subscriptionId,
  billing
}: ISubscription): JSX.Element => {
  const { translate } = useDependencyManagerContext();

  const cardPaymentFlag = getPaymentFlag(billing?.paymentMethod?.cardType);
  const paymentFlag = getPaymentFlag(billing?.paymentMethod?.type);

  const subscriptionLinkedText =
    type === SubscriptionType.HP_ALL_IN
      ? translate('payments.linkedHpAllIn', 'Linked to your HP All-In Plan')
      : translate(
          'payments.linkedInstantInk',
          'Linked to your HP Instant Ink Plan'
        );

  const handleButtonClick = useCallback(() => {
    const analytics =
      type === SubscriptionType.HP_ALL_IN
        ? ManageHpAllInPaymentButtonClicked
        : ManageHpInstantInkPaymentButtonClicked;

    publishEvent(analytics(subscriptionId, billing?.paymentMethod?.type));
    window?.open(URLS.UnifiedShippingBillingURL, '_blank');
  }, [type, subscriptionId, billing?.paymentMethod?.type]);

  const expireDate = useMemo(() => {
    const expirationDate = billing?.paymentMethod?.expirationDate;
    if (!expirationDate) return null;

    const isExpired = getExpirationStatus(expirationDate);
    const expirationText = isExpired
      ? translate('payments.expired', 'Expired:')
      : translate('payments.expires', 'Expires:');

    return (
      <Expired
        data-testid="expiration-date"
        isExpired={isExpired}
        data-component="OrdersPaymentMethodsCardExpirationValue"
        className="caption"
      >
        {`${expirationText} ${expirationDate}`}
      </Expired>
    );
  }, [billing?.paymentMethod?.expirationDate, translate]);

  const manage = useMemo(
    () => (
      <ContainerManager id="manage">
        <SubscriptionLinkedText className="caption-small">
          {subscriptionLinkedText}
        </SubscriptionLinkedText>
        <Button
          appearance="ghost"
          onClick={handleButtonClick}
        >
          <span className="caption">
            {`${translate('devices.manage', 'Manage')}`}
          </span>
        </Button>
      </ContainerManager>
    ),
    [handleButtonClick, subscriptionLinkedText, translate]
  );

  const CreditCard = useMemo(
    () => (
      <InnerCard>
        <TopWrapper>
          <PaymentFlagImage
            data-testid={`card-img-${cardPaymentFlag}`}
            src={Images[cardPaymentFlag as keyof typeof Images]}
          />
          <p
            data-component="OrdersPaymentMethodsCardNumberValue"
            className="caption"
          >
            {getCreditCardMask(billing?.paymentMethod?.lastFour)}
          </p>
          {expireDate}
        </TopWrapper>
        {manage}
      </InnerCard>
    ),
    [billing?.paymentMethod?.lastFour, cardPaymentFlag, expireDate, manage]
  );

  const PayPal = useMemo(
    () => (
      <InnerCard>
        <TopWrapper>
          <PayPalFlagImage
            data-testid={`card-img-${paymentFlag}`}
            src={Images[paymentFlag as keyof typeof Images]}
          />
          <EmailValue data-component="OrdersPaymentMethodsPaypalEmailValue">
            {billing?.paymentMethod?.email}
          </EmailValue>
        </TopWrapper>
        {manage}
      </InnerCard>
    ),
    [paymentFlag, billing?.paymentMethod?.email, manage]
  );

  const getPaymentMethod = useCallback(() => {
    const paymentMethod = billing?.paymentMethod?.type;

    switch (paymentMethod) {
      case PaymentMethodType.CREDIT_CARD:
        return CreditCard;
      case PaymentMethodType.PAYPAL:
        return PayPal;
      default:
        return null;
    }
  }, [CreditCard, PayPal, billing?.paymentMethod?.type]);

  const paymentMethodContent = getPaymentMethod();

  if (!paymentMethodContent) {
    return null;
  }

  return (
    <Card
      data-testid="payment-container-card"
      appearance="outlined"
      onClick={handleButtonClick}
      content={paymentMethodContent}
    />
  );
};

export default Payment;
