const GET_SUBSCRIPTIONS_ADDRESSES = `
  query GetSubscriptionsAddresses {
    account {
      subscriptions {
        resourceId
        friendlyId
        type
        state
        shippingAddress {
          resourceId
          firstName
          lastName
          address
          city
          state
          postalCode
          phoneNumber
        }
      }
    }
  }
`;

export default GET_SUBSCRIPTIONS_ADDRESSES;
