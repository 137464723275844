import {
  ACTIONS,
  activity,
  eventDetailVersion,
  AnalyticsEventWithControlName
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { screenPath, screenName } from 'src/utils/analytics';
import { PaymentMethodType } from '.';
import { URLS } from 'src/utils/urls';

export const ManageHpAllInPaymentButtonClicked = (
  subscriptionId: string,
  paymentType: PaymentMethodType
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManageHpAllInPaymentButton',
    controlDetail: URLS.UnifiedShippingBillingURL,
    actionAuxParams: `subscriptionId=${subscriptionId}&paymentType=${paymentType}`,
    version: eventDetailVersion
  };
};

export const ManageHpInstantInkPaymentButtonClicked = (
  subscriptionId: string,
  paymentType: PaymentMethodType
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ManageHpInstantInkPaymentButton',
    controlDetail: URLS.UnifiedShippingBillingURL,
    actionAuxParams: `subscriptionId=${subscriptionId}&paymentType=${paymentType}`,
    version: eventDetailVersion
  };
};
