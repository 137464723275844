import { init, FullStory } from '@fullstory/browser';
import {
  EnumAllowedLogType,
  LogFuncType
} from '@jarvis/shell-commons/dist/interface/v1/logger/types';
import addScript from 'src/utils/addScript';
import getFeatureFlagValue from 'src/utils/getFeatureFlagValue';
import getTenantId from 'src/utils/getTenantId';

const FULLSTORY_SCRIPT_SRC = 'edge.fullstory.com/s/fs.js';
const FULLSTORY_HOST = 'fullstory.com';
const FULLSTORY_ORG = 'o-21C08X-na1';
const FULLSTORY_NAMESPACE = 'FS';

const loadFullStoryScript = async (
  logger: Record<EnumAllowedLogType, LogFuncType>
) => {
  const ff = await getFeatureFlagValue(logger, 'fullstory');
  if (!ff) {
    logger?.log('feature flag is false, script not loaded');
    return;
  }

  init({
    orgId: FULLSTORY_ORG,
    host: FULLSTORY_HOST,
    namespace: FULLSTORY_NAMESPACE,
    script: FULLSTORY_SCRIPT_SRC
  });

  addScript(
    `https://${FULLSTORY_SCRIPT_SRC}`,
    logger,
    async () => {
      if (FullStory) {
        logger?.log('is tracking user interactions');
        // Associate users in Fullstory with unique personal org ID
        const tenantId = await getTenantId();
        if (tenantId) {
          try {
            FullStory('setIdentity', {
              uid: tenantId
            });
            logger?.log('user associated');
          } catch (error) {
            logger?.error('error associating user', error);
          }
        }
      } else {
        logger?.log('is not tracking user interactions');
      }
    },
    'fullstory-script',
    'text/javascript'
  );
};

export default loadFullStoryScript;
