import { Button } from '@veneer/core';
import React, { useEffect } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  AnalyticsEventPropTypes,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist';
import {
  ErrorContent,
  ErrorTextContainer,
  ErrorTopWrapper,
  StyledIconTools
} from './styles';

const ErrorBoundary = ({
  analyticsEvent
}: {
  analyticsEvent: AnalyticsEventPropTypes;
}) => {
  const { translate } = useDependencyManagerContext();

  useEffect(() => {
    publishEvent(analyticsEvent);
  }, [analyticsEvent]);

  return (
    <ErrorContent>
      <ErrorTopWrapper>
        <StyledIconTools />
        <ErrorTextContainer>
          <div className="subtitle-large">
            {`${translate('common.itsNotYou', 'It’s not you, it’s us...')}`}
          </div>
          <div className="body">
            {`${translate(
              'common.thereWasAnInterruption',
              'There was an interruption while loading this information.'
            )}`}
          </div>
        </ErrorTextContainer>
      </ErrorTopWrapper>
      <Button
        appearance="secondary"
        onClick={() => {
          window.location.reload();
        }}
        data-testid="try-again-button"
      >
        {`${translate('common.tryAgain', 'Please try again')}`}
      </Button>
    </ErrorContent>
  );
};

export default ErrorBoundary;
