import jsonpath from 'jsonpath';
import { ValveFilterErrorType } from '@jarvis/jweb-core';
import { logger } from '../helpers/logger';
import { DataValveFilterError } from '../helpers/dataValveError';
import { subtreeFiltering } from './subtreeFiltering';
import { applyFilter } from './applyFilter';
import { FilterObject } from './filterTypes';

export const sanitize = (node: any, gun: string, bindings: any, originalNode: string, filterInfoFlag: boolean) => {
  logger.log('Sanitize:applying filteration on::', node, 'with gun::', gun);
  let filteredObject = {};
  let filterError = '';
  let appliedFilters = [];
  let filterObject = {};

  try {
    const filter = `$.cdm.filters[?(@.resourceId==='${gun}')]`;
    appliedFilters = jsonpath.query(bindings, filter);
    if (appliedFilters.length > 0) {
      appliedFilters.forEach((filterObj: FilterObject) => {
        filterObject = filterObj;
        filteredObject = applyFilter(filterObj.filterType, filterObj.attributes, node);
        node = filteredObject;
      });
    } else if (filterInfoFlag) {
      filterError = 'notFound';
    } else {
      throw new DataValveFilterError(ValveFilterErrorType.filterError,'Failed to retrieve filters for the current tree','Filter Object is not available');
    }
  } catch (error: any) {
    if (filterInfoFlag) filterError = 'badFilter';
    else {
      throw new DataValveFilterError(ValveFilterErrorType.filterError,'Unexpected error in sanitizeCDM',error);
    }
  }

  subtreeFiltering(bindings, gun, filteredObject, originalNode);
  return { sanitizedNode: filteredObject, filter: filterObject, filterError };
};
