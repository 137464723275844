import { useEffect, useState } from 'react';
import { interfaces } from '@jarvis/shell-commons';

export enum FeatureFlagEnum {
  originalFeedbackExperience = 'original-feedback-experience'
}

const featureFlagsDefaultValues = {
  'original-feedback-experience': false
} as any;

const useFeatureFlags = (key: string) => {
  const getClient = interfaces?.v1?.featureFlags?.getClient;
  const defaultValue = featureFlagsDefaultValues[key] || false;
  const [featureFlagValue, setFeatureFlagValue] = useState(defaultValue);

  useEffect(() => {
    const getFeatureFlagValue = async () => {
      const client = await getClient('myaccount');
      const featureFlagValue = await client?.getFeatureFlag({
        key,
        defaultValue
      });

      setFeatureFlagValue(featureFlagValue);
    };

    getFeatureFlagValue();
  }, [key, getClient, defaultValue, setFeatureFlagValue]);

  return featureFlagValue;
};

export default useFeatureFlags;
