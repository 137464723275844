import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt';
import GenericModal from '../GenericModal';

const UnsavedChangesModal = ({
  i18n,
  onCancel,
  onClose,
  onLeave,
  onShow,
  options,
  show,
  width,
}) => {
  useEffect(() => {
    if (show) {
      onShow();
      options?.callbacks?.onShow?.();
    }
  }, [onShow, options?.callbacks, show]);

  return (
    <GenericModal
      data-testid="unsaved-changes-modal"
      footer={{
        buttons: {
          cancel: {
            dataTestId: 'cancel-btn',
            label: i18n.cancel,
            onClick: () => {
              onCancel();
              options?.callbacks?.onCancel?.();
            },
          },
          proceed: {
            dataTestId: 'leave-btn',
            label: i18n.leave,
            onClick: () => {
              onLeave();
              options?.callbacks?.onLeave?.();
            },
          },
        },
      }}
      header={{
        dataTestId: 'modal-header',
        leadingIcon: <IconWarningAlt filled color="colorOrange6" />,
        title: i18n.title,
      }}
      onClose={() => {
        onClose();
        options?.callbacks?.onClose?.();
      }}
      show={show}
      width={width}
    >
      <div data-testid="body-text">{i18n.body}</div>
    </GenericModal>
  );
};

UnsavedChangesModal.propTypes = {
  i18n: PropTypes.shape({
    body: PropTypes.string,
    cancel: PropTypes.string,
    leave: PropTypes.string,
    title: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onLeave: PropTypes.func,
  onShow: PropTypes.func,
  options: PropTypes.shape({
    callbacks: PropTypes.shape({
      onCancel: PropTypes.func,
      onClose: PropTypes.func,
      onLeave: PropTypes.func,
      onShow: PropTypes.func,
    }),
  }),
  show: PropTypes.bool,
  width: PropTypes.string,
};

UnsavedChangesModal.defaultProps = {
  i18n: {
    body: 'Are you sure you want to leave this page?'
      + ' Your changes have not been saved and will be discarded if you leave now.',
    cancel: 'Cancel',
    leave: 'Leave',
    title: 'Unsaved Changes',
  },
  onCancel: () => {},
  onClose: () => {},
  onShow: () => {},
  onLeave: () => {},
  options: null,
  show: false,
  width: null,
};

export default UnsavedChangesModal;
