import { CDMObject, DataCollectionEventAction, DataCollectionEventResult, EventFailureReason, FilterCDMTreesOptions, FilterCDMTreesResult, ValveFilterError } from '@jarvis/jweb-core';
import { TelemetryClient } from '../client/TelemetryClient/TelemetryClient';
import { APIKeyConfiguration, AuthProviderConfiguration } from '../dataCollectionService/dataCollectionServiceTypes';
import { dataCollectionService } from '../dataCollectionService/dataCollectionService';
import { getWindowValues } from '../client/utils/enum';
import { logger } from '../helpers/logger';
import { Notifications } from '../client/notification';
import { validateSchema } from '../helpers/schemaValidation';
import { publishResultEventData } from '../helpers/publishResultEventData';
import { Queue } from './Queue';
import { QueueItem } from './QueueItem';
import { preparePrebuildNotification, publishFilterError } from './queueHelpers';

export class QueueWorker {
  static running = false;
  static async startSendData() {
    if (QueueWorker.running) {
      return;
    }
    QueueWorker.running = true;

    let queueItem = await Queue.getProcessingItem();
    while (queueItem) {
      queueItem = await Queue.getProcessingItem();
      if (!queueItem) {
        logger.log('QueueWorker::startSendData:NO element in the Queue Stoping execution');
        QueueWorker.running = false;
        break;
      }
      let filterRequired = false;
      for (const eventObject of queueItem.notification.events) {
        if (eventObject?.filter === undefined && eventObject?.filterError === undefined) {
          filterRequired = true;
          break;
        }
      }
      const configuration = dataCollectionService.getConfiguration();
      const { trackingIdentifier, trackingIdentifiers } = queueItem;
      const trackingIds = trackingIdentifier ? [trackingIdentifier] : trackingIdentifiers || undefined;
      if (configuration) {
        logger.log(`QueueWorker::startSendData:queueSizeLimit ${Queue.queueSizeLimit}  publishRetries  ${Queue.publishRetries} queueItemTTLInHours ${Queue.queueItemTTLInHours} publishRetryDelay  ${Queue.publishRetryDelay}`);
        if (configuration?.preConsentEventAccumulation) {
          const webAppConsent = getWindowValues().sessionStorage.getItem('webAppConsent');
          (queueItem.notification as Notifications).originator.originatorDetail.webAppConsent = webAppConsent;
        }
        let responseTime: number | undefined;
        let beginTime: number | undefined;
        let endTime: number;
        try {
          let filteredData: FilterCDMTreesResult | undefined = {
            results: [{ tree: '', treeGun: '' }]
          };
          // create a TelemetryClient
          const client = new TelemetryClient(configuration.stack, queueItem.applicationContext, (configuration as AuthProviderConfiguration).authProvider, (configuration as APIKeyConfiguration).telemetryAPIkey);

          logger.log('QueueWorker::startSendData::online?', getWindowValues().navigator.onLine);
          Queue.paused = !getWindowValues().navigator.onLine;
          if (Queue.paused) {
            logger.log('QueueWorker::startSendData:stoping the execution as Queue is paused due to no network');
            await publishResultEventData(DataCollectionEventAction.finish, trackingIds, { result: DataCollectionEventResult.failure, message: 'Queue paused due to no network' });
            break;
          }

          // Schema validation before filtering the notification
          const originalValidationResult = validateSchema(queueItem.notification);
          logger.log('QueueWorker::startSendData::Schema Validation result before filtering:', originalValidationResult.valid, originalValidationResult.errors.join());

          if (filterRequired) {
            // filter the notification
            filteredData = await filterQueueItem(queueItem);
            if ((filteredData?.results[0] as ValveFilterError)?.errorType) {
              // delete the Element from the queue
              await publishFilterError(queueItem.metadata, filteredData, trackingIds);
              logger.error('QueueWorker::startSendData::Got ValveFilterError! deleting the Item');
              await Queue.removeById(queueItem.id as string);
              continue;
            }
            await publishResultEventData(DataCollectionEventAction.filterNotification, trackingIds, { valveControllerMetadata: queueItem.metadata, valveFilterResult: filteredData?.results });
          } else {
            // for prebuild Notification
            filteredData.results = [await preparePrebuildNotification(queueItem, trackingIds)];
          }
          const filteredNotification = JSON.parse((filteredData?.results as unknown as CDMObject[])[0].tree);

          // Schema validation after filtering the notification
          const postFilterValidationResult = validateSchema(filteredNotification);
          if (originalValidationResult.valid &&!postFilterValidationResult.valid) {
            await publishResultEventData(DataCollectionEventAction.publishNotification, trackingIds, { telemetryServiceResponse: { reason: postFilterValidationResult.errors.join(', ') } });
            logger.error('QueueWorker::startSendData::Schema Validation failed on post filter:', postFilterValidationResult.propertyPath, postFilterValidationResult.errors.join());
            await publishResultEventData(DataCollectionEventAction.finish, trackingIds, { result: DataCollectionEventResult.failure, message: `${EventFailureReason.localSchemaValidationFailure}:${postFilterValidationResult.errors.join()}` });
            await Queue.removeById(queueItem.id as string);
            continue;
          }
          logger.log('QueueWorker::startSendData:Trying to send the event');

          beginTime = Date.now();
          // send to the telemetry
          const response = await client.sendEvent(filteredNotification, trackingIds, queueItem.attemptCount);
          endTime = Date.now();
          responseTime = endTime - beginTime;
          if (response.status >= 200 && response.status <= 299) {
            await publishResultEventData(DataCollectionEventAction.publishNotification, trackingIds, undefined, response, responseTime);
            await publishResultEventData(DataCollectionEventAction.finish, trackingIds, { result: (response.status === 206 ? DataCollectionEventResult.partialSuccess : DataCollectionEventResult.success), message: response.data });
            await Queue.removeById(queueItem.id as string);
          }
        } catch (err: any) {
          logger.error('QueueWorker::startSendData::error Message:', err.message);
          if (isClientError(err) || isSuccess(err)) {
            logger.log(`QueueWorker::startSendData::status:got status ${err.response.status} deleting the notification`);
            endTime = Date.now();
            if (endTime && beginTime) responseTime = endTime - beginTime;
            await publishAndRemove(queueItem, trackingIds, err.response, responseTime, DataCollectionEventResult.failure, err.response.statusText);
            continue;
          } else if (isServerOutOfService(err)) {
            logger.log('QueueWorker::startSendData::error:Server is out of service or some unknown error occured!');
            if (isExpired(queueItem.creationDate)) {
              await publishAndRemove(queueItem, trackingIds, undefined, undefined, DataCollectionEventResult.failure, EventFailureReason.eventExpired);
              continue;
            } else if (queueItem.attemptCount >= Queue.publishRetries) {
              await handleMaxRetry(queueItem, trackingIds, err);
              break;
            } else {
              await handleRetry(err, queueItem);
              continue;
            }
          }
        }
      } else {
        await publishResultEventData(
          DataCollectionEventAction.finish,
          trackingIds,
          {
            result: DataCollectionEventResult.failure,
            message: 'Configuration not set'
          }
        );
        logger.error('Queue worker::startSendData:Stopping as configuration is not set');
        break;
      }
    }
    QueueWorker.running = false;
  }
}

const wait = (time: number) => new Promise(res => {
  setTimeout(() => {
    res('resolved');
  }, time);
});

const isExpired = (creationDateInISOString: string) => {
  const dateNow = new Date(Date.now());
  const creationDate = new Date(creationDateInISOString);
  const noOfHrs: number = (+dateNow - +creationDate) / 1000 / 3600;// es6
  return noOfHrs > Queue.queueItemTTLInHours;
};

export const filterNotification = async (queueItem: QueueItem) => {
  const cdmObject: CDMObject = {
    tree: JSON.stringify(queueItem.notification),
    treeGun: 'com.hp.cdm.service.eventing.version.1.resource.notification'
  };
  const filterCDMTreesOptions: FilterCDMTreesOptions = {
    cdmObjects: [cdmObject],
    filterMetadata: queueItem.metadata
  };
  const filteredNotification = await dataCollectionService.filterCDMTrees(filterCDMTreesOptions);
  return filteredNotification;
};

const filterQueueItem = async (queueItem: QueueItem) => {
  for (const eventObject of queueItem.notification.events) {
    eventObject?.filter !== undefined && delete eventObject['filter'];
    eventObject?.filterError !== undefined && delete eventObject['filterError'];
  }
  return filterNotification(queueItem);
};

const isClientError = (err: any) => (err.response?.status >= 400 && err.response?.status <= 499 && err.response?.status !== 429);

const isSuccess = (err: any) => (err.response?.status >= 200 && err.response?.status <= 299);

const isServerOutOfService = (err: any) => !isClientError(err) && !isSuccess(err);

const publishAndRemove = async (queueItem: QueueItem, trackingIds: string[] | undefined, response: any, responseTime: number | undefined, result: any, message: string) => {
  await publishResultEventData(
    DataCollectionEventAction.publishNotification,
    trackingIds,
    undefined,
    response,
    responseTime);
  await Queue.removeById(queueItem.id as unknown as string);
  await publishResultEventData(
    DataCollectionEventAction.finish,
    trackingIds,
    {
      result,
      message
    });
};

const handleMaxRetry = async (queueItem: any, trackingIds: string[] | undefined, err: any) => {
  const updatedQueueItemValue = { ...queueItem, error: err.message, attemptCount: 0, state: 'failed' };
  await Queue.update(updatedQueueItemValue, queueItem.id as number);
  await publishResultEventData(
    DataCollectionEventAction.finish,
    trackingIds,
    {
      result: DataCollectionEventResult.failure,
      message: EventFailureReason.queueRetryExceeded
    }
  );
  logger.error('QueueWorker::startSendData:Max retry attempt reached, Server is out of Service! stopping the execution');
};

const handleRetry = async (err: any, queueItem: any) => {
  const retryDelay = err.response?.headers ? err.response?.headers['Retry-After']: null;
  if (retryDelay !== undefined && retryDelay !== null) {
    // use the delay from the retry-after header (if provided)
    logger.log('QueueWorker::startSendData:use the delay from the retry-after header',retryDelay);
    await wait(retryDelay);
  } else {
    // default to an exponentially wait
    logger.log('QueueWorker::startSendData:default to an exponentially wait');
    await wait(Queue.publishRetryDelay * 1000 * Math.pow(2,queueItem.attemptCount + 1));
  }
  const updatedQueueItem = { ...queueItem, error: err.message, attemptCount: queueItem.attemptCount + 1, state: 'failed' };
  await Queue.update(updatedQueueItem, queueItem.id as number);
};
