import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

export const usePathname = (justReturn?: boolean): string => {
  const { northboundAPIs } = useDependencyManagerContext();
  const { pathname } = northboundAPIs.v1.navigation.location || {};

  if (justReturn) {
    return pathname?.split('/')?.pop();
  }

  return pathname?.split('/')?.pop()?.toLowerCase();
};
