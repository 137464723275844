import React from 'react';
import { Container, Dot } from './styles';

const SupplyTricolorDot = () => (
  <Container>
    <Dot className=" dot cyan">
      <div />
    </Dot>
    <Dot className="dot magenta">
      <div />
    </Dot>
    <Dot className="dot yellow">
      <div />
    </Dot>
  </Container>
);

export default SupplyTricolorDot;
