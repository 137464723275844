import React from 'react';
import ErrorBoundary from 'src/App/ErrorBoundary';
import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import {
  ManagePersonalDetailsButtonClicked,
  PersonalDetailsError,
  publishEvent
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_USER_DETAILS from 'src/graphql/getUserDetails';
import Loader from '../Loader';
import { Label, Row, Value } from '../../pages/Profile/styles';
import { Content } from './styles';

const OldPersonalDetails = () => {
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const { useQuery } = northboundAPIs.v1.graphql.reactTools;
  const { loading, error, data } = useQuery(GET_USER_DETAILS);
  const { user } = data?.account || {};

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Card
        title={{
          content: translate(
            'profile.personalDetails',
            'Personal details'
          ) as string
        }}
        rowsColorChangeEnabled={false}
        data-testid="personal-details-error"
      >
        <ErrorBoundary analyticsEvent={PersonalDetailsError(error?.message)} />
      </Card>
    );
  }

  return (
    <Card
      title={{
        content: translate(
          'profile.personalDetails',
          'Personal details'
        ) as string,
        type: ROW_TYPE.EXTERNAL,
        analyticsEvent: ManagePersonalDetailsButtonClicked,
        link: {
          url: URLS.HpProfile,
          dataTestId: 'ManagePersonalDetailsButton'
        }
      }}
      rowsColorChangeEnabled={false}
      showDivider={false}
      data-testid="old-personal-details"
    >
      <Content>
        <Row data-testid="profile-field-div">
          <Label className="caption">
            {`${translate('profile.name', 'Name')}`}
          </Label>
          <Value
            data-component="UserDataField"
            className="caption"
          >
            {user?.fullName}
          </Value>
        </Row>
        <Row>
          <Label className="caption">
            {`${translate('profile.email', 'Email')}`}
          </Label>
          <Value
            data-component="UserDataField"
            className="caption"
            data-testid="no-email-val"
          >
            {user?.email || `${translate('profile.noEmail', 'No email')}`}
          </Value>
        </Row>
        <Row>
          <Label className="caption">
            {`${translate('profile.phone', 'Phone')}`}
          </Label>
          <Value
            data-component="UserDataField"
            className="caption"
            data-testid="no-phone-val"
          >
            {user?.phone || `${translate('profile.noPhone', 'No phone')}`}
          </Value>
        </Row>
        <Row>
          <Label className="caption">
            {`${translate(
              'profile.preferredLanguageCountry',
              'Preferred language/country'
            )}`}
          </Label>
          <Value className="caption">
            <a
              data-testid="manage-language-country"
              href={URLS.HpProfile}
              target="_blank"
              rel="noreferrer"
              onClick={() => publishEvent(ManagePersonalDetailsButtonClicked)}
            >
              {`${translate('profile.viewPreferences', 'View your preferences')}`}
            </a>
          </Value>
        </Row>
      </Content>
    </Card>
  );
};

export default OldPersonalDetails;
