export const handleSessionValidation = async (
  hpIdId: string,
  accessToken: any
) => {
  try {
    const isSessionValid = validateUserSession(accessToken, hpIdId);
    const redirected = _getRedirectedStatus();

    if (!isSessionValid) {
      _handleRedirection(redirected);
    }
  } catch (error) {
    console.error(
      'bgtInjectHpIdModule: handleSessionValidation: Error during session validation:',
      error
    );
  }
};

const _getRedirectedStatus = (): { value: boolean } => {
  return {
    value: JSON.parse(sessionStorage.getItem('bgtSessionRedirected') || 'false')
  };
};

const _handleRedirection = (redirected: { value: boolean }) => {
  const shellV1 = (window as any).Shell?.v1;
  if (!redirected.value) {
    redirected.value = true;
    sessionStorage.setItem('bgtSessionRedirected', 'true');
    console.warn(
      'bgtInjectHpIdModule: handleSessionValidation: Redirecting to login page. Session is invalid.'
    );
    shellV1.navigation.push('/login');
  } else {
    sessionStorage.removeItem('bgtSessionRedirected');
    console.warn(
      'bgtInjectHpIdModule: handleSessionValidation: Logging out user. Session is invalid.'
    );
    shellV1.sessionInterface.logout();
  }
};

export const validateUserSession = (
  accessToken: any,
  hpIdId: string
): boolean => {
  try {
    const { idp_type, idp_user_id } = accessToken;
    return idp_type === 'hpid' && idp_user_id === hpIdId;
  } catch (error) {
    console.error(
      'bgtInjectHpIdModule: validateUserSession: Error parsing access token:',
      error
    );
    return false;
  }
};
