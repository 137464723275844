export const launchDarklyClientKey = 'myaccount';

export const featureFlagsDefaultValues = {
  originalFeedbackExperience: false
} as any;

export const ScreenPath = '/ReactMyAccountOrders/';

export const ScreenName = 'Orders';

export const OriginalFeedbackExperience = 'original-feedback-experience';
