import { tokens } from '@veneer/tokens';
import { MainTheme } from 'src/styles/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: ${tokens.layout.size5};
  padding: ${tokens.layout.size5} 40px;
  max-width: 1140px;
  margin: 0 auto;

  @media (${MainTheme.mediaQueries.medium}) {
    gap: ${tokens.layout.size8};
    padding: ${tokens.layout.size5} ${tokens.layout.size8};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
    padding: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size4};
    padding: ${tokens.layout.size4};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};
`;

export const Title = styled.h4`
  font-size: ${tokens.typography.size7};
  line-height: ${tokens.typography.lineHeight7};
  color: ${tokens.color.gray12};

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    font-size: ${tokens.typography.size6};
    line-height: ${tokens.typography.lineHeight6};
  }
`;

export const Subtitle = styled.p`
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  color: ${tokens.color.gray12};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size4};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.medium}) {
    gap: ${tokens.layout.size8};
  }
`;
