import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  LookingForSomethingElse,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import PaymentsLogic from './PaymentsLogic';
import { screenName, screenPath } from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import useFeatureFlags, { FeatureFlagEnum } from 'src/hooks/useFeatureFlags';
import { HpStoreWalletButtonClicked } from './analytics';
import {
  Container,
  Content,
  FindMoreText,
  Header,
  Subtitle,
  Title
} from './styles';

const Payments = (): JSX.Element => {
  const { translate } = useDependencyManagerContext();
  const showOriginalFeedbackExperience = useFeatureFlags(
    FeatureFlagEnum.originalFeedbackExperience
  );

  const htmlString = translate(
    'payments.findMoreCardsAndPayment',
    'Find more cards and payment methods in your {{tagReplaceStart}}HP Store wallet{{tagReplaceEnd}}.'
  );
  const [textBeforeLink, linkText, textAfterLink] = htmlString.split(
    /{{\/?tagReplaceStart}}|{{\/?tagReplaceEnd}}/
  );

  return (
    <Container>
      <Header>
        <Title>{`${translate('orders.paymentMethods', 'Payment methods')}`}</Title>
        <Subtitle>
          {`${translate(
            'payments.viewAndManagePaymentMethods',
            'View and manage your payment methods'
          )}`}
        </Subtitle>
      </Header>
      <Content>
        <div>
          <PaymentsLogic />
          <FindMoreText>
            {textBeforeLink}
            <a
              data-testid="hp-store-wallets-button"
              href={URLS.HpStoreWallet}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => publishEvent(HpStoreWalletButtonClicked)}
            >
              {linkText}
            </a>
            {textAfterLink}
          </FindMoreText>
        </div>
        {showOriginalFeedbackExperience && (
          <LookingForSomethingElse
            analytics={{
              screenName: screenName,
              screenPath: screenPath
            }}
            translate={translate}
          />
        )}
      </Content>
    </Container>
  );
};

export default Payments;
