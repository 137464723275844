import {
  EnumAllowedLogType,
  LogFuncType
} from '@jarvis/shell-commons/dist/interface/v1/logger/types';

const getFeatureFlagValue = async (
  logger: Record<EnumAllowedLogType, LogFuncType>,
  key: string
): Promise<boolean> => {
  try {
    const client = await window.Shell.v1.featureFlags.getClient('myaccount');
    const ff = await client.getFeatureFlag({
      key: key,
      defaultValue: false
    });

    return ff as boolean;
  } catch (error) {
    logger.error('error getting feature flag', error);
    return false;
  }
};

export default getFeatureFlagValue;
