import styled from 'styled-components';
import Tokens from '@veneer/tokens';

const tricolor = {
  blobTricolorSpacing: 4,
  blobOuterTricolorDiameter: 12,
  blobTricolorDiameter: 10,
  cartridgeTricolorWidth: 26,
};

const getDotX = (source, col) => {
  const center = source.cartridgeTricolorWidth / 2 - source.blobTricolorDiameter / 2 - 1;
  if (col === 0) {
    return center - source.blobTricolorDiameter * (3 / 4);
  }

  if (col === 2) {
    return center + source.blobTricolorDiameter * (3 / 4);
  }
  return center;
};

const getDotY = (source, row) => {
  const center = source.cartridgeTricolorWidth / 2 - source.blobTricolorDiameter / 2;
  if (row === 0) {
    return center - source.blobTricolorDiameter * (3 / 5);
  }

  return center + source.blobTricolorDiameter * (3 / 5);
};

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: ${tricolor.cartridgeTricolorWidth}px;
  height: ${tricolor.cartridgeTricolorWidth}px;
`;

export const Dot = styled.div`
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1px;
  width: ${tricolor.blobOuterTricolorDiameter}px;
  height: ${tricolor.blobOuterTricolorDiameter}px;

  > div {
    width: ${tricolor.blobTricolorDiameter}px;
    height: ${tricolor.blobTricolorDiameter}px;
    color: transparent;
    border-radius: 8px;
  }

  &.cyan {
    top: ${getDotY(tricolor, 1)}px;
    left: ${getDotX(tricolor, 0)}px;

    > div {
      background-color: #03a4f0;
    }
  }

  &.magenta {
    top: ${getDotY(tricolor, 1)}px;
    left: ${getDotX(tricolor, 2)}px;

    > div {
      background-color: #f7448b;
    }
  }

  &.yellow {
    top: ${getDotY(tricolor, 0)}px;
    left: ${getDotX(tricolor, 1)}px;

    > div {
      background-color: #ffd906;
    }
  }

  &.black > div {
    background-color: ${Tokens.colorBlack};
    color: ${Tokens.colorWhite};
  }
`;
