import { Dispatch, SetStateAction } from 'react';
import {
  FIELDS,
  HpIdFieldConfigType
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { PersonalDetailsType } from 'src/components/PersonalDetails';

const getFieldsToFetch = (
  setPersonalDetails: Dispatch<SetStateAction<PersonalDetailsType>>
): HpIdFieldConfigType[] => {
  return [
    {
      field: FIELDS.FIRST_NAME,
      setter: (value: string) =>
        setPersonalDetails((prev) => ({ ...prev, firstName: value }))
    },
    {
      field: FIELDS.LAST_NAME,
      setter: (value: string) =>
        setPersonalDetails((prev) => ({ ...prev, lastName: value }))
    },
    {
      field: FIELDS.LANGUAGE,
      setter: (value: string) =>
        setPersonalDetails((prev) => ({
          ...prev,
          preferredLanguage: value
        }))
    },
    {
      field: FIELDS.COUNTRY,
      setter: (value: string) =>
        setPersonalDetails((prev) => ({
          ...prev,
          userCountry: value
        }))
    },
    {
      field: FIELDS.EMAIL,
      setter: (value: string) =>
        setPersonalDetails((prev) => ({ ...prev, email: value })),
      propertySelector: 'value'
    },
    {
      field: FIELDS.PHONE,
      setter: (value: string) =>
        setPersonalDetails((prev) => ({ ...prev, phone: value }))
    }
  ];
};

export default getFieldsToFetch;
