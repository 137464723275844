import React from 'react'
import Button from '@veneer/core/dist/scripts/button'
import useRootContext from '../../../contexts/Root/useRootContext'
import { MainModal, ModalTitle, ModalBox, WarningAlert } from './styles'

const ConfirmationModal = ({
  showModal,
  OnConfirm,
  OnClose,
  modalTitle,
  modalContent
}) => {
  const { localization } = useRootContext()
  const { t } = localization.useReactTranslatorHook()

  return (
    <MainModal
      show={showModal}
      id="confirmation-modal"
      title=""
      footer={
        <div className="vn-button-group--responsive modal-btn">
          <Button
            appearance="secondary"
            onClick={OnClose}
            data-testid="confirmation-modal-cancel-btn"
          >
            {t('application.myAccount.unsavedChanges.cancel')}
          </Button>
          <Button
            appearance="primary"
            onClick={OnConfirm}
            data-testid="leave-button"
          >
            {t('application.myAccount.unsavedChanges.leave')}
          </Button>
        </div>
      }
    >
      <ModalBox>
        <div>
          <WarningAlert filled color="darkOrange6" size={40} />{' '}
          <ModalTitle
            className="title-small"
            data-testid="confirmation-modal-title"
          >
            {modalTitle}
          </ModalTitle>{' '}
        </div>
      </ModalBox>
      <div
        className="subtitle-regular"
        data-testid="confirmation-modal-description"
      >
        {modalContent}
      </div>
    </MainModal>
  )
}

export default ConfirmationModal
