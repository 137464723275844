import { parse, parseISO, isValid } from 'date-fns';

const formatDateHelper = (
  inputDateString: string,
  options: Intl.DateTimeFormatOptions
): string => {
  if (!inputDateString) {
    return inputDateString; // Return the original string if it's null or empty
  }

  let date: Date;

  // Try parsing the date in different formats
  const formats = ['yyyy-MM-dd', 'MMM dd, yyyy'];
  for (const format of formats) {
    date = parse(inputDateString, format, new Date());
    if (isValid(date)) {
      break;
    }
  }

  // If the date is still invalid, try parsing as ISO format
  if (!isValid(date)) {
    date = parseISO(inputDateString);
  }

  // If the date is still invalid, return the original string
  if (!isValid(date)) {
    return inputDateString;
  }

  // Get the user's locale
  const userLocale = (window as any).Shell?.v1?.localization?.locale || 'en-US';

  const formattedDate = new Intl.DateTimeFormat(userLocale, options).format(
    date
  );

  return formattedDate;
};

export const formatDate = (inputDateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric'
  };
  return formatDateHelper(inputDateString, options);
};

export const formatFullDate = (inputDateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  };
  return formatDateHelper(inputDateString, options);
};

export const formatExpirationDate = (inputDateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  };
  return formatDateHelper(inputDateString, options);
};

export const formatDateDay = (inputDateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric'
  };
  return formatDateHelper(inputDateString, options);
};

export const formatOrdinalAbreviation = (day: string): string => {
  const dayNumber = Number(day);

  const ordinal =
    dayNumber > 3 && dayNumber < 21
      ? 'th'
      : ['th', 'st', 'nd', 'rd', 'th'][dayNumber % 10 > 3 ? 0 : dayNumber % 10];
  return ordinal;
};
