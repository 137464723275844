export const getTableI18n = props => {
  const i18n = {
    actionButton: 'Action Menu',
    cancelButton: 'Cancel',
    clearAllFilters: 'Clear Filters',
    columnOptions: 'Column Options',
    columnResizeTooltip: 'Width: %{columnWidth} px',
    deselectAllItems: 'Deselect all',
    downButton: 'move selected column down',
    itemSelected: '%s items selected',
    itemsSelected: '%s item selected',
    noItems: 'No Items Found',
    resetToDefault: 'Reset to Default',
    saveButton: 'Save',
    selectAllItems: 'Select all %{totalNumberOfItems} items',
    selectAllPageItems: 'select all items in the current page',
    sortedAscending: 'ascending sorted',
    sortedDescending: 'descending sorted',
    upButton: 'move selected column up',
    ...props,
  };

  return i18n;
};

export const getPaginationI18n = props => {
  const i18n = {
    currentPage: 'Page %{pageNumber} (Current Page)',
    goto: 'Go to page %{pageNumber}',
    next: 'Next',
    paginationNavigation: 'Pagination Navigation',
    pageSizeInformation: 'of %{total}',
    previous: 'Previous',
    selectPageSize: 'Select page size',
    ...props,
  };

  return i18n;
};
