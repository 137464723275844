import {
  activity,
  eventDetailVersion,
  AnalyticsEventPropTypes,
  AnalyticsEventWithControlName,
  ACTIONS
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { URLS } from './urls';

export const screenPath = '/ReactMyAccountPrivacy/';
export const screenName = 'DataAndPrivacy';

export const DataAndPrivacyScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const HpPrivacyStatementHyperLinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'HpPrivacyStatementLink',
    controlDetail: URLS.HpPrivacyStatement,
    version: eventDetailVersion
  };

export const YourPrivacyChoicesHyperLinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'YourPrivacyChoicesLink',
    controlDetail: URLS.YourPrivacyChoices,
    version: eventDetailVersion
  };

export const PersonalInfoOptOutButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'PersonalInfoOptOutButton',
  controlDetail: URLS.PersonalInfoOptOut,
  version: eventDetailVersion
};

export const DeleteAccountAndDataButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'DeleteAccountAndDataButton',
    controlDetail: URLS.DeleteAccountAndData,
    version: eventDetailVersion
  };
