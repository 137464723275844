import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';
import {
  Card,
  ROW_TYPE
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { IconShieldLock, IconXCircle } from '@veneer/core/';
import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  DeleteAccountAndDataButtonClicked,
  PersonalInfoOptOutButtonClicked
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';

const MoreOptions = () => {
  const { translate } = useDependencyManagerContext();

  const rows = [
    {
      index: 0,
      icon: <IconShieldLock />,
      type: ROW_TYPE.EXTERNAL,
      content: translate(
        'privacy.doNotSharePersonalInfo',
        'Do not sell or share my personal information'
      ),
      link: {
        url: URLS.PersonalInfoOptOut,
        dataTestId: 'PersonalInfoOptOutLink'
      },
      analyticsEvent: PersonalInfoOptOutButtonClicked
    },
    {
      index: 1,
      icon: <IconXCircle />,
      type: ROW_TYPE.EXTERNAL,
      content: translate(
        'privacy.deleteAccount',
        'Delete your account and data'
      ),
      link: {
        url: URLS.DeleteAccountAndData,
        dataTestId: 'DeleteAccountAndDataLink'
      },
      analyticsEvent: DeleteAccountAndDataButtonClicked
    }
  ] as CardRowProps[];

  return (
    <Card
      title={{
        content: translate('common.moreOptions', 'More options') as string
      }}
      rows={rows}
      data-testid="more-options"
    />
  );
};

export default MoreOptions;
