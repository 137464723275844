import addScript from './addScript';
import getHpIdMainUrl from './getHpIdMainUrl';
import getStack from './getStack';

let scriptLoaded = false;

/**
 * Loads data using the ReadOnlyDataStreamer class from the loaded script.
 * Ensures the script is loaded before attempting to use the ReadOnlyDataStreamer.
 *
 * @param {string} dataElement - The data element to be used.
 * @param {(payload: any) => void} hookFunction - The hook function to process the data.
 * @returns {Promise<string>} - A promise that resolves with the loaded data or rejects with an error.
 */
const readOnlyDataStreamer = (
  dataElement: string,
  hookFunction: (payload: any) => void
): Promise<string> => {
  const logger = window.Shell.v1.logger.createLoggerInstance({
    preffixLog: 'ReadOnlyDataStreamer:'
  });
  const stack = getStack();

  return new Promise((resolve, reject) => {
    if (scriptLoaded) {
      initializeReadOnlyDataStreamer();
    } else {
      addScript(getHpIdMainUrl(stack), logger, () => {
        scriptLoaded = true;
        initializeReadOnlyDataStreamer();
      });
    }

    function initializeReadOnlyDataStreamer() {
      const { __hpidClasses } = window as any;
      if (!__hpidClasses) {
        logger.error('Failed to load __hpidClasses from the script.');
        return reject(
          new Error('Failed to load __hpidClasses from the script.')
        );
      }

      const { ReadOnlyDataStreamer } = __hpidClasses;
      if (!ReadOnlyDataStreamer) {
        logger.error('is undefined.');
        return reject(new Error('is undefined.'));
      }

      return new ReadOnlyDataStreamer(dataElement, (payload: any) => {
        if (typeof payload === 'string') {
          hookFunction(payload);
          resolve(payload);
        } else if (typeof payload === 'object' && payload !== null) {
          hookFunction(JSON.stringify(payload));
          resolve(JSON.stringify(payload));
        } else {
          reject(new Error('Payload is not a valid string or JSON object.'));
        }
      });
    }
  });
};

export default readOnlyDataStreamer;
