import { Stack } from '@jarvis/web-stratus-client';

export const HP_SMART_PROD_URL = 'https://www.hpsmart.com/ucde';
export const HP_SMART_STAGE_URL =
  'https://consumer.stage.portalshell.int.hp.com';
const BASE_VIRTUAL_AGENT_URL = 'https://virtualagent.hpcloud.hp.com/?';
const BASE_HP_SHOP_URL = 'https://www.hp.com/us-en/shop/';

export const getHpSmartUrl = (): string => {
  const stack: Stack = (window as any).Shell?.v1.app.getAuthStack();
  return stack === Stack.prod ? HP_SMART_PROD_URL : HP_SMART_STAGE_URL;
};

export const getImMissingInkUrl = (
  language: string = 'en',
  country: string = 'us'
): string => {
  return `${BASE_VIRTUAL_AGENT_URL}botClient=Web&botSubClient=UMA&lc=${language}&cc=${country}`;
};

export const getMyBillWasNotWhatIExpected = (
  language: string = 'en',
  country: string = 'us'
): string => {
  return `${BASE_VIRTUAL_AGENT_URL}botClient=Web&botSubClient=UMA&query=ii_billingIssue&lc=${language}&cc=${country}`;
};

export const URLS = {
  UnifiedShippingBillingURL: `${getHpSmartUrl()}/account-details/shipping-billing`,
  ChangeYourPlanAllIn: `${getHpSmartUrl()}/account-details/subscriptions`,
  AllInShipment: `${getHpSmartUrl()}/hp-all-in-print-plan/shipment-tracking`,
  AccountHistoryAllIn: `${getHpSmartUrl()}/account-details/statements`,
  CancelHPInstantInk: `${getHpSmartUrl()}/print_plans/cancellation`,
  InkShipment: `${getHpSmartUrl()}/print_plans/ink_shipment`,
  AccountHistoryInk: `${getHpSmartUrl()}/print_plans/account_history`,
  ChangeYourPlanPrint: `${getHpSmartUrl()}/print_plans/my_account`,
  InstantInkOverview: `${getHpSmartUrl()}/print_plans`,
  HpAllInOverview: `${getHpSmartUrl()}/hp-all-in-print-plan/overview`,
  ProLiveSupport:
    'https://support.hp.com/us-en/contact/all-in-plan-serial-number',
  HPShop: `${BASE_HP_SHOP_URL}LogonForm`,
  HPStoreSubscriptions: `${BASE_HP_SHOP_URL}MySubscriptionsView?catalogId=10051&storeId=10151&langId=-1&krypto=x%2BA0P%2B5z7GkBQ4j7anxDpnP9CCs1g42ECjoCgMPS97yvLk5NOnpgg5Plv22EZMTeuNS0XNYrKWC5ih09y2wiPb3VqyjpBtui6vsMca550r137z7L29cRE7fSWSRS8xGl&currentPage=SigninPage&ddkey=https%3AETRLogonFlow`,
  GetInstantInkSupport: `https://support.hp.com/us-en/service/hp-instant-ink-series/5264756`
};
