const GET_DEVICES = `
  query Query($limit: Int, $deviceType: [DeviceType]) {
    devices(limit: $limit, deviceType: $deviceType) {
      items {
        deviceId
        identity {
          friendlyName
          serialNumber
          makeAndModel {
            name
            number
          }
        }
        images {
          url
        }
      }
      totalCount
    }
  }
`;

export default GET_DEVICES;
