import { ValveFilterErrorType } from '@jarvis/jweb-core';
import { DataValveFilterError } from '../../helpers/dataValveError';

enum consentType {
  strictlyNecessary = 1,
  telemetry = 2,
  personalization = 3
}

const getTelemetryConsent = (consent: string) => {
  const params = consent.split('&');
  const consentData = params.reduce((accum, current) => {
    const arr = current.split('=');
    accum[arr[0] as keyof {}] = arr[1] as never;
    return accum;
  }, { groups: '' });
  if (!consentData || !consentData.groups){
    throw new DataValveFilterError(ValveFilterErrorType.treeNotAllowed, `Groups not provided in webAppConsent "${consent}"`, 'Invalid webAppConsent');
  }
  const consents = consentData.groups.split(','); // returns ['1:1', '2:1', '3:1']
  const consentObj = consents.reduce((accum, current) => {
    const arr = current.split(':');
    accum[arr[0] as keyof {}] = arr[1] as never;
    return accum;
  }, {
    1: null,
    2: null,
    3: null
  });
  const isTelemetryAllowed = !!Number(consentObj[consentType.telemetry]);
  return isTelemetryAllowed;
};
export default getTelemetryConsent;
