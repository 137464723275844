import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import React from 'react';
import { Device } from 'src/types/IDevices';
import getDeviceImage from 'src/utils/getDeviceImage';
import {
  CardSection,
  ContentType,
  DeviceImage,
  DeviceInfo,
  DeviceName,
  Info
} from './styles';

type TDeviceCard = {
  item: Device;
  onClick: () => void;
};

const DeviceCard = ({ item, onClick }: TDeviceCard) => {
  const { translate } = useDependencyManagerContext();
  const { images, identity, __typename } = item;

  return (
    <CardSection
      data-testid="device-card"
      appearance="outlined"
      onClick={onClick}
      content={
        <>
          <DeviceImage>{getDeviceImage(images, __typename)}</DeviceImage>
          <ContentType>
            <DeviceName
              {...(identity?.friendlyName && {
                'data-component': 'DevicesFriendlyNameValue'
              })}
              className="caption"
              data-testid="device-name"
            >
              {identity?.friendlyName || identity?.makeAndModel?.name}
            </DeviceName>
            <Info>
              <DeviceInfo className="caption-small">
                {identity?.makeAndModel?.name}
              </DeviceInfo>
              <DeviceInfo
                data-component="DevicesSerialNumberValue"
                className="caption-small"
              >
                {`${translate('devices.serialNumber', 'Serial number')}`}:{' '}
                {identity?.serialNumber}
              </DeviceInfo>
            </Info>
          </ContentType>
        </>
      }
    />
  );
};
export default DeviceCard;
