import { ServiceInstanceState } from '../../../../../services/JWeb/JWebEnums';
import bindAllMethods from '../../../../../utils/bindAllMethods';
import {
  CloseServiceInstanceOptions,
  Service
} from '../../../../../services/webServiceRouting/types';
import { PutRequestOptionsType } from './clients/OnboardingDirector/types';
import * as T from './types';
import { internalLogger } from '../../../../../interface/v1/logger';
import EventManager from './EventManager';
import { getServices } from '../../../../../infra/commonInitializer';
import WebServiceRouting from '../../../../../services/webServiceRouting';
import TaskManager from './TaskManager';
import SessionManager from './SessionManager';
import StageBuilder from '../../../stageBuilder/StageBuilder';
import ListenerManager from './ListenerManager';
import SplunkRumManager from './SplunkRumManager';
import LegacyStoreManager from './LegacyStoreManager';
import { OnboardingInstanceType } from './types';

export const serviceLaunchOptionsCacheKey = 'shell-onboarding-agent-session';

export default class OnboardingAgent {
  OnboardingInstanceState = ServiceInstanceState;
  private _localizationInterface: T.OnboardingAgentConstructorDependencies['localizationInterface'];
  private _navigationInterface: T.OnboardingAgentConstructorDependencies['navigationInterface'];
  private _onboardingInstance: T.OnboardingInstanceType = {
    state: this.OnboardingInstanceState.closed
  };
  private _eventManager: EventManager;
  private _webServiceRouting: WebServiceRouting;
  private _sessionService: T.OnboardingAgentConstructorDependencies['sessionService'];
  private _taskManager: TaskManager;
  private _sessionManager: SessionManager;
  private _listenerManager: ListenerManager;
  private _splunkRumManager: SplunkRumManager;
  private _legacyStoreManager: LegacyStoreManager;
  private _startedWebServiceRoutingCloseStateListener = false;
  private _navigationService: T.OnboardingAgentConstructorDependencies['navigationService'];

  constructor(dependencies: T.OnboardingAgentConstructorDependencies) {
    const services = getServices();
    this._eventManager = new EventManager();
    this._webServiceRouting = services?.webServiceRouting;
    bindAllMethods(this);
    this._startWebServiceRoutingCloseStateListener();
    this._listenerManager = new ListenerManager(dependencies);
    this._taskManager = new TaskManager(dependencies);
    this._sessionManager = new SessionManager(
      dependencies,
      this.update,
      this._listenerManager
    );
    this._legacyStoreManager = new LegacyStoreManager(dependencies);
    this._splunkRumManager = new SplunkRumManager(dependencies);
    this._localizationInterface = dependencies.localizationInterface;
    this._navigationInterface = dependencies.navigationInterface;
    this._sessionService = dependencies.sessionService;
    this._navigationService = dependencies.navigationService;
  }

  /**
   * Handles processes on the current onboarding state.
   *
   * @param stateBuilder - State builder from the microfrontend router.
   */
  async handleOnboardingState(stateBuilder: StageBuilder): Promise<void> {
    // TODO: Is there a better way to provide the state builder to the session manager?
    this._sessionManager.stateBuilder = stateBuilder;
    switch (this._onboardingInstance?.state) {
      case this.OnboardingInstanceState.failedToLaunch:
        return await this._sessionManager.handleFailedState();
      case this.OnboardingInstanceState.running:
      case this.OnboardingInstanceState.launching:
        return await this._sessionManager.handleRunningOrLaunchingState();
      case this.OnboardingInstanceState.closed:
      default:
        return await this._sessionManager.launchOnboarding();
    }
  }

  /**
   * Called whenever the session is updated from the session manager.
     Passed as a callback to the manager
   */
  async update(): Promise<void> {
    await this._listenerManager.startConfirmBeforeLeaveListener();
    const { sessionId } =
      this._sessionManager.session?.context?.sessionContext || {};
    if (!sessionId) {
      this.setFailedToLaunch();
    } else {
      await this.handleSession();
    }
    this._sessionManager.clearExistingSessionPromise?.();
  }

  /**
   * Handles the failed state of the onboarding session
   * @private
   */
  private setFailedToLaunch(): void {
    this._setOnboardingInstanceState(
      this.OnboardingInstanceState.failedToLaunch
    );
    this._eventManager.sendOnboardingFailedToLaunchEvent();
  }

  /**
   * Handles an ongoing session or new session
   * @private
   */
  private async handleSession() {
    const session = this._sessionManager.session;
    const newServiceId = session?.context?.nextService?.serviceId;
    window.sessionStorage.setItem(
      serviceLaunchOptionsCacheKey,
      JSON.stringify(session)
    );
    this._legacyStoreManager.updateStore(session);

    this._setOnboardingInstanceState(this.OnboardingInstanceState.launching);

    if (newServiceId) {
      await this.handleNewService(newServiceId);
    }

    this._taskManager.launchNewTasks(session);

    if (!newServiceId) {
      await this.closeOnboardingInstance({
        result: 'success'
      });
    }
  }

  /**
   * Handles a serviceId that is different than the last serviceId
   * @param serviceId - serviceId registered in the manifest, as provided by OD.
   */
  private handleNewService = async (serviceId) => {
    const service = await this._sessionManager.getServiceFromServiceId(
      serviceId
    );
    const isLoginRequired = await this.isLoginRequired({
      assetReference: service?.assetReference,
      public: service?.public
    });

    //FIXME Later: Added this part to contingency fix of the 4165, but it needs to be removed later and fixed on the JSHELL-4221 and should be worked as the version 1.0.188
    if (isLoginRequired) {
      this.presentLogin(service);
    } else {
      await this._webServiceRouting.launchService({
        serviceId,
        serviceOptions: {
          appSessionId: this._sessionManager.session?.appSessionId,
          onboardingContext: this._sessionManager.session?.context,
          keepQueryParamsInSamePath: true
        }
      });
      this._setOnboardingInstanceState(this.OnboardingInstanceState.running);
    }
  };

  /**
   * Presents login
   * @param service - Used to redirect back to the service after login
   * @private
   */
  private presentLogin(service: Service): void {
    const loginType = this._onboardingInstance?.authentication?.loginType;
    // TODO: In the future, postLoginRedirect methods could be inside a subservice in navigation.
    const { loginService } = getServices();
    const loginPath = loginService.getLoginPath({
      postLoginRedirect: this._hasLocale() + service?.path,
      loginType,
      skipUserOnboarding: true
    });

    const defaultRedirect = this._navigationInterface.createHref({
      pathname: loginPath
    });

    internalLogger?.log?.(
      `service-${service?.id}-requires-login-redirect-to: ${defaultRedirect}`
    );

    this._listenerManager.stopConfirmBeforeLeaveListener();
    this._navigationService.redirect(defaultRedirect);
  }

  // TODO: We should use the utils/isLoginRequired.js instead
  private async isLoginRequired(options: {
    assetReference: string;
    public?: boolean;
  }) {
    if (!options?.public) {
      return !this._sessionService?.isLoggedIn?.();
    }
    return false;
  }

  async closeOnboardingInstance(options: {
    result: CloseServiceInstanceOptions['resultData']['result'];
  }): Promise<void> {
    if (this._onboardingInstance.state === this.OnboardingInstanceState.closed)
      return undefined;
    else {
      await this._sessionManager.closeOnboardingInstance(options);
    }
  }

  // TODO: Move out - util function
  private _hasLocale(): string {
    const country = this._localizationInterface.country;
    const language = this._localizationInterface.language;

    const locale = country && language ? `/${country}/${language}` : '';
    return locale;
  }

  private _setOnboardingInstanceState(state: ServiceInstanceState) {
    const previousState = this._onboardingInstance.state;
    if (previousState !== state) {
      this._onboardingInstance.state = state;
      const newState = this._onboardingInstance?.state;
      const { serviceId } =
        this._sessionManager?.session?.context?.nextService || {};
      const appSessionId = this._sessionManager?.session?.appSessionId;

      this._triggerWebOnboardingStageStartedEvent(
        previousState,
        newState,
        appSessionId,
        serviceId
      );

      this._splunkRumManager.startOnboardingSpan(
        this._sessionManager.session,
        newState
      );

      internalLogger?.log?.(
        `onboarding-state-changed-to-${newState}`,
        JSON.stringify(this._sessionManager.session)
      );
    }
  }

  private _triggerWebOnboardingStageStartedEvent(
    previousState: string,
    newState: OnboardingInstanceType['state'],
    appSessionId: string,
    serviceId: string
  ) {
    const onboardingWasClosed =
      !previousState || previousState === this.OnboardingInstanceState.closed;

    if (
      onboardingWasClosed &&
      newState !== this.OnboardingInstanceState.closed
    ) {
      this._eventManager.sendWebOnboardingStageStartedEvent(appSessionId);
    }

    if (newState === this.OnboardingInstanceState.running) {
      this._eventManager.sendWebOnboardingStageStartedEvent(
        appSessionId,
        serviceId
      );
    }
  }

  /**
   * Listeners
   */
  private async _startWebServiceRoutingCloseStateListener() {
    if (this._startedWebServiceRoutingCloseStateListener) return;
    this._startedWebServiceRoutingCloseStateListener = true;
    this.subscribeToWebServiceRouting(
      this._webServiceRouting.Events.ServiceInstanceClosed
    );
  }

  private subscribeToWebServiceRouting(eventName: string) {
    this._eventManager.subscribeToEvent(
      eventName,
      this._handleWebServiceRoutingEvent
    );
  }

  private async _handleWebServiceRoutingEvent(event) {
    if (
      this._onboardingInstance?.state ===
        this.OnboardingInstanceState.launching ||
      this._onboardingInstance?.state === this.OnboardingInstanceState.running
    ) {
      const eventData: CloseServiceInstanceOptions = event.eventData;
      const loginType = eventData?.authentication?.loginType;

      if (loginType) {
        this._onboardingInstance.authentication = {
          loginType
        };
      }

      const resultData = eventData?.resultData as PutRequestOptionsType['data'];

      const { sessionContext, nextService } =
        this._sessionManager?.session?.context || {};
      const xCorrelationId = sessionContext?.xCorrelationId;
      const serviceId = nextService?.serviceId;
      const appSessionId = this._sessionManager?.session?.appSessionId;

      this._eventManager.sendOnboardingStageFinishedEvent(
        appSessionId,
        serviceId,
        resultData,
        xCorrelationId
      );

      if (resultData?.result === 'cancelled') {
        await this.closeOnboardingInstance({
          result: 'cancelled'
        });
      } else {
        await this._sessionManager.getNextOnboardingDirectorStage(resultData);
      }
    }
  }
}
