import { ValveControllerMetadata } from '@jarvis/jweb-core';
import { JarvisWebHttpClient } from '@jarvis/web-http';
import { Stack } from '../../dataCollectionService/dataCollectionServiceTypes';
import { getApplicationContextFromValueStore } from '../../helpers/valueStoreHelpers';
import { getBrowserInfoResult, stackUrl } from '../utils/enum';

export class BindingsClient {
  apiKey: string;
  cacheControl: string;
  constructor(stack: Stack, apiKey: string, cacheControl: string, dataValveCustomEndpoint?: string) {
    this.jarvisWebHttpClient = new JarvisWebHttpClient({
      defaultBaseURL: `${stackUrl(stack, dataValveCustomEndpoint)}/clientdatavalvecontroller`
    });
    this.apiKey = apiKey;
    this.cacheControl = cacheControl;
  }

  API_VERSION = '1';
  GET_BINDINGS_ENPOINT = `/v${this.API_VERSION}/bindings`;

  jarvisWebHttpClient: JarvisWebHttpClient;

  private queryString = (metadata: ValveControllerMetadata, resourceIds?: string[], dataModelTypes?: string[]) => {
    const data = {
      ...metadata,
      resourceIds,
      dataModelTypes
    };
    const queryString = Object.keys(data).reduce((a, k) => {
      const value = data[k as never];
      if (value) {
        a.push(k + '=' + encodeURIComponent(value) as never);
      }
      return a;
    }, []).join('&');
    return queryString ? `?${queryString}` : '';
  };
  result = getBrowserInfoResult();
  async getBindings(data: ValveControllerMetadata, resourceIds?: string[], dataModelTypes?: string[]) {
    const applicationContext = await getApplicationContextFromValueStore();
    return this.jarvisWebHttpClient.get({
      headers: {
        'Content-Type': 'application/json',
        'x-hp-client-id': this.apiKey,
        'Cache-Control': this.cacheControl,
        'X-HP-User-Agent': `${applicationContext?.webAppName}-${applicationContext?.webAppVersion};${this.result.os.name};${this.result.browser.version}`,
      },
      url: `${this.GET_BINDINGS_ENPOINT}${this.queryString(data, resourceIds, dataModelTypes)}`
    });
  }
}
