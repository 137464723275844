import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useRootContext from '../../contexts/Root/useRootContext';
import StyledLink from './styles';

const Link = ({
  href, onClick, children, ...rest
}) => {
  const { shell } = useRootContext();
  const handleClick = useCallback(e => {
    const isAbsoluteUrl = href?.startsWith('https://') || href?.startsWith('http://');
    if (!isAbsoluteUrl && shell?.navigation?.push) {
      if (e.nativeEvent) {
        e.nativeEvent.stopImmediatePropagation();
      }
      e.stopPropagation();
      e.preventDefault();
      shell.navigation.push(href);
    }
    if (onClick) {
      onClick(e);
    }
  }, [href, onClick, shell?.navigation]);

  return (
    <StyledLink
      href={href}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Link.defaultProps = {
  onClick: null,
};

export default Link;
