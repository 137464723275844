import {
  ACTIONS,
  activity,
  AnalyticsEventWithControlName,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { ScreenName, ScreenPath } from 'src/utils/constants';

import { getPortalUrl, URLS } from 'src/utils/urls';

export const HpStoreOrdersButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: ScreenPath,
  screenName: ScreenName,
  controlName: 'HpStoreOrdersButton',
  controlDetail: URLS.MyOrders,
  version: eventDetailVersion
};

export const ManageMySubscriptionsButtonClicked = (
  country: string,
  language: string
): AnalyticsEventWithControlName => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath: ScreenPath,
    screenName: ScreenName,
    controlName: 'ManageMySubscriptionsButton',
    controlDetail: `${getPortalUrl()}/${country}/${language}/subscriptions`,
    version: eventDetailVersion
  };
};
