import React, { useEffect } from 'react';

import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';
import Help from 'src/pages/Help';
import { publishEvent } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { HelpAndSupportScreenDisplayed } from 'src/utils/analytics';
import * as S from './styles';

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  useEffect(() => {
    publishEvent(HelpAndSupportScreenDisplayed);
  }, []);

  return (
    <DependencyManagerProvider>
      <S.Container data-testid="support-page">
        <Help />
      </S.Container>
    </DependencyManagerProvider>
  );
};

export default App;
