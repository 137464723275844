import React from 'react';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import { OrdersPage } from '../pages/Orders';
import '../styles/global.scss';
import * as T from './types';

const App: React.FC<T.AppPropsType> = () => {
  return (
    <DependencyManagerProvider>
      <OrdersPage />
    </DependencyManagerProvider>
  );
};

export default App;
