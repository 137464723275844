import styled from 'styled-components'
import Select from '@veneer/core/dist/scripts/select'
import TextArea from '@veneer/core/dist/scripts/text_area'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Button from '@veneer/core/dist/scripts/button'
import tokens from '@veneer/tokens'

export const FormInput = styled(TextBox)`
  margin-right: 2px;
  margin-bottom: ${tokens.layout.size4};
  input {
    line-height: ${tokens.typography.lineHeight2};
  }
  label {
    line-height: ${tokens.typography.lineHeight2};
    margin-bottom: 2px;
    ${(props) => !props.disabled && `color: ${tokens.color.gray7};`}
  }
  ${(props) =>
    props.helperText &&
    `
    transition: 0.25s ease-out;
    margin-bottom: 0;
    > p {
      padding-top: 0;
    }
    &:focus-within {
      margin-bottom: 16px;
      > p {
        padding-top: 4px;
      }
    }
  `}
  ${(props) =>
    props.error &&
    `
    margin-bottom: 16px;
    > p {
      padding-top: 4px;
    }
  `}
`

export const FormSelect = styled(Select)`
  margin-bottom: ${tokens.layout.size4};
  height: ${tokens.typography.lineHeight9};
  div {
    line-height: ${tokens.typography.lineHeight2};
  }
  label {
    line-height: ${tokens.typography.lineHeight0};
    color: ${tokens.color.gray7};
  }
`
export const FormSelectWithSeparateLabel = styled(Select)`
  margin-bottom: ${tokens.layout.size4};
  height: 48px;
  label + div {
    height: 34px;
    line-height: ${tokens.typography.lineHeight2};
    div {
      color: ${({ inputColor }) => inputColor};
    }
  }
  label {
    line-height: ${tokens.typography.lineHeight2};
    margin-bottom: 2px;
    color: ${({ color }) => color};
  }
`

export const FormButton = styled(Button)`
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight2};
  height: ${tokens.typography.lineHeight9};
  span {
    margin: 0px;
  }
  @media (max-width: 1036px) {
    margin-bottom: ${tokens.layout.size4};
  }
`

export const Form = styled.form`
  margin-top: 20px;
`

export const FormFields = styled.div`
  margin: ${tokens.layout.size4} 0;
  max-width: ${(props) =>
    props.formMaxWidth ? `${props.formMaxWidth}` : '272px'};
`

export const Container = styled.div`
  flex-grow: 1;
  .padding-top-0 {
    padding-top: 0px !important;
  }
  h4 {
    color: ${tokens.color.gray10};
    font-size: ${tokens.typography.size7};
    line-height: ${tokens.typography.lineHeight6};
  }
  > div {
    color: #000;
  }
  #profile-container div > div {
    margin-bottom: 0px !important;
  }
  #profile-form {
    margin-top: 26px;
    .btnContainer {
      button:nth-of-type(1) {
        width: 145px;
      }
      button:nth-of-type(2) {
        width: 80px;
      }
    }
  }
`

export const FormLink = styled.div`
  margin-left: ${tokens.layout.size2};
  a {
    color: ${tokens.color.hpBlue7};
    font-size: ${tokens.typography.size2};
    line-height: ${tokens.typography.lineHeight2};
  }

  a:hover {
    color: ${tokens.color.hpBlue8};
  }
`

export const ProgressBar = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const GridSeparator = styled.div`
  max-width: 100%;

  & > * {
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const TitleContent = styled.div`
  max-width: 100%;

  & > * {
    max-width: 100%;

    &:first-child:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const MainContent = styled.div`
  max-width: 100%;
  div#profile,
  div#organization {
    padding-top: 14px !important;
    padding-bottom: ${tokens.layout.size3} !important;
  }
  > * {
    &:not(:last-child) {
      :not(:empty) {
        margin-bottom: 32px;
      }
    }
  }
`

export const TemplateContainer = styled.div`
  width: 100vw;
  min-height: fit-content;
  box-sizing: border-box;
  max-width: 100%;
  padding: 24px 24px 32px;

  & > * {
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const ModifiedTextArea = styled(TextArea)`
  margin: 16px 0;
  max-width: 352px;
`

export const Icon = styled.div``
