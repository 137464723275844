import styled, { css, keyframes } from 'styled-components'
import tokens from '@veneer/tokens'

export const slideUp = keyframes`
  from {
    bottom: -72px;
  }
  to {
    bottom: 0;
  }
`

export const slideDown = keyframes`
  from {
    bottom: 0;
  }
  to {
    bottom: -72px;
  }
`

export const Container = styled.div`
  background: ${tokens.color.hpBlue0};
  bottom: ${(props) => (props.show ? '0' : '-72px')};
  box-sizing: border-box;
  column-gap: ${(props) => props.gapSize};
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: flex-end;
  left: 0;
  padding: ${tokens.layout.size3} ${tokens.layout.size6} ${tokens.layout.size3}
    0;
  position: fixed;
  width: 100%;
  z-index: 1;

  ${(props) =>
    !props.fixed
      ? css`
          animation: ${props.show ? slideUp : slideDown} 0.3s ease-out;
        `
      : ''}
`
