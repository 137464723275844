const getTenantId = (preffixLog = 'Fullstory:'): Promise<string | null> => {
  const shell = window?.Shell?.v1;

  if (!shell) {
    console.error('Shell is not defined');
    return Promise.resolve(null);
  }
  const logger = shell.logger?.createLoggerInstance({
    preffixLog
  });

  const fetchTenantId = (): string | null => {
    return shell.tenantHandlerInterface?.getTenantId() ?? null;
  };

  return new Promise((resolve) => {
    let tenantId: string | null = null;

    try {
      tenantId = fetchTenantId();
      if (tenantId) {
        resolve(tenantId);
      } else {
        throw new Error('tenantId is null');
      }
    } catch (error) {
      logger?.warn(
        'error retrieving tenantId. Waiting for tenant to be set.',
        error
      );
      shell.tenantHandlerInterface?.onTenantChange(() => {
        logger?.log('tenantId changed');
        try {
          tenantId = fetchTenantId();
          resolve(tenantId);
        } catch (error) {
          logger?.error('error fetching tenantId after change', error);
        }
      });
    }
  });
};

export default getTenantId;
