import { useQuery } from '@clientos/graphql-client/dist/react';
import React, { useEffect, useMemo } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import ErrorBoundary from 'src/App/ErrorBoundary';
import {
  Loading,
  ApiSubscriptionStates,
  getSubscriptionCommercialState,
  CommercialSubscriptionStates,
  SubscriptionType,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import GET_PAYMENT_METHODS from 'src/graphql/getPaymentMethods';
import Payment, { IBilling } from '../../../components/Payment';
import {
  PaymentMethodsScreenDisplayed,
  PaymentMethodsError
} from './analytics';
import { GhostContent, Card } from '../../../components/Payment/styles';
import {
  Section,
  Error,
  Icon,
  IconGhost,
  NoDataText,
  Payments
} from './styles';

type Subscription = {
  type: SubscriptionType;
  state: ApiSubscriptionStates;
  resourceId?: string;
  friendlyId?: string;
  billing: IBilling;
};

const filterActiveSubscriptions = (
  subscriptions: Subscription[],
  type: SubscriptionType
) =>
  subscriptions?.filter(
    (subscription) =>
      subscription.type === type &&
      subscription.billing?.paymentMethod &&
      getSubscriptionCommercialState(subscription.state, subscription.type) !==
        CommercialSubscriptionStates.INACTIVE
  ) || [];

const PaymentsLogic = () => {
  const { translate } = useDependencyManagerContext();
  const { data, error, loading } = useQuery(GET_PAYMENT_METHODS);

  const instantInkPaymentMethod = useMemo(
    () =>
      filterActiveSubscriptions(
        data?.account?.subscriptions,
        SubscriptionType.INSTANT_INK
      ),
    [data?.account?.subscriptions]
  );
  const hpAllInPaymentMethod = useMemo(
    () =>
      filterActiveSubscriptions(
        data?.account?.subscriptions,
        SubscriptionType.HP_ALL_IN
      ),
    [data?.account?.subscriptions]
  );

  const dataPayments = useMemo(
    () =>
      [...hpAllInPaymentMethod, ...instantInkPaymentMethod]?.filter((i) => i),
    [hpAllInPaymentMethod, instantInkPaymentMethod]
  );
  const hasPaymentMethods = !loading && !error && dataPayments.length > 0;

  useEffect(() => {
    if (hasPaymentMethods) {
      const message = `totalPaymentMethodCt=${dataPayments?.length}&totalInstanInkCt=${instantInkPaymentMethod?.length}&totalHpAllInCt=${hpAllInPaymentMethod?.length}`;
      publishEvent(PaymentMethodsScreenDisplayed(message));
    }
  }, [
    loading,
    error,
    dataPayments,
    instantInkPaymentMethod,
    hpAllInPaymentMethod,
    hasPaymentMethods
  ]);

  if (loading) {
    return (
      <Section
        data-testid="loading-payment-methods"
        appearance="outlined"
        content={<Loading />}
      />
    );
  }

  if (error) {
    return (
      <Error
        data-testid="error-payment-methods"
        appearance="outlined"
        content={
          <ErrorBoundary analyticsEvent={PaymentMethodsError(error?.message)} />
        }
      />
    );
  }

  if (!hasPaymentMethods) {
    return (
      <Payments>
        <Card
          isEmpty
          data-testid="empty-payment-methods"
          appearance="outlined"
          content={
            <GhostContent>
              <Icon>
                <IconGhost size={40} />
              </Icon>
              <NoDataText data-testid="no-payment-methods-added-yet">
                {`${translate(
                  'payments.noPaymentMethodsAddedYet',
                  'No payment methods added yet!'
                )}`}
              </NoDataText>
            </GhostContent>
          }
        />
      </Payments>
    );
  }

  return (
    <Payments>
      {dataPayments?.map((payment, index) => (
        <Payment
          key={index}
          subscriptionId={
            payment?.type === SubscriptionType.HP_ALL_IN
              ? payment?.friendlyId
              : payment?.resourceId
          }
          type={payment?.type}
          billing={payment?.billing}
        />
      ))}
    </Payments>
  );
};

export default PaymentsLogic;
