import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';
import {
  ApiSubscriptionStates,
  Card,
  CommercialSubscriptionStates,
  getSubscriptionCommercialState,
  ROW_TYPE,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useCallback, useMemo } from 'react';
import ErrorBoundary from 'src/App/ErrorBoundary';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_SUBSCRIPTIONS_ADDRESSES from 'src/graphql/getSubscriptionsAddresses';
import {
  AddressesError,
  EditAddressHpAllInPlanHyperlinkClicked,
  EditAddressHpInstantInkHyperlinkClicked
} from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { Label, Value } from '../../pages/Profile/styles';
import AddressCard from './AddressCard';
import Loader from '../Loader';

interface Address {
  address: string | null;
  city: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  postalCode: string | null;
  resourceId: string | null;
  state: string | null;
}

interface Subscription {
  resourceId: string | null;
  friendlyId: string | null;
  shippingAddress: Address | null;
  type: SubscriptionType;
  state: ApiSubscriptionStates;
}

const Addresses = () => {
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const { useQuery } = northboundAPIs.v1.graphql.reactTools;
  const { loading, error, data } = useQuery(GET_SUBSCRIPTIONS_ADDRESSES);

  const subscriptions = data?.account?.subscriptions || [];

  const instantInk = subscriptions.filter(
    (subscription: Subscription) =>
      subscription?.type.toUpperCase() === SubscriptionType.INSTANT_INK &&
      getSubscriptionCommercialState(subscription.state, subscription.type) !==
        CommercialSubscriptionStates.INACTIVE
  );

  const hpAllIn = subscriptions.filter(
    (subscription: Subscription) =>
      subscription?.type.toUpperCase() === SubscriptionType.HP_ALL_IN &&
      getSubscriptionCommercialState(subscription.state, subscription.type) !==
        CommercialSubscriptionStates.INACTIVE
  );

  const AddressLine = useCallback((address: Address) => {
    if (!address) return null;

    return (
      <Value data-component="UserDataField">
        <div className="caption">
          {address.firstName} {address.lastName}
        </div>
        <div className="caption">{address.address}</div>
        <div className="caption">
          {address.city}, {address.state} {address.postalCode}
        </div>
        <div className="caption">{address.phoneNumber}</div>
      </Value>
    );
  }, []);

  const SubscriptionAddressLine = useCallback(
    (subscription: Subscription, index: number, isInstantInk: boolean) => {
      return [
        {
          value: (
            <Label>
              <div
                className="caption"
                data-testid={
                  isInstantInk ? `HP_II_${index}` : `HP_ALL_${index}`
                }
              >
                {isInstantInk
                  ? `${translate('common.instantInk', 'HP Instant Ink')}`
                  : `${translate('common.allIn', 'HP All-In Plan')}`}
              </div>
              <div
                className="caption"
                data-component="UserDataField"
              >
                {`${translate('common.id', 'ID:')}`}{' '}
                {subscription.friendlyId || subscription.resourceId}
              </div>
            </Label>
          )
        },
        {
          value: AddressLine(subscription.shippingAddress)
        }
      ] as CardRowProps['content'];
    },
    [AddressLine, translate]
  );

  const subscriptionsRows = useMemo(() => {
    const showHpAllIn = hpAllIn.length > 0;
    const showInstantInk = instantInk.length > 0;
    const rows = [] as CardRowProps[];
    let addressCount = 0;

    showInstantInk &&
      instantInk.map((subscription: Subscription, index: number) => {
        rows.push({
          index: addressCount,
          content: SubscriptionAddressLine(subscription, index, true),
          type: ROW_TYPE.EXTERNAL,
          link: {
            url: URLS.UnifiedShippingBillingURL,
            dataTestId: 'edit-address-instant-ink-' + addressCount
          },
          analyticsEvent: EditAddressHpInstantInkHyperlinkClicked
        });
        addressCount++;
      });

    showHpAllIn &&
      hpAllIn.map((subscription: Subscription, index: number) => {
        rows.push({
          index: addressCount,
          content: SubscriptionAddressLine(subscription, index, false),
          type: ROW_TYPE.EXTERNAL,
          link: {
            url: URLS.UnifiedShippingBillingURL,
            dataTestId: 'edit-address-hp-all-in-' + addressCount
          },
          analyticsEvent: EditAddressHpAllInPlanHyperlinkClicked
        });
        addressCount++;
      });

    return <AddressCard rows={rows} />;
  }, [hpAllIn, instantInk, SubscriptionAddressLine]);

  if (error) {
    return (
      <Card
        title={{
          content: `${translate('profile.addresses', 'Addresses')}`
        }}
        rowsColorChangeEnabled={false}
        data-testid="addresses-error"
      >
        <ErrorBoundary analyticsEvent={AddressesError(error?.message)} />
      </Card>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return subscriptionsRows;
};

export default React.memo(Addresses);
