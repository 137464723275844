import React, { useEffect } from 'react';
import {
  LookingForSomethingElse,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import ActiveSessions from 'src/components/ActiveSessions';
import AdvancedSecurityOptions from 'src/components/AdvancedSecurityOptions';
import ContentWrapper from 'src/pages/Security/ContentWrapper';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { FeatureFlagProps } from 'src/types/FeatureFlag';
import { Content, Header, Subtitle, Title } from './styles';
import {
  screenName,
  screenPath,
  SecurityScreenDisplayed
} from 'src/utils/analytics';

const Security = ({ flags, flagsLoadingState }: FeatureFlagProps) => {
  const { translate } = useDependencyManagerContext();

  useEffect(() => {
    publishEvent(SecurityScreenDisplayed);
  }, []);

  return (
    <>
      <Header data-testid="security-header">
        <Title>{`${translate('menu.security', 'Security')}`}</Title>
        <Subtitle>
          {`${translate(
            'security.description',
            'Manage your login details and what devices are used to log in to your HP account'
          )}`}
        </Subtitle>
      </Header>
      <Content>
        <ContentWrapper
          flags={flags}
          flagsLoadingState={flagsLoadingState}
        />
        <AdvancedSecurityOptions />
        <ActiveSessions />
      </Content>
      {flags.isOriginalFeedbackExperienceEnabled && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </>
  );
};

export default Security;
