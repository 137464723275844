import { useEffect, useMemo, useState } from 'react';

function useMediaQuery(mediaQuery) {
  const mediaQueryList = useMemo(() => window.matchMedia(`screen and ${mediaQuery}`), [mediaQuery]);
  const [doesMatch, setDoesMatch] = useState(mediaQueryList.matches);

  useEffect(() => {
    const listener = event => setDoesMatch(event.matches);

    mediaQueryList.addEventListener('change', listener);
    return () => mediaQueryList.removeEventListener('change', listener);
  }, [mediaQueryList]);

  return doesMatch;
}

export default useMediaQuery;
