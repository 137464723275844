import {
  AnalyticsEventPropTypes,
  LookingForSomethingElse,
  publishEvent,
  useReadOnlyDataStreamer,
  WelcomeNonUsUsers
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FrameCards from 'src/components/FrameCards';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_SUBSCRIPTIONS from 'src/graphql/getSubscriptions';
import useFeatureFlags, { FeatureFlagsEnum } from 'src/hooks/useFeatureFlags';
import { screenName, screenPath } from 'src/utils/constants';
import getSubscriptions from 'src/utils/getSubscriptions';
import getFieldsToFetch from '../../utils/getFieldsToFetch';
import { SubscriptionsError, UserNameError } from './analytics';
import { Header, Subtitle, Title } from './styles';

export interface PersonalDetailsType {
  firstName?: string;
  country?: string;
}

const Home = () => {
  const [hpIdUser, setHpIdUser] = useState<PersonalDetailsType>({
    firstName: '',
    country: ''
  });

  const { error: errorHpIdUser, loading: loadingHpIdUser } =
    useReadOnlyDataStreamer(setHpIdUser, getFieldsToFetch);

  const {
    featureFlagValue: welcomeNonUsUsers,
    isLoading: isWelcomeNonUsUsersLoading
  } = useFeatureFlags(FeatureFlagsEnum.welcomeNonUsUsers);

  const {
    featureFlagValue: originalFeedbackExperience,
    isLoading: isOriginalFeedbackExperienceLoading
  } = useFeatureFlags(FeatureFlagsEnum.originalFeedbackExperience);

  const { translate, northboundAPIs } = useDependencyManagerContext();

  const { useQuery } = northboundAPIs.v1.graphql.reactTools;

  const {
    error: subscriptionsError,
    loading: subscriptionsLoading,
    data: subscriptionsData
  } = useQuery(GET_SUBSCRIPTIONS);

  const { subscriptions } = subscriptionsData?.account || {};

  const language = navigator.language;

  const showWelcomeNonUsUsers = useMemo(() => {
    if (loadingHpIdUser || isWelcomeNonUsUsersLoading || !welcomeNonUsUsers) {
      return false;
    }

    const isNonUsUser = hpIdUser?.country && hpIdUser.country !== 'US';
    const isNonUsLanguage = language !== 'en-US';

    return isNonUsUser || (errorHpIdUser && isNonUsLanguage);
  }, [
    errorHpIdUser,
    hpIdUser.country,
    isWelcomeNonUsUsersLoading,
    language,
    loadingHpIdUser,
    welcomeNonUsUsers
  ]);

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isOriginalFeedbackExperienceLoading;

  const welcomeText = useCallback(() => {
    if (loadingHpIdUser || !hpIdUser.firstName) {
      return <Title>{`${translate('home.welcome', 'Welcome')}`}</Title>;
    }

    return (
      <Title data-testid="username">{`${translate('home.welcomeUser', {
        defaultValue: 'Welcome, {{user}}',
        replace: { user: hpIdUser.firstName }
      })}`}</Title>
    );
  }, [hpIdUser.firstName, loadingHpIdUser, translate]);

  const subs = useMemo(() => {
    return getSubscriptions(subscriptions);
  }, [subscriptions]);

  useEffect(() => {
    const analytics: AnalyticsEventPropTypes[] = [];
    if (subscriptionsError) {
      analytics.push(SubscriptionsError(subscriptionsError.message));
    }

    if (errorHpIdUser) {
      analytics.push(UserNameError(`Error fetching field firstName from HPID`));
    }
    analytics.length > 0 &&
      publishEvent(analytics as unknown as AnalyticsEventPropTypes);
  }, [errorHpIdUser, hpIdUser.firstName, subscriptionsError, translate]);

  return (
    <>
      {showWelcomeNonUsUsers && (
        <WelcomeNonUsUsers
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          stack={northboundAPIs.v1.app.getAuthStack()}
          translate={translate}
        />
      )}
      <Header data-testid="header">
        <div>
          {welcomeText()}
          <Subtitle>
            {`${translate(
              'home.mainTitle',
              'Quick links to your info, subscriptions and purchases with HP'
            )}`}
          </Subtitle>
        </div>
      </Header>
      <FrameCards
        subscriptions={subs}
        subscriptionsLoading={subscriptionsLoading}
      />
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </>
  );
};
export default Home;
