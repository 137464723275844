import { PublishCdmEventsOptions } from '@clientos/commons-northbound-api/dist/interface/v1/analytics/types';
import { SubscriptionsWarningState } from './enums';
import { getImMissingInkUrl, getMyBillWasNotWhatIExpected, URLS } from './urls';
import { SubscriptionType } from '@hpx-core-experiences/react-my-account-commons/dist/index';

const activity = 'UnifiedAcctMgmt-v01';
export const screenPath = '/ReactMyAccountSubscriptions/';
export const screenName = 'SubscriptionsAndServices';
const eventDetailVersion = '1.5.0';
export const screenPathSubscriptionDetails =
  '/ReactMyAccountSubscriptions/SubscriptionsAndServices/';
const screenPathSubscriptionDetailsError =
  '/ReactMyAccountSubscriptions/SubscriptionsAndServices/SubscriptionDetails/';

export const screenNameSubscriptionDetails = 'SubscriptionDetails';
const screenNameSubscriptionsError = 'SubscriptionsError';
const screenNameSubscriptionDetailsError = 'SubscriptionDetailsError';

function getSubTypeNameFormatted(subscriptionType: SubscriptionType): string {
  switch (subscriptionType) {
    case SubscriptionType.INSTANT_INK:
      return 'InstantInk';
    case SubscriptionType.HP_ALL_IN:
      return 'HpAllIn';
    default:
      return 'Unknown';
  }
}

export interface AnalyticsEventPropTypes {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlDetail?: string;
  version: string;
}

export interface AnalyticsEventWithControlName extends AnalyticsEventPropTypes {
  controlName: string;
}

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
) => {
  const publishCdmEvents = (window as any)?.Shell?.v1?.analytics
    ?.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

function parseType(type: keyof typeof SubscriptionType) {
  let controlName: string;
  if (type == SubscriptionType.INSTANT_INK) {
    controlName = 'HpInstantInk';
  } else if (type == SubscriptionType.HP_ALL_IN) {
    controlName = 'HpAllIn';
  } else {
    controlName = 'HpCarePack';
  }
  return controlName;
}

export function getActionAuxParamsSubscriptionsDetails(
  id: string,
  startDate: string | Date
): string {
  return `subscriptionStartDate=${
    startDate ?? new Date()
  }&subscriptionId=${id}`;
}

export const ModuleDisplayedErrorSubscription: AnalyticsEventPropTypes = {
  action: ACTIONS.MODULE_DISPLAYED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionsError,
  version: eventDetailVersion
};

export const ModuleDisplayedErrorSubscriptionDetails: AnalyticsEventPropTypes =
  {
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: screenPathSubscriptionDetailsError,
    screenName: screenNameSubscriptionDetailsError,
    version: eventDetailVersion
  };

export function getModuleDisplayedError(
  errorMessage: string,
  type?: keyof typeof SubscriptionType
): AnalyticsEventPropTypes {
  return type != null
    ? ({
        ...ModuleDisplayedErrorSubscription,
        screenMode: parseType(type),
        actionAuxParams: `error=${errorMessage.slice(0, 256)}`
      } as AnalyticsEventPropTypes)
    : ({
        ...ModuleDisplayedErrorSubscription,
        actionAuxParams: `error=${errorMessage.slice(0, 256)}`
      } as AnalyticsEventPropTypes);
}

export function getModuleDisplayedSubscriptionsDetailsError(
  errorMessage: string,
  type?: keyof typeof SubscriptionType
): AnalyticsEventPropTypes {
  return type != null
    ? ({
        ...ModuleDisplayedErrorSubscriptionDetails,
        screenMode: parseType(type),
        actionAuxParams: `error=${errorMessage.slice(0, 256)}`
      } as AnalyticsEventPropTypes)
    : ({
        ...ModuleDisplayedErrorSubscriptionDetails,
        actionAuxParams: `error=${errorMessage.slice(0, 256)}`
      } as AnalyticsEventPropTypes);
}

export const SubscriptionDetailsProps = {
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionDetails,
  version: eventDetailVersion
};

export function generateAnalyticsOfScreenDisplayedByType(
  type: keyof typeof SubscriptionType
): AnalyticsEventPropTypes {
  const screenMode = parseType(type);

  return {
    ...SubscriptionDetailsProps,
    action: ACTIONS.SCREEN_DISPLAYED,
    screenMode
  } as AnalyticsEventPropTypes;
}

export const ScreenDisplayed = (
  subscriptionsLength: number,
  hpInstantInkLength: number,
  hpAllInLength: number
) =>
  ({
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenPath,
    screenName,
    version: eventDetailVersion,
    actionAuxParams: `totalSubscriptionsCt=${subscriptionsLength}&totalInstantInkCt=${hpInstantInkLength}&totalHpAllInCt=${hpAllInLength}`
  }) as AnalyticsEventPropTypes;

export const ChangeYourInstantInkPlanButtonClicked: AnalyticsEventWithControlName =
  {
    ...SubscriptionDetailsProps,
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    controlName: 'ChangeYourInstantInkPlanButton',
    controlDetail: URLS.ChangeYourPlanPrint
  };

export const ChangeYourAllInPlanButtonClicked: AnalyticsEventWithControlName = {
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'ChangeYourAllInPlanButton',
  controlDetail: URLS.ChangeYourPlanAllIn
};

export const CancelHPInstantInkButtonClicked: AnalyticsEventWithControlName = {
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'CancelHPInstantInkButton',
  controlDetail: URLS.CancelHPInstantInk
};

export const CancelHPAllInButtonClicked: AnalyticsEventWithControlName = {
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'CancelYourHpAllInButton',
  controlDetail: URLS.ChangeYourPlanAllIn
};

export const HPStoreSubscriptionHyperLinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    version: eventDetailVersion,
    controlName: 'DontSeeSubscriptionsLink',
    controlDetail: URLS.HPShop
  };

export function generateAnalyticsOfSubscriptionCardClickedByType(
  type: keyof typeof SubscriptionType,
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName {
  const controlName = parseType(type);

  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    version: eventDetailVersion,
    controlName: `${controlName}SubscriptionCardClicked`,
    actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
  };
}

export const SubscriptionWarningModuleDisplayed = (
  subscriptionType: SubscriptionType,
  warningType: SubscriptionsWarningState
): AnalyticsEventPropTypes => ({
  action: ACTIONS.MODULE_DISPLAYED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: `${screenNameSubscriptionDetails}/SubscriptionStatusBanner`,
  screenMode: getSubTypeNameFormatted(subscriptionType),
  version: eventDetailVersion,
  actionAuxParams: `warningType=${warningType?.toLowerCase()}`
});

export const SubscriptionWarningButtonClicked = (
  subscriptionType: SubscriptionType,
  warningType: SubscriptionsWarningState
): AnalyticsEventWithControlName => ({
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath: screenPathSubscriptionDetails,
  screenName: screenNameSubscriptionDetails,
  screenMode: getSubTypeNameFormatted(subscriptionType),
  version: eventDetailVersion,
  controlName: 'SubscriptionStatusBannerButton',
  controlDetail:
    subscriptionType === SubscriptionType.HP_ALL_IN
      ? URLS.HpAllInOverview
      : URLS.InstantInkOverview,
  actionAuxParams: `warningType=${warningType?.toLowerCase()}`
});

export const InstantInkManagePaymentMethodHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  screenMode: 'HpInstantInk',
  controlName: 'ManageInstantInkPaymentMethodLink',
  controlDetail: URLS.UnifiedShippingBillingURL,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const HpAllInManagePaymentMethodHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  screenMode: 'HpAllIn',
  controlName: 'ManageAllInPaymentMethodLink',
  controlDetail: URLS.UnifiedShippingBillingURL,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const InstantInkAddPaymentMethodHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  screenMode: 'HpInstantInk',
  controlName: 'AddInstantInkPaymentMethodLink',
  controlDetail: URLS.UnifiedShippingBillingURL,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const HpAllInAddPaymentMethodHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  screenMode: 'HpAllIn',
  controlName: 'AddAllInPaymentMethodLink',
  controlDetail: URLS.UnifiedShippingBillingURL,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const MissingInkButtonClicked = (
  id: string,
  startDate: string | Date,
  language: string = 'en',
  country: string = 'us'
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'ImMissingInkButton',
  controlDetail: getImMissingInkUrl(language, country),
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const PaymentHistoryAllInHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'PaymentHistoryAllInLink',
  controlDetail: URLS.AccountHistoryAllIn,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const PaymentHistoryInstantInkHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'PaymentHistoryInstantInkLink',
  controlDetail: URLS.AccountHistoryInk,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const ShipmentHistoryAllInHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'ShipmentHistoryAllInLink',
  controlDetail: URLS.AllInShipment,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const ShipmentHistoryInstantInkHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'ShipmentHistoryInstantInkLink',
  controlDetail: URLS.InkShipment,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const OverviewInstantInkHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'InstantInkOverviewLink',
  controlDetail: URLS.InstantInkOverview,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const OverviewAllInHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'HpAllInOverviewLink',
  controlDetail: URLS.HpAllInOverview,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const HpAllInManageAddressHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'ChangeAddressAllInLink',
  controlDetail: URLS.UnifiedShippingBillingURL,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const InstantInkManageAddressHyperLinkClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: 'ChangeAddressInstantInkLink',
  controlDetail: URLS.UnifiedShippingBillingURL,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const ProLiveSupportButtonClicked =
  (): AnalyticsEventWithControlName => ({
    ...SubscriptionDetailsProps,
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    controlName: 'ProLiveSupportButton',
    controlDetail: URLS.ProLiveSupport
  });

export const CallSupportHyperLinkClicked = (
  phoneNumber: string
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlDetail: phoneNumber,
  controlName: 'CallSupportHpAllInLink'
});

export const MyBillWasNotWhatIExpectedButtonClicked = (
  id: string,
  startDate: string | Date,
  type: SubscriptionType,
  language: string = 'en',
  country: string = 'us'
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'MyBillWasNotWhatIExpectedButton',
  controlDetail: getMyBillWasNotWhatIExpected(language, country),
  screenMode: parseType(type),
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});

export const GetInstantInkSupportButtonClicked = (
  id: string,
  startDate: string | Date
): AnalyticsEventWithControlName => ({
  ...SubscriptionDetailsProps,
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  controlName: 'GetInstantInkSupportButton',
  controlDetail: URLS.GetInstantInkSupport,
  actionAuxParams: getActionAuxParamsSubscriptionsDetails(id, startDate)
});
