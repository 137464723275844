import {
  AnalyticsEventPropTypes,
  ACTIONS,
  activity,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { screenPath, screenName } from 'src/utils/analytics';

export const PaymentMethodsScreenDisplayed = (
  totalPaymentMethodCt: string
): AnalyticsEventPropTypes => {
  return {
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenPath,
    screenName,
    actionAuxParams: totalPaymentMethodCt,
    version: eventDetailVersion
  };
};

export const PaymentMethodsError = (
  errorInfo: string
): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath,
    screenName: `${screenName}Error`,
    version: eventDetailVersion
  };
};
