import React, { useMemo } from 'react';
import { FeatureFlagProps } from 'src/types/FeatureFlag';
import LoginDetails from 'src/components/LoginDetails';
import OldLoginDetails from 'src/components/OldLoginDetails';
import Loader from 'src/components/Loader';

const ContentWrapper: React.FC<FeatureFlagProps> = ({
  flags,
  flagsLoadingState
}) => {
  const { isSecurityListLoginDetailsEnabled: securityDetailsList } = flags;
  const { isSecurityListLoginDetailsLoading: securityDetailsListLoading } =
    flagsLoadingState;

  const SecurityDetailsWrapper = useMemo(() => {
    if (securityDetailsListLoading) {
      return <Loader />;
    }

    return securityDetailsList ? (
      <LoginDetails
        flags={flags}
        flagsLoadingState={flagsLoadingState}
      />
    ) : (
      <OldLoginDetails />
    );
  }, [
    flags,
    flagsLoadingState,
    securityDetailsList,
    securityDetailsListLoading
  ]);

  return <>{SecurityDetailsWrapper}</>;
};

export default ContentWrapper;
