import {
  ACTIONS,
  activity,
  AnalyticsEventWithControlName,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { ScreenName, ScreenPath } from 'src/utils/constants';
import { URLS } from 'src/utils/urls';

export const ViewOrderHistoryHyperLinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: ScreenPath,
  screenName: ScreenName,
  controlName: 'ViewOrderHistory',
  controlDetail: URLS.OrderHistory,
  version: eventDetailVersion
};

export const GuestOrdersHyperLinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath: ScreenPath,
  screenName: ScreenName,
  controlName: 'GuestOrders',
  controlDetail: URLS.GuestOrders,
  version: eventDetailVersion
};
