import styled from 'styled-components';
import tokens from '@veneer/tokens';

const StyledLink = styled.a`
  && {
    color: ${tokens.colorHpBlue6};
    text-decoration: none;
    :hover {
      color: ${tokens.colorHpBlue7};
      text-decoration: underline;
    }
    :active {
      color: ${tokens.colorHpBlue8};
      text-decoration: underline;
    }
  }
`;

export default StyledLink;
