import React from 'react';
import { Card } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  EditAddressHpStoreHyperlinkClicked,
  publishEvent
} from 'src/utils/analytics';
import { IconGhost, NoAddresses, NoAddressesText } from './styles';
import { AddressLink, AddressSectionContent } from './styles';
import { URLS } from 'src/utils/urls';
import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';

interface AddressCardProps {
  rows: CardRowProps[];
}

const AddressCard = ({ rows }: AddressCardProps) => {
  const { translate } = useDependencyManagerContext();
  const rowsColorChangeEnabled = rows.length > 0;

  const emptyAddress = (
    <AddressSectionContent>
      <NoAddresses
        content={
          <>
            <IconGhost size={40} />
            <NoAddressesText>
              {`${translate('profile.noAddresses', 'No addresses yet!')}`}
            </NoAddressesText>
          </>
        }
      />
    </AddressSectionContent>
  );

  const htmlString = translate(
    'profile.findMoreAddresses',
    'Find more addresses in your {{tagReplaceStart}}HP Store address book.{{tagReplaceEnd}}'
  )
    .replace(
      '{{tagReplaceStart}}',
      `<a href="${URLS.EditAddressHpStore}" target="_blank" rel="noreferrer">`
    )
    .replace('{{tagReplaceEnd}}', '</a>');

  if (rows.length === 0) {
    rows.push({
      index: 0,
      content: emptyAddress
    });
  }

  return (
    <Card
      title={{
        content: `${translate('profile.addresses', 'Addresses')}`
      }}
      data-testid="addresses"
      rowsColorChangeEnabled={rowsColorChangeEnabled}
      rows={rows}
    >
      <AddressLink>
        <div
          data-testid="edit-address-hp-store"
          dangerouslySetInnerHTML={{ __html: htmlString }}
          onClick={() => publishEvent(EditAddressHpStoreHyperlinkClicked)}
        />
      </AddressLink>
    </Card>
  );
};

export default AddressCard;
