import React, { useMemo } from 'react';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import Page from 'src/router';
import '../styles/global.scss';
import { Container } from './styles';
import * as T from './types';

const RoutesComponent = () => {
  return (
    <Container data-testid="device-page">
      <Page />
    </Container>
  );
};

const MemoizedRoutesComponent = React.memo(RoutesComponent);

const MainComponent: React.FC<T.AppPropsType> = () => {
  const { northboundAPIs } = useDependencyManagerContext();

  const client = useMemo(
    () => northboundAPIs.v1.graphql.getClient(),
    [northboundAPIs.v1.graphql]
  );

  const GraphQLProvider = useMemo(
    () => northboundAPIs.v1.graphql.reactTools.createGraphQLProvider(React),
    [northboundAPIs.v1.graphql.reactTools]
  );
  return (
    <GraphQLProvider client={client}>
      <MemoizedRoutesComponent />
    </GraphQLProvider>
  );
};

const MemoizedMainComponent = React.memo(MainComponent);

const App: React.FC<T.AppPropsType> = () => {
  return (
    <DependencyManagerProvider>
      <MemoizedMainComponent />
    </DependencyManagerProvider>
  );
};

export default App;
