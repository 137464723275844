const waitForOObject = (
  callback: () => void,
  preffixLog = 'Fullstory:',
  interval = 100,
  timeout = 5000
) => {
  const shell = window?.Shell?.v1;

  if (!shell) {
    console.error('Shell is not defined');
    return;
  }

  const logger = shell.logger?.createLoggerInstance({
    preffixLog
  });
  const startTime = Date.now();

  const checkAvailability = () => {
    if (window._O) {
      callback();
    } else if (Date.now() - startTime < timeout) {
      setTimeout(checkAvailability, interval);
    } else {
      logger?.error(
        `window._O object not found after waiting for ${timeout}ms`
      );
    }
  };

  checkAvailability();
};

export default waitForOObject;
