import { AssetUnitType, ValveControllerMetadata } from '@jarvis/jweb-core';
import { getIntrinsicPlatform } from '../client/utils/enum';

const isGetBindingsParamValid = (filterMetadata: ValveControllerMetadata): string => {
  // Country
  if (filterMetadata.country) {
    if (filterMetadata.country.length > 4) {
      return 'Country is not valid';
    }
  } else {
    return 'country is a required param for getBindings api';
  }

  // assetUnit
  if (filterMetadata.assetUnit === AssetUnitType.solution && !filterMetadata.assetType){
    return 'assetType not provided';
  }
  if (filterMetadata.assetUnit === AssetUnitType.mobile || filterMetadata.assetUnit === AssetUnitType.desktop) {
    if (filterMetadata.assetType) {
      return 'if assetUnit is mobile or desktop and assetType not to be provided';
    } else {
      if (filterMetadata.assetUnit !== getIntrinsicPlatform()){
        return 'assetUnit does not match intrinsic platform';
      }
    }
  }
  if (!filterMetadata.assetUnit) {
    filterMetadata.assetUnit = getIntrinsicPlatform() as AssetUnitType;
  }

  // assetUnit Combinations
  if (filterMetadata.assetUnit === ('printer' as AssetUnitType)) {
    // deviceId is Required when the Asset Unit is printer.
    if (!filterMetadata.deviceId) {
      return 'deviceId is required if assetUnit is printer';
    }
    // edgeType is Required when the Asset Unit is printer.
    if (!filterMetadata.edgeType) {
      return 'edgeType is required if assetUnit is printer';
    }
  }

  if (filterMetadata.assetUnit === AssetUnitType.mobile || filterMetadata.assetUnit === AssetUnitType.desktop) {
    // appInstanceId is Required when the Asset Unit is mobile.
    if (!filterMetadata.appInstanceId) {
      return 'appInstanceId is a required if assetUnit is mobile or Desktop';
    }
  }

  if (filterMetadata.assetUnit === AssetUnitType.solution) {
    // accountLoginId or stratusUserId is Required when the Asset Unit is solution.
    if (!filterMetadata.accountLoginId && !filterMetadata.stratusUserId) {
      return 'accountLoginId or stratusUserId is a required if assetUnit is solution';
    }
  }
  return '';
};

export default isGetBindingsParamValid;
