import React from 'react';
import PropTypes from 'prop-types';
import PreferencesContext from './PreferencesContext';

function merge(object, defaultObject) {
  const mergedObject = { ...defaultObject, ...object };

  Object.entries(mergedObject).forEach(([key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      mergedObject[key] = merge(value, defaultObject[key]);
    }
  });

  return mergedObject;
}

const PreferencesProvider = ({ children, currentPreferences, defaultPreferences }) => {
  const mergedPreferences = merge(currentPreferences, defaultPreferences);

  return <PreferencesContext.Provider value={mergedPreferences}>{children}</PreferencesContext.Provider>;
};

PreferencesProvider.defaultProps = {
  currentPreferences: {},
  defaultPreferences: {},
};

PreferencesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentPreferences: PropTypes.shape(PropTypes.any),
  defaultPreferences: PropTypes.shape(PropTypes.any),
};

export default PreferencesProvider;
